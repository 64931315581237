import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addTypeTissu = ( typeTissus ) => {
  return axios.post(API_URL + "/typeTissus/" , typeTissus ,{ headers: authHeader() });
};

const getTypeTissus = () => {
  return axios.get(API_URL + "/typeTissus/" , { headers: authHeader() });
};
const getActiveTypeTissus = () => {
  return axios.get(API_URL + "/typeTissus/active" , { headers: authHeader() });
};
const updateTypeTissu = (id , typeTissus ) => {
  return axios.put(API_URL + "/typeTissus/" + id , typeTissus, { headers: authHeader() });
};
const deleteTypeTissu = (id) => {
  return axios.delete(API_URL + "/typeTissus/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addTypeTissu,
  getTypeTissus,
  updateTypeTissu,
  deleteTypeTissu,
  getActiveTypeTissus
};
export default exportsMethodes;