import { Tabs } from "antd";
import React from "react";
import ReferenceBroderie from "./ReferenceBroderie";
import TypeCoupe from "./TypeCoupe";
import TypeOrderCoupe from "./TypeOrderCoupe";
import TypeTissus from "./TypeTissus";
const { TabPane } = Tabs;

const ParamOrdreCoupe = () => {
  return (
    <Tabs defaultActiveKey="1" centered>
      <TabPane tab="Type ordre de coupe" key="1">
        <TypeOrderCoupe></TypeOrderCoupe>
      </TabPane>
      <TabPane tab="Type de coupe" key="2">
        <TypeCoupe></TypeCoupe>
      </TabPane>
      <TabPane tab="Type de tissus" key="3">
        <TypeTissus></TypeTissus>
      </TabPane>
      <TabPane tab="Référence Broderie" key="4">
        <ReferenceBroderie></ReferenceBroderie>
      </TabPane>
    </Tabs>
  );
};
export default ParamOrdreCoupe;
