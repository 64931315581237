import {
  CheckOutlined, DeleteOutlined, PlusCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col, Divider, Form, Input, InputNumber, message,
  Row,
  Spin,
  Tooltip,
  Select
} from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import currencys from '../../../helpers/currency';
import Can from "../../../security/Can";
import BonCommandeService from "../../../services/bonCommande.service";
import ClientService from "../../../services/client.service";
import EntrepriseService from "../../../services/entreprise.service";
import FournisseurService from "../../../services/fournisseur.service";
import ProduitService from "../../../services/produit.service";
import TaxeService from "../../../services/taxe.service";
import AppTable from "../../uiHelpers/AppTabe";
import FicheClient from "../../uiHelpers/Fiches/FicheClient";
import FicheDetails from "../../uiHelpers/Fiches/FicheDetails";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import FicheFournisseur from "../../uiHelpers/Fiches/FicheFournisseur";
import FicheHeader from "../../uiHelpers/Fiches/FicheHeader";
import FicheTableGain from "../../uiHelpers/Fiches/FicheTableGain";
import FicheTableTotaux from "../../uiHelpers/Fiches/FicheTableTotaux";
import FicheTableTva from "../../uiHelpers/Fiches/FicheTableTva";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import AddProductModalForm from "../General/AddProductModalForm";
import writtenNumber from '/node_modules/written-number/lib/index';


writtenNumber.defaults.lang = 'fr';


function BonCommande() {
  const [productsForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [fournisseur, setFournisseur] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [entreprise, setEntreprise] = useState({});
  const [productsModal, setProductsModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isDarkMode, setDarkMode] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectingProductList, setSelectingProductList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [appliedTaxes, setListAppliedTaxes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [gains, setGain] = useState([]);
  const [productModal , setProductModal] = useState(false);
  const [fodec , setFodec] = useState(false);
  const [writtenTotal,setWrittenTotal] = useState("");
  const [selectedCurrency,setSelectedCurrency] = useState("");
  const [oldMontantTotal,setoldMontantTotal] = useState("");
  const [showDefecite,setShowDefecite] = useState(false);
  const history = useHistory();

  const location = useLocation();

  const [prixTotal, setPrixTotal] = useState({
    montant_total: 0,
    total_ttc: 0,
    total_ht: 0,
    fodec: 0,
    tva: 0,
    totalTimbreFiscal: "0.000",
  });

  const isVente = false;

  useEffect(() => {
    loadFournisseur();
    loadClients();
    loadEntreprise();
    getThemeMode();
    setUpdate();
    return () => {
      clearStates();
    };
  }, []);

  const loadTaxeFodec = async () => {
    const response = await TaxeService.checkTaxeFodec();
    setFodec(response.data);
  }

  const setUpdate = async () => {
    setLoading(true);
    if( location.state?.id ){
      const id = location.state?.id;
      await BonCommandeService.getBonCommande( id , ( response )=> {
        if (response.status === 200 || 204) {
          const activeFodec = response.data.fodec > 0 ? true : false; 
          setFodec(activeFodec)
          const fournisseurId = response.data.produit_achats[0].fournisseurId;
          const productList = {...response.data }
          productList.date = moment(productList.date);
          productList.date_livraison = moment(productList.date_livraison);
          productList.fournisseur = fournisseurId;
          loadProduits(fournisseurId);
          setSelectedCurrency(response.data.devise);
          const ids = []

          for (const element of productList.produit_achats){
            ids.push(element.produitId);
            setQteDefecite(element)
          } 
          productsForm.setFieldsValue(productList);
          setSelectedIds(ids);
          calculeTotalTaxes(fournisseurId,activeFodec);
          setUpdateId(id);
          setEditMode(true);
          fournisseurChanged();
        }else message.error("Une erreur est survenu ! ");
      });
    }else{
      await loadTaxeFodec();
      generateReference();
    }
    setLoading(false);

  }

  const setQteDefecite = (element) => {
    const qteDefecite = element.produit?.stocks[0]?.qteDeficite
    element.qteDefecite = qteDefecite
    if (qteDefecite < 0) setShowDefecite(true)
  }

  const generateReference = async () => {
    await BonCommandeService.getLast( ( response )=> {
      if (response.status === 200 || 204) {
        if(response.data.length === 0){
          var nbr  = "1";
          var reference = "BC-" + nbr.padStart(4, '0');
        }else{
          const lastRef = response.data.reference;
          const refNbr = (Number(lastRef.substring(3, 7)) + 1 ) + "";
          var reference = "BC-" + refNbr.padStart(4, '0');
        }
        productsForm.setFieldsValue({reference: reference ,date : moment(new Date()) } )
      }else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  }

  const loadProduits = async (id) => {
    setLoading(true);
    const response = await ProduitService.getProduitsByFournisseur(id);
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const getProducts = async (id) => {
    setLoading(true);
    const response = await ProduitService.getProduitsByFournisseur(id);
    if (response.status === 200 || 204) return response.data;
    else message.error("Une erreur est survenu ! ");
  };
  
  const initTableData = (produits) => {
    produits = formatteProductsToDataTable(produits);
    setProductList(produits);
    setSelectingProductList(produits)
  };

  const formatteProductsToDataTable = (produits) => {
    for (const produit of produits) {
      produit.showType = produit.type ? "Service" : "Produit";
      produit.ttcAchat = produit.produit_achats[0]?.prixTTC;
      produit.htAchat = produit.produit_achats[0]?.prixHT;
      produit.pqte = produit.stocks[0]?.quantite;
      produit.fournisseurId = produit.produit_achats[0]?.fournisseurId;
      produit.showFournisseur =
        produit.produit_achats[0]?.fournisseur?.raison_sociale ??
        "Produit proprietere";
      produit.ttcVente = produit.produit_ventes[0].prixTTC;
      produit.htVente = produit.produit_ventes[0].prixHT;
      produit.qte =  produit?.stocks[0]?.quantite
    }
    return produits;
  };

  const selectProduct = (produit) => {
    if (produit == null) return
    setSelectedIds((prev) => [...prev, produit.id]);
    formatteProduct(produit);
    filterSelectedProduitFromSelectedIds(produit)
  };

  const addProductOnSelect = () => {
    const config = productsForm.getFieldsValue();
    const produit = productList.find( (e) => e.id === Number(config.produitToSelect) );
    if ( selectedIds.filter((it)=> it == produit?.id).length > 0 ) return
    selectProduct(produit)
    productsForm.setFieldsValue({produitToSelect:null})
  }

  const filterSelectedProduitFromSelectedIds = (produit) => {
    let products = [...selectingProductList]
    let temp = products.filter((it)=> it.id !== produit.id )
    setSelectingProductList(temp)
  }

  const removeElement = (produitId) => {
    const list = selectedIds;
    setSelectedIds(list.filter((idP) => idP !== produitId));
    const prod = productList.find((it)=> it.id == produitId )
    setSelectingProductList((prev) => [...prev,prod])
  };

  const isSelected = (id) => {
    return selectedIds.includes(id);
  };

  const formatteProduct = (produit) => {
    const qte = produit?.quantite ?? 1;

    if( module === "vente"){
      var prixUniteHt = produit?.produit_ventes[0]?.prixHT;
      var prixUniteTTC = produit?.produit_ventes[0]?.prixTTC;
      var tva = produit?.produit_ventes[0]?.tva
      var ht =  produit?.produit_ventes[0]?.total_prix_HT ?? Number(prixUniteHt) * Number(qte);
      var ttc = produit?.produit_achats[0]?.total_prix_TTC ?? Number(prixUniteTTC) * Number(qte);

    }else{
      var prixUniteHt = produit?.produit_achats[0]?.prixHT;
      var prixUniteTTC = produit?.produit_achats[0]?.prixTTC;
      var tva = produit?.produit_achats[0]?.tva;
      var ht = produit?.produit_achats[0]?.total_prix_HT  ?? Number(prixUniteHt) * Number(qte);
      var ttc = produit?.produit_achats[0]?.total_prix_TTC ?? Number(prixUniteTTC) * Number(qte);
    }

    const remise = produit?.remise ?? 0;
    const product = {
      produitId: produit?.id,
      libelle: produit?.libelle,
      quantite: Number(qte),
      prixHT: Number(prixUniteHt).toFixed(3),
      prixTTC: Number(prixUniteTTC).toFixed(3),
      tva: Number(tva),
      remise: Number(remise),
      total_prix_HT: Number(ht).toFixed(3),
      total_prix_TTC: Number(ttc).toFixed(3),
    };
    const productList = productsForm.getFieldValue("produit_achats")
      ? productsForm.getFieldValue("produit_achats")
      : [];
    productList.push(product);
    productsForm.setFieldsValue({ produit_achats: productList });
    calculeTotalTaxes();
  };

  const getThemeMode = () => {
    const theme = localStorage.getItem("COLOR_THEME");
    const darkMode = theme === "DARK" ? true : false;
    setDarkMode(darkMode);
  };

  const reloadFournisseurs = async () => {
    setLoading(true);
    const response = await FournisseurService.getFournisseurs();
    if (response.status === 200 || 204) {
      setFournisseurs(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const loadFournisseur = async () => {
    setLoading(true);
    const response = await FournisseurService.getFournisseurs();
    if (response.status === 200 || 204) {
      setFournisseurs(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const getFournisseurs = async () => {
    setLoading(true);
    const response = await FournisseurService.getFournisseurs();
    if (response.status === 200 || 204) {
      return response.data;
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const fournisseurChanged = async () => {
    const fournisseurId = productsForm.getFieldValue("fournisseur");
    loadProduits(fournisseurId);
    const fournisseurList = fournisseurs.length ? fournisseurs : await getFournisseurs();
    const fournisseur =  fournisseurList.find((four) => four.id === fournisseurId);
    setFournisseur(fournisseur);

  };

  const loadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      setClients(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadEntreprise = async () => {
    setLoading(true);
    const response = await EntrepriseService.getEntreprise();
    if (response.status === 200 || 204) {
      setEntreprise(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const clearStates = () => {
    setEditMode(false);
    setUpdateId(null);
  };

  const handleCancel = () => {
    setProductsModal(false);
  };

   // type : 0->qte | 1->prixttc | 2->tva |3->remise
   const priceChanged = (index, type) => {
    const products = productsForm.getFieldValue("produit_achats");
    const product = products[index];
    const taxe = product.tva / 100;
    const quantite = product.quantite;
    const remise = product.remise;
    
    let prixTTC = 0
    let prixHT = 0
    let total_ttc = ""
    let total_ht = ""

    if (type == 0 || type == 1){
      prixTTC = product.prixTTC
      total_ttc = Number(prixTTC * quantite).toFixed(3);
      total_ht = (Number(total_ttc) / (1 + taxe)).toFixed(3) 

      products[index].total_prix_TTC = total_ttc;
      products[index].total_prix_HT = total_ht

    }else if (type == 2){
      let lastProductPrixTTC = productList.filter((it)=>it.id == product.produitId)[0]?.produit_achats[0]?.prixTTC
      lastProductPrixTTC = Number(lastProductPrixTTC)

      const tvaDiff = (product.tva - 19) / 100
      prixTTC = tvaDiff !== 0 ? Number(product.prixTTC) + Number(product.prixTTC) * tvaDiff :  lastProductPrixTTC
      total_ttc = Number(prixTTC * quantite).toFixed(3);
      prixTTC = parseFloat((total_ttc / quantite).toFixed(3))

      products[index].prixTTC = prixTTC;
      products[index].total_prix_TTC = total_ttc;

    }else if (type == 3){
      prixTTC = product.prixTTC - (product.prixTTC * (remise / 100))
      prixHT = product.prixHT

      total_ttc = Number(prixTTC * quantite).toFixed(3);
      total_ht = (Number(total_ttc) / (1 + taxe)).toFixed(3) 

      products[index].total_prix_TTC = total_ttc;
    }

    productsForm.setFieldsValue({ products });
    calculeTotalTaxes();
  }  

  const calculeTotalTaxes = async (fournisseurId , activeFodec) => {
    const products = productsForm.getFieldValue("produit_achats");
    const list = [];
    const listGain = [];
    const isFodecActive = activeFodec ? activeFodec : fodec

    for (const product of products) {
      if (product.tva > 0.1) {
        const base = isFodecActive ? 
          Number(product.total_prix_HT) + Number(product.total_prix_HT * 1) / 100 :
          Number(product.total_prix_HT);
        const montantTaxes = isFodecActive ?
          ((Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100)).toFixed(3) :
          (Number(product.total_prix_HT) * (product.tva / 100)).toFixed(3);
        const taxe = {
          tvaPorucentage: product.tva,
          montantTaxes: montantTaxes,
          base: base,
        };
        list.push(taxe);
      }
      const listProd = productList.length ? productList : await getProducts(fournisseurId); 
      const prod = listProd.find( prod => prod.id === product.produitId);
      const gain_ht = ( Number(product.total_prix_HT) - (prod?.produit_achats[0]?.prixHT * product.quantite )).toFixed(3);
      const gain_ttc = Number(product.total_prix_TTC) - (prod?.produit_achats[0]?.prixTTC) * product.quantite;

      const gain = {gain_ht , gain_ttc};
      listGain.push(gain);
    }
    setGain(listGain)
    setListAppliedTaxes(list);
    calculateTotal(isFodecActive);
    setLoading(false);

  };


  const calculateTotal = (activeFodec) => {
    const products = productsForm.getFieldValue("produit_achats");
    const remiseGlobal = productsForm.getFieldValue("remise_global") ? productsForm.getFieldValue("remise_global") : 0;
    let total_ht = 0;
    let tva = 0;
    let totalTimbreFiscal = prixTotal.totalTimbreFiscal
    let total_ttc = 0;
    let temp_ttc = 0;

    const isFodecActive =  activeFodec ? activeFodec : fodec;
    for (const product of products) {
      const montantTaxes = isFodecActive ? 
        (Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100) :
        (Number(product.total_prix_HT) ) * (product.tva / 100) ;
      total_ht += Number(product.total_prix_HT);
      tva += montantTaxes;
      temp_ttc += Number(product.total_prix_TTC)
    }
    total_ht = Number(total_ht) - ( Number(total_ht) *  (Number(remiseGlobal) / 100))


    let fodecMontant = isFodecActive ? Number(total_ht) / 100 : 0;
    total_ttc = Number((temp_ttc + Number(fodecMontant)).toFixed(1));

    let montant_total = Number((Number(total_ttc) - ( Number(total_ttc) *  (Number(remiseGlobal) / 100)) + Number(totalTimbreFiscal)).toFixed(1))

    const total = { montant_total, total_ttc, total_ht, fodec: fodecMontant, tva, totalTimbreFiscal };
    setPrixTotal(total);
    setoldMontantTotal(total.montant_total)

    const final = montant_total.toString().split(".");
    const currencyName = currencys.find(c => c.code === productsForm.getFieldValue("devise"));
    setWrittenTotal("Le compte est arrêté à la somme de " + writtenNumber(final[0]) + " " +currencyName.base + " , " +final[1] + " " + currencyName.sub);

  };
  
  const getProductId = (index, name) => {
    return productsForm.getFieldValue("produit_achats")[index]?.produitId;
  };

  const addCommande = async () => {
    setLoading(true);
    const product = productsForm.getFieldsValue();
    for (const element of product.produit_achats) {
      element.fournisseurId = productsForm.getFieldValue("fournisseur");
    }
    const bonCommande = {...product , ...prixTotal }
    bonCommande.etat = 0;
    bonCommande.remise_global =  bonCommande.remise_global ? bonCommande.remise_global : 0;
    bonCommande.reste_a_paye = bonCommande.montant_total
    bonCommande.paiement_status = 0
    console.log(bonCommande)
    const response = editMode ? 
      await BonCommandeService.updateBonCommande(updateId,bonCommande) 
      : await BonCommandeService.addBonCommande(bonCommande);
    if (response.status === 200 || 204) {
      if(editMode) message.info("Mise à jour avec success");
      else message.info("Ajout Bon de commande Avec Success");
      history.push("/bon_Commandes");

    } else message.error("Une erreur est survenu ! ");
    setLoading(false);

  }

  const ProduitColumns = [
    {
      title: "Type",
      dataIndex: "showType",
      key: "1",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Libelle",
      dataIndex: "libelle",
      sorter: (a, b) => a.showNom.localeCompare(b.showNom),
    },
    {
      title: "Référence Int",
      dataIndex: "reference_interne",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      sorter: (a, b) => a.valeur - b.valeur,
    },{
      title: "Quantité",
      dataIndex: "pqte",
      sorter: (a, b) => a.pqte - b.pqte,
    },
    {
      title: "Achat TTC",
      dataIndex: "ttcAchat",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Vente TTC",
      dataIndex: "ttcVente",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              disabled={isSelected(record.id)}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => selectProduct(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const openProductModal = () => {
    setProductModal(true);
  };

  const onCloseProductModal = async (action) => {
    if (action) {
      const fournisseurId = productsForm.getFieldValue("fournisseur");
      await loadProduits(fournisseurId);
    }
    setProductModal(false);
  };

  const changeCurrency = () => {
    const currency = productsForm.getFieldValue("devise");
    setSelectedCurrency(currency);
  }
  const disableForm = () => {
    return selectedIds.length === 0;
  }

  const handleTimbreChange = (selectedTimbre) => {
    setPrixTotal({...prixTotal,
      totalTimbreFiscal:selectedTimbre.toFixed(3),
      montant_total: Number(oldMontantTotal) + selectedTimbre 
    })
  }
  
  function currencyFormat(num){
    return !isNaN(num) ? Number(num)?.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num
  }

  return (
    <>
    {Can("10_2") ? (
    <Spin  className="px-5" spinning={isLoading} size="large" >
      <Card className="w-100 card-shadow">
        <Form layout="vertical" initialValues={{ devise: "TND", }} form={productsForm} onFinish={addCommande}>
          <Row>
            <FicheHeader
              moduleName={"Bon De Commande"}
              showClients={isVente}
              entreprise={entreprise}
              clients={clients}
              fournisseurs={fournisseurs}
              selectedClient={(client) => setClient(client)}
              selectedFournisseur={() => fournisseurChanged()}
              reloadFournisseur={()=> reloadFournisseurs()}
            ></FicheHeader>
          </Row>
          <Row>
            {isVente ? (
              <Col span={8} className="px-2 pt-3">
                <FicheClient client={client}></FicheClient>
              </Col>
            ) : (
              <Col span={8} className="px-2 pt-3">
                <FicheFournisseur fournisseur={fournisseur}></FicheFournisseur>
              </Col>
            )}
            <Col span={8} offset={8} className="px-2 pt-3">
              <FicheDetails showLivraisonDate={true} currencyChanged={ ()=>  changeCurrency()} ></FicheDetails>
            </Col>
          </Row>
          <Row>
            <Col span="24" className="px-4">
              <table
                className={` mb-5 table ${isDarkMode ? "table-dark" : ""} `}
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Libelle</th>
                    { showDefecite &&  <th scope="col">Qte Defecite</th>  }
                    <th scope="col">Quantité</th>
                    <th scope="col">Prix Unitaire TTC</th>
                    <th scope="col">TVA</th>
                    <th scope="col">Remise</th>
                    <th scope="col">Total HT</th>
                    <th scope="col">Total TTC</th>
                    <th scope="col" className="text-center color">
                      <Tooltip title="Ajouter un produit">
                        <span
                          className="medium pointer"
                          type="primary"
                          onClick={() => setProductsModal(true)}
                        >
                          <PlusCircleOutlined />
                        </span>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <Form.List
                    name="produit_achats"
                    label="List des addresse de livraisons"
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <>
                            <tr>
                              <th scope="row">{index + 1}</th>
                              <td>
                                <Form.Item
                                  {...restField}
                                  name={[name, "libelle"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <Input
                                    style={{ width: 180 }}
                                    type={"text"}
                                    bordered={false}
                                  />
                                </Form.Item>
                              </td>
                              { showDefecite  &&
                                  <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "qteDefecite"]}
                                  >
                                    <Input
                                      style={{ width: 60 }}
                                      type={"text"}
                                      bordered={false}
                                      disabled
                                      className="w-125px text-danger"
                                      min={0}
                                    />
                                  </Form.Item>
                                  </td>
                              }
                              <td>
                                <Form.Item
                                  {...restField}
                                  name={[name, "quantite"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    style={{ width: 120 }}
                                    onChange={() => priceChanged(index,0)}
                                    // onChange={() => priceChanged(index)}
                                    className="w-125px"
                                    min={0}
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  {...restField}
                                  name={[name, "prixTTC"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    onChange={() => priceChanged(index,1)}
                                    // onChange={() => priceChanged(index)}
                                    style={{ width: 120 }}
                                    className="w-125px"
                                    min={0}
                                    formatter={(value) => `${value}`}
                                    parser={(value) =>
                                      value.replace("", "")
                                    }
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  {...restField}
                                  name={[name, "tva"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    onChange={() => priceChanged(index,2)}
                                    // onChange={() => priceChanged(index)}
                                    style={{ width: 60 }}
                                    min={0}
                                    max={100}
                                    formatter={(value) => `${value}%`}
                                    parser={(value) => value.replace("%", "")}
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  {...restField}
                                  name={[name, "remise"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    onChange={() => priceChanged(index,3)}
                                    // onChange={() => priceChanged(index)}
                                    min={0}
                                    max={100}
                                    style={{ width: 60 }}
                                    formatter={(value) => `${value}%`}
                                    parser={(value) => value.replace("%", "")}
                                  />
                                </Form.Item>
                              </td>
                              <th scope="row" className=" w-90px">
                                <Form.Item
                                  {...restField}
                                  name={[name, "total_prix_HT"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    className={`w-125px ${
                                      isDarkMode
                                        ? "total-input-dark"
                                        : "total-input-light"
                                    } `}
                                    bordered={false}
                                    style={{ width: 120 }}
                                    min={0}
                                    disabled
                                    formatter={(value) => currencyFormat(value)}
                                    parser={(value) => value.replace(``, "")}
                                  />
                                </Form.Item>
                              </th>
                              <th scope="row" className="w-90px">
                                <Form.Item {...restField} name={[name, "total_prix_TTC"]}>
                                  <InputNumber
                                    className={`w-125px ${
                                      isDarkMode
                                        ? "total-input-dark"
                                        : "total-input-light"
                                    } `}
                                    style={{ width: 120 }}
                                    bordered={false}
                                    min={0}
                                    disabled
                                    formatter={(value) => currencyFormat(value)}
                                    parser={(value) =>
                                      value.replace(``, "")
                                    }
                                  />
                                </Form.Item>
                              </th>
                              <td className="text-center red-flag pt-1 w-90px">
                                <Tooltip title="Supprimer le produit">
                                  <span
                                    className="medium pointer"
                                    type="primary"
                                    onClick={() => {
                                      removeElement(getProductId(index));
                                      remove(name);
                                      calculeTotalTaxes();
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </span>
                                </Tooltip>
                              </td>
                            </tr>
                          </>
                        ))}
                      </>
                    )}
                  </Form.List>
                </tbody>
              </table>
            </Col>
            <Col span={7} className="px-2" >
              <Form.Item 
                name="produitToSelect"
              >
                <Select
                  size="large" 
                  allowClear
                  showSearch
                  placeholder="produit (quantite en stock)"
                  onChange={() => addProductOnSelect()}
                  filterOption={(inputValue, option) =>
                    option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 
                    || option.props.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {selectingProductList?.map(produit=> (
                     <Select.Option key={produit.id}>{produit.libelle + " " + "(" +produit.qte+ ")" }</Select.Option> 
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} offset={16} className="px-4">
              <table className={`table ${isDarkMode ? "table-dark" : ""} `}>
                <thead>
                  <tr>
                    <th scope="col"  className="pb-3">Remise : </th>
                    <th scope="col">
                      <Form.Item name="remise_global" initialValue={0} className="my-0 py-1">
                        <InputNumber
                          onChange={() => calculateTotal()}
                          style={{ width: "100%" }}
                          formatter={(value) => `${value}%`}
                          parser={(value) => value.replace("%", "")}
                          min={0}
                          max={100}
                        />
                      </Form.Item>
                    </th>
                  </tr>
                </thead>
              </table>
            </Col>
            <FicheTableGain selectedCurrency={selectedCurrency} gains={gains} isDarkMode={isDarkMode}></FicheTableGain>
            <FicheTableTva
              fodec={fodec}
              selectedCurrency={selectedCurrency}
              isDarkMode={isDarkMode}
              appliedTaxes={appliedTaxes}
            ></FicheTableTva>
            <FicheTableTotaux
              fodec={fodec}
              selectedCurrency={selectedCurrency}
              isDarkMode={isDarkMode}
              prixTotal={prixTotal}
              timbreChange={(timber)=>handleTimbreChange(timber)}
            ></FicheTableTotaux>
          </Row>
          <Divider className="text-capitalize">{writtenTotal}</Divider>

              
          <FicheFooter entreprise={entreprise}></FicheFooter>

          <Row className="d-flex flex-row-reverse pt-5 pb-2">
            <Button 
              className="mx-2" 
              type="primary"
              disabled={disableForm()}
              onClick={productsForm.submit}
            >
              Enregistrer
            </Button>
          </Row>
        </Form>
      </Card>
      <Modal
        visible={productsModal}
        getContainer={false}
        footer={null}
        onOk={handleCancel}
        onCancel={handleCancel}
        confirmLoading={isLoading}
        title={
          <div className="d-flex justify-content-between px-4">
            <div> Liste des Produits </div>
              <Button
                className="mr-5 pr-5"
                shape="round"
                type="primary"
                onClick={() => openProductModal()}
              > Ajouter un produit </Button>
          </div>}
        width={1000}
      >
        <Spin spinning={isLoading} size="large">
          <AppTable data={productList} columns={ProduitColumns} />
        </Spin>
      </Modal>
      <AddProductModalForm showModal={productModal} productAdded={(action) => onCloseProductModal(action)}></AddProductModalForm>
    </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>

  );
}

export default BonCommande;
