import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  DatePicker,
  Dropdown,
  Menu,
  Switch,
} from "antd";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip as RTooltip,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment";
import ProduitService from "../../../services/produit.service";
import BonLivraisonService from "../../../services/bonLivraison.service";
import { Collapse } from "antd";
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const DashboardFianceVenteEnLigne = () => {
  const [searchForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [produitsFini, setProduitsFini] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [isCummulative, setIsCummulative] = useState(false);
  const [bltaux,setBlTaux] = useState(0);
  const [blvlTaux,setBlvlTaux] = useState(0);
  const [searchObj, setSearchObj] = useState({
    produits: [],
    range: [],
  });

  useEffect(() => {
    loadProduits();
    initData();
  }, []);

  const initData = async () => {};

  const loadProduits = async () => {
    setLoading(true);
    const response = await ProduitService.getProduitFini();
    if (response.status === 200 || 204) {
      const data = [...response.data];
      let obj = {};
      obj.id = "tout";
      data.push(obj);
      setProduitsFini(data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const cancelSearch = async () => {
    initData();
    clearSearch();
  };

  const search = async (values) => {
    const temp = {
      produits: values.produits,
      range: searchObj.range,
    };
    setSearchObj(temp);
    setIsCummulative(false);
    filterData(temp);
  };

  const filterData = async (search) => {
    setLoading(true);
    const listOfMonthes = getListOfMonth(search.range);
    var tauxBl = 0;
    var tauxBlVl = 0;

    let bonLivraisons = await BonLivraisonService.getActiveBonLivraison();
    bonLivraisons = bonLivraisons.data;
    let bonLivraisonsHasProduitSelected = [];
    for (let element of bonLivraisons) {
      const blList = filterByProduitIds(element.produit_bon_livraisons, search.produits);
      if (blList.length > 0) bonLivraisonsHasProduitSelected.push(element);
    }
    bonLivraisonsHasProduitSelected = filterByDate( bonLivraisonsHasProduitSelected,search.range,"date");
    const bls = bonLivraisonsHasProduitSelected?.filter((bl) => bl.bonLivraisonParentId != null);

    const data = []
    for (let month of listOfMonthes) {
        var montantBl = 0;
        var montantBlVl = 0;
        var marge = 0;

        for (let element of bls) {
          if (moment(element.date).format("YYYY-MM") == month){
            // BL
            const blproduits = filterByProduitIds(element?.produit_bon_livraisons, search.produits);
            blproduits?.forEach((ele) => { montantBl+= ele.total_prix_HT  });
            
            // BLVL
            const blvl = bonLivraisons?.find((bl) => bl.id == element.bonLivraisonParentId );
            const blvlproduits = filterByProduitIds(blvl?.produit_bon_livraisons, search.produits);
            blvlproduits?.forEach((ele) => { montantBlVl+= ele.total_prix_HT  });
            
            // marge
            marge = montantBlVl - montantBl

            tauxBl +=  element.total_ht
            tauxBlVl +=  blvl.total_ht
            }
        }

        data.push({
            marge: marge,
            BL: montantBl,
            BLVL: montantBlVl,
            month: month
        })
    }    

    setBarChartData(data);
    setBlTaux(tauxBl)
    setBlvlTaux(tauxBlVl)
    setLoading(false);
  };

  const filterCummulativeData = async (search) => {
    setLoading(true);
    const listOfMonthes = getListOfMonth(search.range);

    let bonLivraisons = await BonLivraisonService.getActiveBonLivraison();
    bonLivraisons = bonLivraisons.data;
    let bonLivraisonsHasProduitSelected = [];
    for (let element of bonLivraisons) {
      const blList = filterByProduitIds(element.produit_bon_livraisons, search.produits);
      if (blList.length > 0) bonLivraisonsHasProduitSelected.push(element);
    }
    bonLivraisonsHasProduitSelected = filterByDate( bonLivraisonsHasProduitSelected,search.range,"date");

    const bls = bonLivraisons?.filter((bl) => bl.bonLivraisonParentId != null);

    const data = []
    var montantBl = 0;
    var montantBlVl = 0;
    for (let month of listOfMonthes) {
      var marge = 0;

      for (let element of bls) {
        if (moment(element.date).format("YYYY-MM") == month){                
          // BL
          const blproduits = filterByProduitIds(element?.produit_bon_livraisons, search.produits);
          blproduits?.forEach((ele) => { montantBl+= ele.total_prix_HT  });
          
          // BLVL
          const blvl = bonLivraisons?.find((bl) => bl.id == element.bonLivraisonParentId );
          const blvlproduits = filterByProduitIds(blvl?.produit_bon_livraisons, search.produits);
          blvlproduits?.forEach((ele) => { montantBlVl+= ele.total_prix_HT  });
          
          // marge
          marge = montantBlVl - montantBl
        }else{
          marge = data[data.length-1]?.marge
        }
      }

      data.push({
          marge: marge,
          BL: montantBl,
          BLVL: montantBlVl,
          month: month
      })
    }    

    setBarChartData(data);
    setLoading(false);
  };

  const clearSearch = () => {
    const emptySearch = {
      produits: null,
      range: [],
    };
    setSearchObj(emptySearch);
    setBlTaux(0)
    setBlvlTaux(0)
    searchForm.setFieldsValue(emptySearch);
  };

  const switchCummulative = (value) => {
    setIsCummulative(value);
    if(value) filterCummulativeData(searchObj)
    else filterData(searchObj)
  };

  //   helper fucntion
  const filterByProduitIds = (data, produitIds) => {
    if (produitIds.includes("tout")) return data;
    return data.filter((element) => produitIds.includes(element.produitId));
  };

  const filterByDate = (data, range, field) => {
    let [start, end] = [...range];
    const startDate = moment(start, "YYYY-MM");
    const endDate = moment(end, "YYYY-MM").endOf("month");

    const filteredData = data.filter((item) => {
      const updatedAtMoment = moment(item[field]);
      return updatedAtMoment.isBetween(startDate, endDate, "month", "[]");
    });

    return filteredData;
  };

  const getListOfMonth = (range) => {
    let [start, end] = [...range];
    const startDate = moment(start, "YYYY-MM");
    const endDate = moment(end, "YYYY-MM");
    const dates = [];

    for (
      let currentDate = startDate.clone();
      currentDate.isSameOrBefore(endDate);
      currentDate.add(1, "month")
    ) {
      dates.push(currentDate.format("YYYY-MM"));
    }

    return dates;
  };

  function currencyFormat(num) {
    return  Number(num)?.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

  return (
    <Spin spinning={isLoading} size="large">
      <Card title={"Production"}>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-row align-center">
            <h5> Total des Bon Livraison  : <input style={{fontWeight:"bold"}} className="text-success text-center w-25 border-0 bg-transparent" disabled type="text" value={currencyFormat(bltaux)} /> </h5> 
          </div>
          <div className="d-flex flex-row align-center">
            <h5> Total des Bon Livraison en ligne  : <input style={{fontWeight:"bold"}} className="text-danger text-center w-25 border-0 bg-transparent" disabled type="text" value={currencyFormat(blvlTaux)} /> </h5> 
          </div>
        </div>
        <br />
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Recherche" key="1">
            <Form layout="vertical" form={searchForm} onFinish={search}>
              <Row className="d-flex flex-row justify-content-evenly">
                <Col span={12}>
                  <Form.Item
                    name={"produits"}
                    label="produit fini"
                    rules={[
                      {
                        required: true,
                        message: "selectionner un produit fini !",
                      },
                    ]}
                  >
                    <Select size="large" mode="multiple">
                      {produitsFini.map((cl, index) => (
                        <Select.Option key={index} value={cl.id}>
                          {cl.libelle}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ marginRight: "30px" }}>
                  <Form.Item
                    name={"range"}
                    label="Range"
                    rules={[
                      {
                        required: true,
                        message: "selectionner Range!",
                      },
                    ]}
                  >
                    <RangePicker
                      picker="month"
                      onChange={(date, dateString) =>
                        setSearchObj((prev) => ({
                          ...prev,
                          range: dateString,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-flex justify-content-end">
                <Col className="px-2">
                  <Form.Item className="m-0">
                    <Button type="primary" htmlType="submit">
                      Valider
                    </Button>
                  </Form.Item>
                </Col>
                <Col className="px-2">
                  <Button onClick={cancelSearch} type="danger">
                    Annuler
                  </Button>
                </Col>
              </Row>
            </Form>
          </Panel>
        </Collapse>
        <br />

        <Row>
          <Col span={24}>
            <h5 className="p-3">Chart 2 : </h5>
            <BarChart width={1200} height={400} data={barChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="month"
                label={{ value: "Mois", position: "insideBottom" }}
              />
              <YAxis
                label={{ value: "Montant", angle: -90, position: "insideLeft" }}
              />
              <Tooltip position="right">
                <Switch
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  checkedChildren="Normal"
                  unCheckedChildren="Cummulative"
                  checked={isCummulative}
                  onChange={(value) => switchCummulative(value)}
                >
                  {isCummulative ? "Cummulative" : "Normal"}
                </Switch>
              </Tooltip>
              <Legend verticalAlign="top" height={36} />
              <Bar dataKey="marge" fill="#507e4e" />
              <Bar dataKey="BL" fill="#8884d8" />
              <Bar dataKey="BLVL" fill="#82ca9d" />
            </BarChart>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default DashboardFianceVenteEnLigne;
