import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addClient = ( client ) => {
  return axios.post(API_URL + "/client/" , client ,{ headers: authHeader() });
};

const getClients = () => {
  return axios.get(API_URL + "/client/" , { headers: authHeader() });
};

const getClient = (id) => {
  return axios.get(API_URL + "/client/"+id , { headers: authHeader() });
};

const getClientsNames = async ( callback ) => {
  callback( await axios.get(API_URL + "/client/names", { headers: authHeader() }));
};

const updateClient = (id , client ) => {
  return axios.put(API_URL + "/client/" + id , client, { headers: authHeader() });
};

const deleteClient = (id) => {
  return axios.delete(API_URL + "/client/" + id , { headers: authHeader() });
};
const getClientFacture = (id) => {
  return axios.get(API_URL + "/client/findAllClientsStats/" + id , { headers: authHeader() });

}

const exportsMethodes = {
  addClient,
  getClients,
  updateClient,
  deleteClient,
  getClientsNames,
  getClientFacture,
  getClient
};
export default exportsMethodes;