import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addOrdreCoupe = ( ordreCoupe ) => {
  return axios.post(API_URL + "/ordreCoupe/" , ordreCoupe ,{ headers: authHeader() });
};

const getOrdreCoupes = () => {
  return axios.get(API_URL + "/ordreCoupe/" , { headers: authHeader() });
};
const getActiveOrdreCoupes = () => {
  return axios.get(API_URL + "/ordreCoupe/active" , { headers: authHeader() });
};
const updateOrdreCoupe = (id , ordreCoupe ) => {
  return axios.put(API_URL + "/ordreCoupe/" + id , ordreCoupe, { headers: authHeader() });
};
const deleteOrdreCoupe = (id) => {
  return axios.delete(API_URL + "/ordreCoupe/" + id , { headers: authHeader() });
};

const updateOdreCoupeStatus = ( id , etat ) => {
  return axios.put(API_URL + "/ordreCoupe/status/" + id , etat, { headers: authHeader() });
}

const loadStockQte = ( data )=> {
  return axios.post(API_URL + "/ordreCoupe/qteStock/", data, { headers: authHeader() } )
}

const loadStock = () => {
  return axios.get(API_URL + "/ordreCoupe/stock" , { headers: authHeader() });
}

const exportsMethodes = {
  addOrdreCoupe,
  getOrdreCoupes,
  updateOrdreCoupe,
  deleteOrdreCoupe,
  getActiveOrdreCoupes,
  updateOdreCoupeStatus,
  loadStockQte,
  loadStock
};
export default exportsMethodes;