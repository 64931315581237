import { Col } from "antd";
import React, { useEffect, useState } from "react";
import {
  message,
  Select
} from "antd";
import TimbreFiscalService from "../../../../src/services/timbreFiscal.service";
 
function FicheTableTotaux({ entreprise,isDarkMode ,prixTotal,selectedCurrency, fodec, timbreChange }) {
  useEffect(() => {
    loadTimbreFiscal();
  }, [prixTotal,selectedCurrency]);

  const [timbres, setTimbres] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const loadTimbreFiscal = async () => {
    setLoading(true);
    const response = await TimbreFiscalService.getActiveTimbres()
    if (response.status === 200 || 204) setTimbres(response.data)
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  }

  const handleChange = (selectedTimbre) => {
    timbreChange(selectedTimbre)
  }

  function currencyFormat(num){
    return !isNaN(num) ? Number(num)?.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num
  }

  return (
    <Col span="8" className="px-4">
      <table className={`table ${isDarkMode ? "table-dark" : "" } `}>
        <thead>
          <tr>
          <th scope="row">Totaux</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Total HT</th>
            <td>{currencyFormat(prixTotal?.total_ht)} {selectedCurrency}</td>
          </tr>
          { fodec ? (
            <tr>
              <th scope="row">Fodec</th>
              <td>{currencyFormat(prixTotal?.fodec)} {selectedCurrency}</td>
            </tr>
          ) : ( <></>)}
          <tr>
            <th scope="row">TVA</th>
            <td>{currencyFormat(prixTotal?.tva)} {selectedCurrency}</td>
          </tr>
          <tr>
            <th scope="row">Total TTC</th>
            <td>{currencyFormat(prixTotal?.total_ttc)} {selectedCurrency}</td>
          </tr>
          {/* { prixTotal?.totalTimbreFiscal > 0 &&
            <tr>
              <th scope="row">Timbre fiscale</th>
              <td>{currencyFormat(prixTotal?.totalTimbreFiscal)} {selectedCurrency}</td>
            </tr>
          } */}
          <tr>
            <th scope="row">Timbre fiscale</th>
            <td>
              <Select size="middle" onChange={handleChange}>
                {timbres.map((cl,index)=> <Select.Option key={index} value={cl.valeur}>{cl.valeur}</Select.Option> )} 
              </Select>
              {selectedCurrency}
            </td>
          </tr>
          <tr>
            <th scope="row">Total</th>
            <th scope="row">{currencyFormat(prixTotal?.montant_total)} {selectedCurrency}</th>
          </tr>
        </tbody>
      </table>
    </Col>
  );
}
export default FicheTableTotaux;
