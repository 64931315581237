import {
  CheckOutlined,
  DownloadOutlined,
  EditOutlined,
  FilePptOutlined,
  CopyOutlined,
  DollarOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Menu,
  message,
  Modal,
  Spin,
  Tooltip,
  Tag
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import Can from "../../../security/Can";
import BonRetour from "../../../services/bonRetour.service";
import ClientService from "../../../services/client.service";
import BonTransfertService from "../../../services/bonTransfert.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";
const url = environment.serverUrl;

const DashboardBonRetour = () => {
  const [commandeForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [bnTransferModal, setbonTransfertModal] = useState(false);
  const [bonTransfert, setbonTransferts] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [reference, setReference] = useState();

  useEffect(() => {
    loadBonRetours();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadBonRetours = async () => {
    setLoading(true);
    await BonRetour.getBonRetours(true, async (response) => {
      if (response.status === 200 || 204) {
        initTableData(response.data);
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  };

  const initTableData = async (data) => {
    const showMode = localStorage.getItem("SHOW_MODE");
    const list = [];
    for (const element of data) {
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
      element.client = await getClientName(element);
      element.quantite = await getQuantites(element)
      element.montant_total = currencyFormat(element.montant_total)
      if(showMode === "NO") {
        if(element.etat !== -1) list.push(element);
      } else list.push(element);
    }
    console.log("list",list)
    setDataTable(list);
  };

  const getQuantites = (element) => {
    let quantites = 0
    for (let it of element.produit_bon_retours) quantites += it.quantite
    return quantites
  }

  const getClientName = async (element) => {
    let client = await ClientService.getClient(element.clientId)
    client = client.data
    return client?.nom + " " + client?.prenom
  }

  const getCommandeReference = async (bonTransfertId) => {
    const response = await BonTransfertService.getBonTransfertsRef(
      bonTransfertId
    );
    return response.data.reference;
  };

  const formatteEtat = (etat) => {
    switch (etat) {
      case 0:
        return "En cours";
      case 1:
        return "Activé";
      case -1:
        return "Annulé";
      default:
        return "";
    }
  };

  const changeBonStatus = async (id, etat) => {
    const response = await BonRetour.changeEtat(id, etat);
    if (response.status === 200 || 204) {
      message.info("Bon de transfert desactivé ");
    } else message.error("Une erreur est survenu ! ");
    loadBonRetours();
  };

  const navigate = (location) => {
    history.push(location);
  };

  const goToBonTransfer = (record) => {
    const id = record.id;
    const editMode = false;
    history.push("/BonRetour", { id, editMode });
  };

  const navigateToUpdate = (record) => {
    const id = record.id;
    const editMode = true;
    history.push("/BonRetour", { id, editMode });
  };

  const openBonTransferModal = async () => {
    setLoading(true);
    await BonTransfertService.getActiveBonTransfert((response) => {
      if (response.status === 200 || 204) {
        const data = response.data;
        for (const element of data) {
          element.showDate = moment(new Date(element.date)).format(
            "YYYY-MM-DD"
          );
        }
        setbonTransferts(response.data);
        setbonTransfertModal(true);
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  };

  const changeDevisStatus = async (id ,etat,record) => {
    const response = await BonRetour.changeEtat(id,etat,record);
    if (response.status === 200 || 204) {
       message.info("Bon de retour desactivé ");
    } else message.error("Une erreur est survenu ! ");
    loadBonRetours();
  }

  const bonRetourCols = [
    {
      title: "Référence",
      dataIndex: "reference",
      sorter: (a, b) => a.reference - b.reference,
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate - b.showDate,
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) => a.client - b.client,
    },
    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => <b>{Number(record.montant_total).toFixed(3)} TND</b>,
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Quantite",
      dataIndex: "quantite",
      render: (text, record) => <b>{Number(record.reste_a_payer).toFixed(3)} TND</b>,
      sorter: (a, b) => a.quantite - b.quantite,
    },
    {
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) => a.devise - b.devise,
    },{
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => <Dropdown.Button overlay={
        <Menu disabled={!Can("18_4")} >
          <Menu.Item  disabled={record.etat === 1} className="px-5" key="1" onClick={()=> changeDevisStatus(record.id ,1,record)}>Validé</Menu.Item>
          <Menu.Item  disabled={record.etat === -1} className="px-5" key="2" onClick={()=> changeDevisStatus(record.id ,-1,record)}>Annulé</Menu.Item>
        </Menu>
      }>{formatteEtat(record.etat)}</Dropdown.Button>
      ,
      sorter: (a, b) => a.etat - b.etat,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          {/* <Tooltip title="Dupliquer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("10_5")}
              onClick={() => confirmDuplication(record)}
              icon={<CopyOutlined />}
            />
          </Tooltip> */}
          <Tooltip title="Visualiser">
            <Button
              disabled={!Can("18_5")}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => showPDFModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              disabled={record.etat === 1 || !Can("18_3")}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => navigateToUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const showPDFModal = (record) => {
    setShowModal(true);
    setReference(record.reference);
  };
  const downloadFile = () => {
    window.open(
      url + "Files/stock/BonRetour/" + reference + ".pdf",
      "download"
    );
  };

  const bonTransfertCols = [
    {
      title: "Référence Bon de transfert",
      dataIndex: "reference",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "Client",
      dataIndex: "showClient",
      sorter: (a, b) => a.showClient.localeCompare(b.showClient),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(3)} TND</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => goToBonTransfer(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleCancel = () => {
    commandeForm.resetFields();
    setbonTransfertModal(false);
  };

  function currencyFormat(num){
    return !isNaN(num) ? Number(num)?.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num
  }

  return (
    <>
      {Can("22_1") ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Bon de Retour"}
            extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("22_2")}
                onClick={() => navigate("/bonRetour")}
              >
                Ajouter Un Bon De Retour
              </Button>
            }
          >
            <TableWithFiltres data={dataTable} columns={bonRetourCols} />
          </Card>
          <Modal
            title={
              <>
                <div className="d-flex justify-content-between ">
                  <div className="my-0">Bon de retour : {reference}</div>
                  <div className="px-5 ">
                    <Button
                      onClick={() => downloadFile()}
                      icon={<DownloadOutlined />}
                    >
                      Telecharger
                    </Button>
                  </div>
                </div>
              </>
            }
            centered
            visible={showModal}
            footer={null}
            width={1000}
            onCancel={() => setShowModal(false)}
            confirmLoading={isLoading}
          >
            {showModal && (
              <div className="heigt-500px">
                <PdfViewerModal
                  diractory="Files/stock/BonRetour/"
                  reference={reference}
                ></PdfViewerModal>
              </div>
            )}
          </Modal>

          <Modal
            visible={bnTransferModal}
            getContainer={false}
            centered
            onOk={commandeForm.submit}
            onCancel={handleCancel}
            confirmLoading={isLoading}
            title={"Liste des bons de transfert"}
            width={800}
          >
            <Spin spinning={isLoading} size="large">
              <TableWithFiltres
                data={bonTransfert}
                columns={bonTransfertCols}
              />
            </Spin>
          </Modal>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default DashboardBonRetour;
