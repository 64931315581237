import React from 'react'
import { useEffect, useState, useRef } from "react";
import { EditOutlined, HighlightOutlined, DownloadOutlined, ToolOutlined,FilePptOutlined, DollarOutlined, DeleteOutlined, SearchOutlined, CopyOutlined } from "@ant-design/icons";
import {
  Button, Card, Col, Divider, Empty, Form, Input, InputNumber, message,
  Modal, Row, Select, Spin, Space, Table, Tabs, Tag, Tooltip, DatePicker, Dropdown, Menu
} from "antd";
import Can from "../../../security/Can";
import financeService from "../../../services/finance.service";
import CompteBancaireService from "../../../services/compteBancaire.service";
import BonLivraison from "../../../services/bonLivraison.service";
import ClientService from "../../../services/client.service";
import Paiment from "../../../services/paiement.service";
import BonRetourService from "../../../services/bonRetour.service";
import PdfViewerModal from "../../../PdfViewerModal";
import { Collapse } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { environment } from "../../../env/config.env";
import HistoriqueClient from './HistoriqueClient';
const { Panel } = Collapse;
const { TabPane } = Tabs;

const url = environment.serverUrl;

const DashboardFianceClient = () => {
    const [searchForm] = Form.useForm();
    const [paiementForm] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [clientsVentes, setClientsVentes] = useState([]);
    const [paiementsModal, setPaiementsModal] = useState(false);
    const [compteInput, setCompteInput] = useState(false);
    const [comptesBancaires, setComptesBancaires] = useState([]);
    const [paiementsList, setPaiementsList] = useState([]);
    const [bonLivraisons, setBonLivraisons] = useState([]);
    const [selectedRef, setSelectedRef] = useState("");
    const [maxMontant, setMaxMontant] = useState(0);
    const [BlTable, setBlTable] = useState([]);
    const [client, setClient] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [reference,setReference] = useState();
    const [searchObj,setSearchObj] = useState({client:"",date_de:"",date_a:""});
    const [openBLModal,setOpenBLModal] = useState(false);
    const [clientTotalVentes,setClientTotalVentes] = useState(0);
    const [clientTotalReste,setClientTotalReste] = useState(0);
    const [expandedRowKeys,setExpandedRowKeys] = useState([]);
    const [bonRetours,setBonRetours] = useState([]);
    const [openBRModal,setOpenBRModal] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    
    useEffect(() => {  
      loadBonRetours()
      loadComptesBancaires();
      // loadClientVentes() 
      loadBonLivraisons()
      getClients()
      initSearch()
    }, []);

    const loadComptesBancaires = async () => {
      setLoading(true);
      const response = await CompteBancaireService.getCompteBancaires();
      if (response.status === 200 || 204) setComptesBancaires(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
    
    const loadClientVentes = async () => {
        setLoading(true);
        let res = await BonRetourService.getActiveBonRetours()
        const response = await financeService.getFinanceClient()
        if (response.status === 200 || 204) initTableData(response.data, res.data)
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
    }

    const initTableData = async (data, bonLivraisons) => {
        for (const element of data){
            let SommeBRs = 0
            let BRs = await bonLivraisons.filter(it => it.clientId == element.id)
            await BRs.map((it) => SommeBRs += Number(it.montant_total))
            element.client = element.nom
            element.total_vente = SommeBRs !=0 ? element.montant_total - SommeBRs : element.montant_total
            element.total_payé = await getClientPaiment(element.id)
            element.reste = Number(element.total_vente - element.total_payé).toFixed(3)
            setClientTotalVentes((prev) => parseFloat(prev) + parseFloat(element.total_vente))
            setClientTotalReste((prev) => parseFloat(prev) + parseFloat(element.reste))
        }
        console.log("initTableData",data)
        setDataTable(data)
    }

    const getClientPaiment = async (ClientId) => {
      let total_paye = 0
      const response = await financeService.getClientPaiment(ClientId)
      const data = response.data
      for (const element of data ){
        total_paye += element.montant
      }
      return total_paye.toFixed(3)
    } 

    const getClients = async () => {
      setLoading(true);
      const response = await ClientService.getClients()
      if (response.status === 200 || 204){
        const newClient = {nom:"tout"}
        response.data.push(newClient)
        setClientsVentes(response.data)
      } 
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    }

    const loadBonRetours = async () => {
      setLoading(true);
      let response = await BonRetourService.getActiveBonRetours()
      if (response.status === 200 || 204) setBonRetours(response.data)
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    }

    const visDisable = (record) => {
      if(!Can("12_5") || record.total_payé == "0.000" ) return true
    }

    const colums = [
        {
          title: "Client",
          dataIndex: "client",
          sorter: (a, b) => a.client.localeCompare(b.client),
        },
        {
          title: "Total Vente",
          dataIndex: "total_vente",
          render:  (text,record) =>(
            <Tag color="#2db7f5"> {currencyFormat(text)} </Tag>
          ),
          sorter: (a, b) => a.total_vente - b.total_vente,
        },
        {
          title: "Total payé",
          dataIndex: "total_payé",
          render:  (text,record) =>(
            <Tag color="#008000"> {currencyFormat(text)} </Tag>
          ),
          sorter: (a, b) => a.total_payé.localeCompare(b.total_payé),
        },
        {
          title: "Reste à payer",
          dataIndex: "reste",
          render:  (text,record) =>(
            <Tag color="#f50"> {currencyFormat(text)} </Tag>
          ),
          sorter: (a, b) => a.reste.localeCompare(b.reste),
        },
        {
            title: "Action",
            key: "action",
            width: "13%",
      
            render: (text, record) => (
              <div>
                <Tooltip title="Visualiser">
                  <Button
                    className="mx-1"
                    type="dashed"
                    shape="circle"
                    disabled={visDisable(record)}
                    onClick={() => showPDFModal(record)}
                    icon={<FilePptOutlined />}
                  />
                </Tooltip>
                <Tooltip title="Liste des règlements">
                  <Button
                    className="mx-1"
                    type="dashed"
                    shape="circle"
                    disabled={!Can("12_9")}
                    onClick={() => openPaiementModal(record)}
                    icon={<DollarOutlined />}
                  />
                </Tooltip>
              </div>
            ),
          },
    ];
  

    const subColums = [
        {
          title: "Bon de Livraison",
          dataIndex: "bonLivraison",
        },{
          title: "Moyen de paiement",
          dataIndex: "moyen_paiement",
        },{
          title: "Date",
          dataIndex: "date",
        },{
          title: "Date d'encaissement",
          dataIndex: "date_encaissement",
        },{
          title: "Montant TTC",
          dataIndex: "montant",
          render:  (text,record) =>(<>{currencyFormat(text)}</>)
        }
    ];
      
    const locale = {
        emptyText: (
          <Empty description={"Le tableau est vide pour le moment "}></Empty>
        ),
    };

    const paiementsCols = [
      {
        title: "Bon de Livraison",
        dataIndex: "bonLivraison",
        sorter: (a, b) => a.bonLivraison - b.bonLivraison,
      },
      {
        title: "Moyen de paiement",
        dataIndex: "moyen_paiement",
        sorter: (a, b) => a.moyen_paiement.localeCompare(b.moyen_paiement),
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => a.date.localeCompare(b.date),
      },
      {
        title: "Date d'encaissement",
        dataIndex: "date_encaissement",
        sorter: (a, b) =>
          a.date_encaissement.localeCompare(b.date_encaissement),
      },
      {
        title: "Montant TTC",
        dataIndex: "montant",
        render: (text, record) => <b>{currencyFormat(text)} </b>,
        sorter: (a, b) => a.montant - b.montant,
      },
  ];

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Valider
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Annuler
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const BlCol = [
    {
      title: "Référence",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
      ...getColumnSearchProps('reference'),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
      ...getColumnSearchProps('showDate'),
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) => a.client.localeCompare(b.client),
      ...getColumnSearchProps('client'),
    },
    
    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      ...getColumnSearchProps('montant_total'),
      render: (text, record) => 
        <b>
          {record.reference.includes('BR') &&  <p className='text-danger'>{currencyFormat(record.montant_total)+" TND"}</p>}
          {record.reference.includes('BL') &&  <p className='text-success'>{currencyFormat(record.montant_total)+" TND"}</p>}
        </b>,
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Reste a paye",
      dataIndex: "reste_a_payer",
      render: (text, record) => 
      <b>
        {record.reference.includes('BL') &&  <p>{Number(record.reste_a_payer).toFixed(3)+" TND"}</p>}
      </b>,
      sorter: (a, b) => a.reste_a_payer - b.reste_a_payer,
    },
    {
      title: "Devise",
      dataIndex: "devise",
      ...getColumnSearchProps('devise'),
      sorter: (a, b) => a.devise - b.devise,
    },
    {
      title: "Etat Paiement",
      dataIndex: "Etat_Paiement",
      render: (text, record) => (
        <>
          {record.paiment_status === 1 && <Tag color="#2db7f5">Payé</Tag>}
          {record.paiment_status === -1 && <Tag color="#f50">Non Payé</Tag>}
        </>
      ),
      sorter: (a, b) => a.paiement_status - b.paiement_status,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Visualiser">
            <Button
              disabled={!Can("18_5")}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => {if(record.reference.includes('BL')) showBLModal(record); else showBRModal(record)}}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  
    
  const openPaiementModal = async (record) => {
    setLoading(true);
    setClient(record)
    const response = await financeService.getBonLivrisons(record.id)
    getBLBRListe(response.data, record)
    getPaimentList(response.data,record.id)
    setMaxMontant(Number(record.reste))
    setPaiementsModal(true);
    setLoading(false);
  };

  const getBLBRListe = async (BLs, record) => {
    setLoading(true);
    const clientId = record.id
    const BRs = bonRetours.filter(it => it.clientId == clientId)
    BLs = BLs.filter((it)=> it.etat != -1)
    for (const element of BRs){
      element.client = record.nom
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
      element.reste_a_payer = "-"
      element.Etat_Paiement = "-"
    }

    for (const element of BLs){
      element.client = record.nom
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
    }
    let BLsBRs = BLs.concat(BRs)
    BLsBRs = validateDataByDate(BLsBRs)
    setBonLivraisons(BLsBRs)
    setLoading(false);
  }

  const getPaimentList = async (BL,clientId) => {
  const response  = await Paiment.getClientPaiements(clientId)
  let data = response.data
  for (const element of data){
    element.date = moment(element.date).format("YYYY MM DD") 
    element.date_encaissement = element.date_encaissement ? element.date_encaissement : "-" 
    element.bonLivraison = element.id
  }
  data = validateDataByDate(data)
  setPaiementsList(data)
  }

  const selectChange = () => {
    const paiement = paiementForm.getFieldsValue();
    const moyen_paiement = paiement.moyen_paiement;
    const moyens = ["Carte Bancaire", "Chèque", "Virement"];
    if (moyens.includes(moyen_paiement)) setCompteInput(true);
    else setCompteInput(false);
  };

  const closePaiementModal = () => {
    setPaiementsModal(false);
    paiementForm.resetFields();
  };

  const addPaiement = async (values) => {
    setLoading(true);
    const data = values;
    data.etat = 1;
    data.clientId = client.id

    const response = await Paiment.addPaiementGlobal(data,client.id)
    if (response.status === 200) {
      setPaiementsModal(false);
      loadClientVentes()
      paiementForm.resetFields();
      message.info("Paiement ajouter avec success");
    } else message.error("Une erreur est survenu ! ");
    setClientTotalVentes(0)
    setClientTotalReste(0)
    setLoading(false);
  };

  const loadBonLivraisons = async () => {
    setLoading(true);
    await BonLivraison.getBonLivraisons(false , async (response)=> {
      if (response.status === 200 || 204) {
        const data = response.data;
        await ClientService.getClientsNames( (resp) => {
          if (response.status === 200 || 204) {
            initBLtable(data , resp.data)
          }else message.error("Une erreur est survenu ! ");
        });
      }else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  };

  const initBLtable = (data ,clients) => {
    setLoading(true);
    const showMode = localStorage.getItem("SHOW_MODE");
    const list = [];
    for (const element of data) {
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
      element.client = getClientName(element.client);
      if(showMode === "NO") {
        if(element.etat !== -1) list.push(element);
      } else list.push(element);
    }
    setBlTable(list);
    setLoading(false);
  }

  const getClientName = (cientObj) => {
    return cientObj.nom + " " + cientObj.prenom
  }

  const showPDFModal = (record) => {
    console.log(record)
    setShowModal(true);
    setReference("BLC-"+record.id)
  } 
  const downloadFile = () => {
    window.open(url+"Files/Finance/"+reference+".pdf", 'download');
  }

  const downloadBLFile = () => {
    window.open(url+"Files/Vente/BonLivraison/"+reference+".pdf", 'download');
  }

  const downloadBRFile = () => {
    window.open(url+"Files/stock/BonRetour/"+reference+".pdf", 'download');
  }

  const showBLModal = (record) => {
    console.log(record)
    setOpenBLModal(true);
    setReference(record.reference)
  } 

  const showBRModal = (record) =>{
    console.log(record)
    setOpenBRModal(true);
    setReference(record.reference)
  }

  const initSearch = () => {
    const searchObj = searchForm.getFieldsValue()
    const starOfMonth = moment().startOf("month")
    const endOfMonth = moment().endOf("month")
    searchObj.date_de = starOfMonth 
    searchObj.date_a = endOfMonth
    searchObj.client = "tout"
    setSearchObj(searchObj)
    searchForm.setFieldsValue(searchObj)
    loadClientVentesByDate(searchObj)
  }

  const search = async (values) => {
    if (!validSearchDate(values))  return message.error("Une erreur: date debut doit être inférieur à date fin! ");
      const temp = {
        client:values.client,
        // date_de:formatDate(values.date_de),
        // date_a:formatDate(values.date_a)
        ...values
      } 
      setSearchObj(temp)
      await loadClientVentesByDate(temp);
  }

  const formatDate = (date) => {
    return date.format('YYYY-MM-DD')
  }

  const validSearchDate = (values) => {
    if (moment(values.date_de).isAfter(moment(values.date_a))) return false
    else return true
  }

  const loadClientVentesByDate = async (temp) => {
    setLoading(true);
    let bonRetours = await BonRetourService.getActiveBonRetours()
    const response = await financeService.getFinanceClientByDate(temp)
    if (response.status === 200 || 204) initTableDataByDate(response.data, temp, bonRetours.data)
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  }

  const initTableDataByDate = async (data, search, bonRetours) => {
    let total_v = 0
    let totalVenteSomme = 0
    let totalResteSomme = 0
    for (const element of data){
        let SommeBRs = 0
        let BRs = await bonRetours.filter(it => it.clientId == element.id)
        BRs = await validateDataByDate(BRs)
        await BRs.map((it) => SommeBRs += Number(it.montant_total))

        element.client = element.nom
        let temp =  element.bon_livraison_ventes?.map((it)=> Number(it?.montant_total) + total_v )
         element.total_vente = temp ? SommeBRs !=0 ? temp.reduce((a,b)=> a+b ,0) - SommeBRs : temp.reduce((a,b)=> a+b ,0) 
                                    : 0 
        element.total_payé = await getClientPaimentByDate(element.id, search)
        element.reste = Number(element.total_vente - element.total_payé).toFixed(3)
        
        totalVenteSomme += parseFloat(element.total_vente)
        totalResteSomme += parseFloat(element.reste)
    }
    setClientTotalVentes(totalResteSomme)
    setClientTotalReste(totalResteSomme)

    console.log(data)
    setDataTable(data)
  }
  
  const validateDataByDate = (data) => {
    let validDatasByDate = []
    for (let element of data){
      if ( moment(element.date).isBetween(moment(searchObj.date_de),moment(searchObj.date_a))) validDatasByDate.push(element)
    }
    return validDatasByDate
  }

  const getClientPaimentByDate = async (ClientId, search) => {
    let total_paye = 0
    const response = await financeService.getClientPaimentByDate(ClientId, search)
    const data = response.data
    for (const element of data ){
      total_paye += element.montant
    }
    return total_paye.toFixed(3)
    } 

   const cancelSearch = async () => {
    setClientTotalVentes(0)
    setClientTotalReste(0)
    // await loadClientVentes()
    initSearch()
    clearSearch()
   }

   const clearSearch = () => {
    const startOfMonth = moment().startOf("month")
    const endOfMonth = moment().endOf("month")
    const emptySearch = {client:"tout",date_de:startOfMonth,date_a:endOfMonth}
    setSearchObj(emptySearch)
    searchForm.setFieldsValue(emptySearch)
   }

    function currencyFormat(num) {
       return  Number(num)?.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

  const onTableRowExpand = async (expanded, record) => {
      setLoading(true)
      var keys = [];
      if(expanded){
          keys.push(record.id);
      }
      const response = await financeService.getBonLivrisons(record.id)
      getBLBRListe(response.data, record)
      setExpandedRowKeys(keys)
      setLoading(false)
  }

  //  maxMontant = reste a paye 
  const checkPaiement = () => {
    return maxMontant <= 0;
  }

  return (
    <>
       <Spin spinning={isLoading} size="large">
       <Tabs defaultActiveKey="1">
        <TabPane tab="Finance" key="1">
          <Card
              title={"Gestion Finanicer Client"}
          >
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-row align-center">
                    <h5> Total des Ventes  : <input style={{fontWeight:"bold"}} className="text-success text-center w-25 border-0 bg-transparent" disabled type="text" value={currencyFormat(clientTotalVentes)} /> </h5> 
                  </div>
                  <div className="d-flex flex-row align-center">
                    <h5> Total des Reste à payer  : <input style={{fontWeight:"bold"}} className="text-danger text-center w-25 border-0 bg-transparent" disabled type="text" value={currencyFormat(clientTotalReste)} /> </h5> 
                  </div>
                </div>
              <br />
             <Collapse defaultActiveKey={['1']}>
              <Panel header="Recherche" key="1">
                <Form
                layout="vertical" 
                form={searchForm} 
                onFinish={search}
                >
                  <Row className='d-flex flex-row justify-content-evenly'>
                    <Col span={12}>
                      <Form.Item 
                      name={"client"}
                      label="client"
                        rules={[
                          {
                            required: true,
                            message: 'selectionner un client !',
                          },
                        ]}>
                            <Select size="large">
                              {clientsVentes.map((cl,index)=> <Select.Option key={index} value={cl.nom}>{cl.nom}</Select.Option>  )}
                            </Select>
                        </Form.Item>
                    </Col> 
                    <Col span={4} style={{marginRight:"30px"}}>
                      <Form.Item 
                        name={"date_de"}
                        label="date debut"
                        valuePropName='date'
                        rules={[
                          {
                            required: true,
                            message: 'selectionner date de!',
                          },
                        ]}>
                              <DatePicker
                                defaultValue={moment().startOf('month')}
                                value={searchObj.date_de ? moment(searchObj.date_de) : ""}
                                placeholder=" "
                                size="large"
                                className="w-100"
                                onChange={(date,dateString)=> setSearchObj((prev)=>({...prev,date_de:dateString}))}
                              ></DatePicker>               
                        </Form.Item>
                    </Col> 
                    <Col span={4}>
                      <Form.Item 
                        name={"date_a"}
                        label="date fin"
                        valuePropName='date'
                        rules={[
                          {
                            required: true,
                            message: 'selectionner date a!',
                          },
                        ]}>
                              <DatePicker
                                defaultValue={moment().endOf('month')}
                                value={searchObj.date_a ? moment(searchObj.date_a) : ""}
                                placeholder=" "
                                size="large"
                                className="w-100"
                                onChange={(date,dateString)=> setSearchObj((prev)=>({...prev,date_a:dateString}))}
                              ></DatePicker>
                      </Form.Item>
                    </Col> 
                  </Row>
                  <Row className='d-flex justify-content-end'>
                    <Col className='px-2'>
                        <Form.Item className='m-0'>
                          <Button type="primary" htmlType="submit">
                            Valider
                          </Button>
                        </Form.Item>
                    </Col>
                    <Col className='px-2'>
                          <Button onClick={cancelSearch} type="danger">
                            Annuler
                          </Button>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
            <br />
            <Table columns={colums}
              dataSource={dataTable}
              pagination={{ position: ["bottomCenter"], pageSize: 50 }}
              scroll={{ y: 500 }}
              rowKey={(record) => record.id}
              onExpand={onTableRowExpand}
              expandedRowKeys={expandedRowKeys}
              expandable={{
                rowExpandable: record => record.id !== null,
                expandedRowRender: record => <>
                <Spin spinning={isLoading} size="large">
                    <Table
                      rowKey={record.id}
                      locale={locale}
                      columns={BlCol}
                      dataSource={bonLivraisons}
                      pagination={{ position: ["none"], pageSize: 50 }}          
                    />
                </Spin>
                </>,
              }}
            />
        </Card>
        </TabPane>
        <TabPane tab="Historique" key="2">
              <HistoriqueClient />
        </TabPane>
      </Tabs>

        <Modal
            visible={paiementsModal}
            getContainer={false}
            //centered
            onOk={paiementForm.submit}
            onCancel={() => closePaiementModal()}
            confirmLoading={isLoading}
            title={"Paiements"}
            width={1100}
          >
            <hr />
            <Tabs defaultActiveKey="3">
              <Tabs.TabPane tab="Liste des BL" key="3">
                <Table
                  showSorterTooltip={false}
                  rowKey={(record) => record.id}
                  locale={locale}
                  columns={BlCol}
                  dataSource={bonLivraisons}
                  pagination={{ position: ["bottomCenter"], pageSize: 50 }} 
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Liste des paiements" key="1">
                <Table
                  showSorterTooltip={false}
                  rowKey={(record) => record.id}
                  locale={locale}
                  columns={paiementsCols}
                  dataSource={paiementsList}
                  pagination={{ position: ["bottomCenter"], pageSize: 50 }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                 disabled={checkPaiement()}
                tab="Nouveau paiement"
                key="2"
              >
                <Form
                  layout="vertical"
                  form={paiementForm}
                  onFinish={addPaiement}
                >
                  <Row>
                    <Col span={24} className="px-2">
                      <Form.Item label="Type de paiement" name="moyen_paiement">
                        <Select size="large" onChange={() => selectChange()}>
                          <Select.Option key="1" value="Espece">
                            Espece
                          </Select.Option>
                          <Select.Option key="2" value="Carte Bancaire">
                            Carte Bancaire
                          </Select.Option>
                          <Select.Option key="3" value="Chèque">
                            Chèque
                          </Select.Option>
                          <Select.Option key="4" value="Virement">
                            Virement
                          </Select.Option>
                          <Select.Option key="5" value="Effet">
                            Effet
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Montant"
                        name="montant"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          min={0}
                          max={maxMontant}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Date"
                        name="date"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder=" "
                          size="large"
                          className="w-100"
                        ></DatePicker>
                      </Form.Item>
                    </Col>
                    {compteInput && (
                      <>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Compte Bancaire"
                            name="compteBancaireId"
                          >
                            <Select
                              size="large"
                              onChange={() => selectChange()}
                            >
                              {comptesBancaires &&
                                comptesBancaires.map((compte,index) => (
                                  <Select.Option
                                    key={index}
                                    value={compte.id}
                                  >
                                    {compte.designation}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Date d'encaissement"
                            name="date_encaissement"
                            rules={[
                              {
                                required: true,
                                message: "Champ obligatoire !",
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder=" "
                              size="large"
                              className="w-100"
                            ></DatePicker>
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    <Col span={24} className="px-2">
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            message:
                              "Champ obligatoire avec maximum de caractère 255 !",
                            max: 254,
                          },
                        ]}
                      >
                        <TextArea rows={3} type="text" maxLength={255} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Tabs.TabPane>
            </Tabs>
          </Modal>

          <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  Bilan Financier : {reference}
                </div>
                <div className="px-5 ">
                  <Button  onClick={()=> downloadFile()} icon={<DownloadOutlined />} >
                    Telecharger
                  </Button>
                </div>
              </div>
            </>
          }
          //centered
          visible={showModal}
          footer={null}
          width={1000}
          onCancel={()=> setShowModal(false)}
          confirmLoading={isLoading}
        >
          { showModal && 
            <div className="heigt-500px">
              <PdfViewerModal 
                diractory="Files/Finance/" 
                reference={reference}  
              ></PdfViewerModal>
            </div>
          }
        </Modal>  
        <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  Bon de Livraison : {reference}
                </div>
                <div className="px-5 ">
                  <Button  onClick={()=> downloadBLFile()} icon={<DownloadOutlined />} >
                    Telecharger
                  </Button>
                </div>
              </div>
            </>
          }
          //centered
          visible={openBLModal}
          footer={null}
          width={1000}
          onCancel={()=> setOpenBLModal(false)}
          confirmLoading={isLoading}
        >
          { openBLModal && 
            <div className="heigt-500px">
              <PdfViewerModal 
                diractory="Files/Vente/BonLivraison/" 
                reference={reference}  
              ></PdfViewerModal>
            </div>
          }
        </Modal>  

        <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  Bon de Retour : {reference}
                </div>
                <div className="px-5 ">
                  <Button  onClick={()=> downloadBRFile()} icon={<DownloadOutlined />} >
                    Telecharger
                  </Button>
                </div>
              </div>
            </>
          }
          //centered
          visible={openBRModal}
          footer={null}
          width={1000}
          onCancel={()=> setOpenBRModal(false)}
          confirmLoading={isLoading}
        >
          { openBRModal && 
            <div className="heigt-500px">
              <PdfViewerModal 
                diractory="Files/stock/BonRetour/" 
                reference={reference}  
              ></PdfViewerModal>
            </div>
          }
        </Modal>  

    </Spin>  
    </>
  )
}

export default DashboardFianceClient