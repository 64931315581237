import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addBonLivraison = async ( bon_livraison ) => {
  return await axios.post(API_URL + "/v/bon_livraison" , bon_livraison ,{ headers: authHeader() });
};

const getBonLivraisons = async ( type ,callback ) => {
  callback( await axios.get(API_URL + "/v/bon_livraison/type/"+type , { headers: authHeader() }))
};

const getBonLivraisonsEnLigne = async ( type ,callback ) => {
  callback( await axios.get(API_URL + "/v/bon_livraison/type/en-ligne/"+type , { headers: authHeader() }))
};

const getBonLivraisonsBySearch = async ( searchObj ) => {
  return await axios.post(API_URL + "/v/bon_livraison/search" , searchObj , { headers: authHeader() });
};

const getBonLivraisonsBySearchEnLigne = async ( searchObj ) => {
  return await axios.post(API_URL + "/v/bon_livraison/search/en-ligne" , searchObj , { headers: authHeader() });
};

const getBonLivraison = async ( id ) => {
  return await axios.get(API_URL + "/v/bon_livraison/" + id , { headers: authHeader() })
};
const updateBonLivraison = (id , bon_livraison ) => {
  return axios.put(API_URL + "/v/bon_livraison/" + id , bon_livraison, { headers: authHeader() });
};

const updateActiveBonLivraisonEnLigne = (id , bon_livraison ) => {
  return axios.put(API_URL + "/v/bon_livraison/active-blvl/" + id , bon_livraison, { headers: authHeader() });
};

const deleteBonLivraison = (id) => {
  return axios.delete(API_URL + "/v/bon_livraison/" + id , { headers: authHeader() });
};
const changeEtat = (id ,etat) => {
  return axios.post(API_URL + "/v/bon_livraison/state/" + id , {etat : etat} , { headers: authHeader() });
};

const changeEtatEnLigne = (id ,etat) => {
  return axios.post(API_URL + "/v/bon_livraison/state/en-ligne/" + id , {etat : etat} , { headers: authHeader() });
};

const changeStateEnLigneEnCourLivraison = (id ,etat) => {
  return axios.post(API_URL + "/v/bon_livraison/state/en-ligne/etat-en-cours-livraison/" + id , {etat : etat} , { headers: authHeader() });
};

const annuler = (id, etat) => {
  return axios.post(API_URL + "/v/bon_livraison/state/en-ligne/annuler/" + id , {etat : etat} , { headers: authHeader() });
};

const annulerBLVLActive = (id, etat) => {
  return axios.post(API_URL + "/v/bon_livraison/state/en-ligne/active/annuler/" + id , {etat : etat} , { headers: authHeader() });
};

const getActiveReception = async (id) => {
  return await axios.get(API_URL + "/v/bon_livraison/active/" + id ,{ headers: authHeader() });
}

const getActiveBonLivraison = async () => {
  return await axios.get(API_URL + "/v/bon_livraison/all/active" ,{ headers: authHeader() });
}

const getLast = async (callback) => {
  callback( await axios.get(API_URL + "/v/bon_livraison/last", { headers: authHeader() }))
}
const duplicate = async ( id) => {
  return axios.get(API_URL + "/v/bon_livraison/duplicate/" + id , {headers: authHeader()})
}

const exportsMethodes = {
  addBonLivraison,
  getBonLivraisons,
  getBonLivraison,
  updateBonLivraison,
  updateActiveBonLivraisonEnLigne,
  deleteBonLivraison,
  changeEtat,
  getActiveReception,
  getActiveBonLivraison,
  getLast,
  duplicate,
  getBonLivraisonsBySearch,
  getBonLivraisonsEnLigne,
  getBonLivraisonsBySearchEnLigne,
  changeEtatEnLigne,
  changeStateEnLigneEnCourLivraison,
  annuler,
  annulerBLVLActive
};
export default exportsMethodes;