import React from 'react'
import { useEffect, useState, useRef } from "react";
import {
  Button, Card, Col, Divider, Empty, Form, Input, InputNumber, message,
  Modal, Row, Select, Spin, Space, Table, Tabs, Tag, Tooltip, DatePicker, Dropdown, Menu
} from "antd";
import stockService from "../../../services/Stock.service";
import userService from "../../../services/user.service";
import { Collapse } from 'antd';
import moment from 'moment';
const { Panel } = Collapse;

const MouvementStock = () => {
  const [searchForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchObj,setSearchObj] = useState({user:"",date_de:"",date_a:""});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {  
    getUsers()
    initSearch()
  }, []);

  const initSearch = () =>{
    const searchObj = searchForm.getFieldsValue()
    const starOfMonth = moment().startOf("month")
    const endOfMonth = moment().endOf("month")
    searchObj.date_de = starOfMonth 
    searchObj.date_a = endOfMonth
    searchObj.user = "tout"
    setSearchObj(searchObj)
    searchForm.setFieldsValue(searchObj)
    loadUserStockActions(searchObj)
  }

  const loadUserStockActions = async (searchObj) => {
    setLoading(true);
    const response = await stockService.getUserStockActions(searchObj)
    if (response.status === 200 || 204) initTableData(response.data)
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  }

  const getUsers = async () => {
    setLoading(true);
    const response = await userService.getUsers()
    if (response.status === 200 || 204) initUsers(response.data)
    else message.error("Une erreur est survenu ! ");
    console.log(response.data)
    setLoading(false);
  }

  const initUsers = (data) => {
    for (let element of data){
      element.password = ""
    } 
    data.push({nom_prenom:"tout"})
    setUsers(data)
  }

  const initTableData = (data) => {
    for (let element of data){
      element.user = element.auth_user.nom_prenom
      element.type = getProduitType(element.type)
      element.produit = element.produitId ? element.produit?.libelle : ""
      element.date = moment(element.data).format("YYYY-MM-DD");
    }
    setDataTable(data)
  }

  const getProduitType = (type) => {
    switch(type){
      case 1:
        return "MP"
      case 2:
        return "PC"
      case 3:
        return "PB"
      case 4:
        return "PA"
      case 5:
        return "PF"
    }
  }

  const search = async (values) => {
    if (!validSearchDate(values))  return message.error("Une erreur: date debut doit être inférieur à date fin! ");
      const temp = {
        user:values.user,
        date_de:formatDate(values.date_de),
        date_a:formatDate(values.date_a)
      } 
      setSearchObj(temp)
      await loadUserStockActions(temp);
  }

  const formatDate = (date) => {
    return date.format('YYYY-MM-DD')
  }

  const validSearchDate = (values) => {
    if (moment(values.date_de).isAfter(moment(values.date_a))) return false
    else return true
  }

  const cancelSearch = () => {
    initSearch()
    clearSearch()
  }


  const clearSearch = () => {
    const startOfMonth = moment().startOf("month")
    const endOfMonth = moment().endOf("month")
    const emptySearch = {user:"",date_de:startOfMonth,date_a:endOfMonth}
    setSearchObj(emptySearch)
    searchForm.setFieldsValue(emptySearch)
  }

  const colums =  [
    {
      title: "Utilisateur",
      dataIndex: "user",
      sorter: (a, b) => a.uthUserId.localeCompare(b.uthUserId),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
      title: "Mouvement",
      dataIndex: "mouvement",
      sorter: (a, b) => a.mouvement.localeCompare(b.mouvement),
    },
    {
      title: "Produit",
      dataIndex: "produit",
      sorter: (a, b) => a.produit.localeCompare(b.produit),
    },
    {
      title: "QteIn",
      dataIndex: "qteIn",
      sorter: (a, b) => a.qteIn - b.qteIn,
    },
    {
      title: "QteOut",
      dataIndex: "qteOut",
      sorter: (a, b) => a.qteOut - b.qteOut,
    },
    {
      title: "type",
      dataIndex: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
  ]

  return (
    <>
       <Spin spinning={isLoading} size="large">
          <Card
              title={"Mouvement Stock"}
          >
             <Collapse defaultActiveKey={['1']}>
              <Panel header="Recherche" key="1">
                <Form
                layout="vertical" 
                form={searchForm} 
                onFinish={search}
                >
                  <Row className='d-flex flex-row justify-content-evenly'>
                    <Col span={12}>
                      <Form.Item 
                      name={"user"}
                      label="Utilisateur"
                        rules={[
                          {
                            required: true,
                            message: 'selectionner un User !',
                          },
                        ]}>
                            <Select size="large">
                              {users.map((cl,index)=> <Select.Option key={index} value={cl.nom_prenom}>{cl.nom_prenom}</Select.Option>  )}
                            </Select>
                        </Form.Item>
                    </Col> 
                    <Col span={4} style={{marginRight:"30px"}}>
                      <Form.Item 
                        name={"date_de"}
                        label="date debut"
                        valuePropName='date'
                        rules={[
                          {
                            required: true,
                            message: 'selectionner date de!',
                          },
                        ]}>
                              <DatePicker
                                defaultValue={moment().startOf('month')}
                                value={searchObj.date_de ? moment(searchObj.date_de) : ""}
                                placeholder=" "
                                size="large"
                                className="w-100"
                                onChange={(date,dateString)=> setSearchObj((prev)=>({...prev,date_de:dateString}))}
                              ></DatePicker>               
                        </Form.Item>
                    </Col> 
                    <Col span={4}>
                      <Form.Item 
                        name={"date_a"}
                        label="date fin"
                        valuePropName='date'
                        rules={[
                          {
                            required: true,
                            message: 'selectionner date a!',
                          },
                        ]}>
                              <DatePicker
                                defaultValue={moment().endOf('month')}
                                value={searchObj.date_a ? moment(searchObj.date_a) : ""}
                                placeholder=" "
                                size="large"
                                className="w-100"
                                onChange={(date,dateString)=> setSearchObj((prev)=>({...prev,date_a:dateString}))}
                              ></DatePicker>
                      </Form.Item>
                    </Col> 
                  </Row>
                  <Row className='d-flex justify-content-end'>
                    <Col className='px-2'>
                        <Form.Item className='m-0'>
                          <Button type="primary" htmlType="submit">
                            Valider
                          </Button>
                        </Form.Item>
                    </Col>
                    <Col className='px-2'>
                          <Button onClick={cancelSearch} type="danger">
                            Annuler
                          </Button>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
            <br />
            <Table columns={colums}
              dataSource={dataTable}
              pagination={{ position: ["bottomCenter"], pageSize: 50 }}
              scroll={{ y: 500 }}
              rowKey={(record) => record.id}
            />
        </Card>

    </Spin>  
    </>
  )
}

export default MouvementStock