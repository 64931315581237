import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import typeBroderie from "../../../../helpers/typeBroderie";

import Can from "../../../../security/Can";
import BroderieService from "../../../../services/broderie.service";
import OrdreCoupeService from "../../../../services/ordreCoupe.service";

import referenceBroderieService from "../../../../services/referenceBroderie.service";
import typeCoupeService from "../../../../services/typeCoupe.service";
import typeOrdreCoupeService from "../../../../services/typeOrdreCoupe.service";

import clientService from "../../../../services/client.service";
import fournisseurService from "../../../../services/fournisseur.service";
import produitService from "../../../../services/produit.service";
import typeTissusService from "../../../../services/typeTissus.service";
import AppTable from "../../../uiHelpers/AppTabe";

import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

const Broderie = () => {
  const [broderieForm] = Form.useForm();
  const [broderieModal, setBroderieModal] = useState(false);
  const [validateCoupeModal, setValidateCoupeModal] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();

  const [isValidation, setValidation] = useState(false);
  const [selectedType, setSelectedType] = useState(false);
  const [typeOrdreCoupe, setTypeBroderie] = useState([]);

  const [dataTable, setDataTable] = useState([]);

  const [typeCoupe, setTypeCoupe] = useState([]);
  const [typeTissu, setTypeTissu] = useState([]);

  const [referencesBroderies, setReferencesBroderie] = useState([]);
  const [produits, setProduits] = useState([]);
  const [clients, setClients] = useState([]);
  const [fournisseurs, setFournisseurs] = useState([]);

  const [etat, setEtat] = useState(0);
  const [isStateChange, setStateChange] = useState(0);

  useEffect(() => {
    loadTypeCoupes();
    loadTypeTissus();
    loadBroderies();
    loadProduits();
    loadTypeBroderies();
    loadReferencesBrodrie();
    loadFournisseurs();
    loadClients();

    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };

  const loadBroderies = async () => {
    setLoading(true);
    const response = await BroderieService.getBroderies();
    const tCoup = await typeCoupeService.getTypeCoupes();
    const tTissus = await typeTissusService.getTypeTissus();
    if (response.status === 200 || 204)
      initTableData(response.data, tCoup.data, tTissus.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadTypeBroderies = async () => {
    setLoading(true);
    const response = await typeOrdreCoupeService.getTypeOrderCoupes();
    if (response.status === 200 || 204) setTypeBroderie(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadReferencesBrodrie = async () => {
    setLoading(true);
    const response = await referenceBroderieService.getReferenceBroderies();
    if (response.status === 200 || 204) setReferencesBroderie(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadTypeCoupes = async () => {
    setLoading(true);
    const response = await typeCoupeService.getTypeCoupes();
    if (response.status === 200 || 204) setTypeCoupe(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadTypeTissus = async () => {
    setLoading(true);
    const response = await typeTissusService.getTypeTissus();
    if (response.status === 200 || 204) setTypeTissu(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadFournisseurs = async () => {
    setLoading(true);
    const response = await fournisseurService.getFournisseurs();
    if (response.status === 200 || 204) setFournisseurs(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadClients = async () => {
    setLoading(true);
    const response = await clientService.getClients();
    if (response.status === 200 || 204) setClients(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadProduits = async () => {
    setLoading(true);
    const response = await produitService.getProduitFini();
    if (response.status === 200 || 204) setProduits(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const initTableData = (data, tCoupe, tTissus) => {
    for (const element of data) {
      element.showType = element.type === 1 ? "Suite Bon Commande" : "Interne";
      element.bonCommande = element?.bon_commande_vente?.reference ?? "N/A";
      element.showProduit = element?.produit?.libelle;
      element.typeCoupe =
        tCoupe.filter((e) => e?.id === element?.prodTypeCoupeId)[0]?.nom ?? "";
      element.typeTissus =
        tTissus.filter((e) => e?.id === element?.prodTypeTissuId)[0]?.nom ?? "";
      element.createdAt = moment(element.createdAt).format("YYYY-MM-DD");
    }
    var temp = sortByCreatedAt(data);
    setDataTable(temp);
  };

  const sortByCreatedAt = (data) => {
    return data
      .sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      })
      .reverse();
  };

  const showBroderieModal = () => {
    setBroderieModal(true);
  };

  const handleOk = () => {
    setBroderieModal(false);
  };

  const updateMode = (record) => {
    setBroderieModal(true);
    broderieForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
    setTimeout(() => {
      showCommandeField();
      loadStockQte();
    }, 100);
  };

  const handleCancel = () => {
    setBroderieModal(false);
    clearData();
  };

  const addBroderie = async (values) => {
    setLoading(true);
    const prodId =
      editMode && produits.find((it) => it.libelle == values.showProduit)?.id;
    values.produitId = prodId ? prodId : Number(values.showProduit);
    values.etat = values.etat ? values.etat : 0;

    const response = editMode
      ? await BroderieService.updateBroderie(updateId, values)
      : await BroderieService.addBroderie(values);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Ordre de Coupe ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    if (isStateChange)
      await BroderieService.updateOdreCoupeStatus(updateId, { etat: 1 });
    closingModal();
    setLoading(false);
  };

  const closingModal = async () => {
    await loadBroderies();
    setBroderieModal(false);
    setValidateCoupeModal(false);
    clearData();
    setEtat(0);
    setStateChange(false);
  };

  const clearData = () => {
    broderieForm.resetFields();
    broderieForm.setFieldsValue({ type: false });
    setEditMode(false);
    setUpdateId(null);
    setSelectedType(false);
  };

  const changeBroderieStatus = async (record, etat) => {
    try {
      setLoading(true);
      if (etat === 1) {
        setValidation(true);
        broderieForm.setFieldsValue(record);
        setUpdateId(record.id);
        setEtat(1);
        setEditMode(true);
        setBroderieModal(true);
        setStateChange(true);
        setTimeout(() => {
          showCommandeField();
          loadStockQte();
        }, 100);
      } else {
        await BroderieService.updateOdreCoupeStatus(record.id, { etat: etat });
        record.etat = etat;
      }
      await loadBroderies();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const showMode = (record) => {
    // setIsDisabled(true);
    setUpdateId(record.id);
    setEditMode(true);
    broderieForm.setFieldsValue(record);
    setValidateCoupeModal(true);
  };

  const formatteEtat = (etat) => {
    switch (etat) {
      case 0:
        return "En cours";
      case 1:
        return "Validé";
      case -1:
        return "Annulé";
      default:
        return "";
    }
  };

  const colums = [
    {
      title: "Numéro",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Type Ordre de Broderie",
      dataIndex: "showType",
      sorter: (a, b) => a.showType.localeCompare(b.showType),
    },
    {
      title: "Type Coupe",
      dataIndex: "typeCoupe",
      sorter: (a, b) => a.showType.localeCompare(b.showType),
    },
    {
      title: "Type Tissus",
      dataIndex: "typeTissus",
      sorter: (a, b) => a.showType.localeCompare(b.showType),
    },
    {
      title: "Produit",
      dataIndex: "showProduit",
      sorter: (a, b) => a.showProduit.localeCompare(b.showProduit),
    },
    {
      title: "Bon commande",
      dataIndex: "bonCommande",
      sorter: (a, b) => a.bonCommande.localeCompare(b.bonCommande),
    },
    {
      title: "Quantité objectif",
      dataIndex: "nombreObjectif",
      sorter: (a, b) => a.nombreObjectif.localeCompare(b.nombreObjectif),
    },
    {
      title: "Date de Creation",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => (
        <Dropdown.Button
          overlay={
            <Menu>
              <Menu.Item
                disabled={record.etat === 1}
                className="px-5"
                key="1"
                onClick={() => changeBroderieStatus(record, 1)}
              >
                Validé
              </Menu.Item>
              <Menu.Item
                disabled={record.etat === -1}
                className="px-5"
                key="4"
                onClick={() => changeBroderieStatus(record, -1)}
              >
                Annulé
              </Menu.Item>
            </Menu>
          }
        >
          {formatteEtat(record.etat, record.bn_status)}
        </Dropdown.Button>
      ),
      sorter: (a, b) => a.etat - b.etat,
    },
    {
      title: "Actions",
      key: "action",
      width: "15%",
      render: (text, record) => (
        <div>
          {/* <Tooltip title="afficher">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                disabled={record.etat !== 1}
                onClick={() => showMode(record)}
                icon={<EyeOutlined />}
              />
            </Tooltip> */}
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={record.etat === 1}
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const loadStockQte = async () => {
    setLoading(true);
    const config = broderieForm.getFieldsValue();
    if (
      config.prodTypeCoupeId !== undefined &&
      config.prodTypeTissuId !== undefined
    ) {
      const response = await OrdreCoupeService.loadStockQte({
        prodTypeCoupeId: config.prodTypeCoupeId,
        prodTypeTissuId: config.prodTypeTissuId,
      });
      const qteStockFace = response?.data?.face ?? 0;
      const qteStockDos = response?.data?.dos ?? 0;
      broderieForm.setFieldsValue({ qteStockFace, qteStockDos });
    }
    setLoading(false);
  };

  const showCommandeField = () => {
    const config = broderieForm.getFieldsValue();
    setSelectedType(config.type);
  };

  const produitChanged = () => {
    const config = broderieForm.getFieldsValue();
    if (config.showProduit) {
      const produit = produits.find((e) => e.id === Number(config.showProduit));
      console.log(produit);
      broderieForm.setFieldsValue({
        prodTypeCoupeId: produit.prodTypeCoupeId,
        prodTypeTissuId: produit.prodTypeTissuId,
        prodReferenceBroderieId: produit.prodReferenceBroderieId,
      });
      loadStockQte();
    }
  };

  const nombreReelChanged = () => {
    const config = broderieForm.getFieldsValue();
    broderieForm.setFieldsValue({
      nombreFace: config.nombreReel,
      nombreDos: 0,
    });
  };
  const nombreFaceChanged = () => {
    const config = broderieForm.getFieldsValue();
    broderieForm.setFieldsValue({
      nombreDos: 0,
      nombreReel: config.nombreFace,
    });
  };

  const nombreDosChanged = () => {
    const config = broderieForm.getFieldsValue();
    broderieForm.setFieldsValue({
      nombreFace: 0,
      nombreReel: config.nombreDos,
    });
  };

  const nombreObjChanged = () => {
    const config = broderieForm.getFieldsValue();
    broderieForm.setFieldsValue({
      nombreObjectifFace: config.nombreObjectif,
      nombreObjectifDos: 0,
    });
  };
  const nombreObjFaceChanged = () => {
    const config = broderieForm.getFieldsValue();
    broderieForm.setFieldsValue({
      nombreObjectifDos: 0,
      nombreObjectif: config.nombreObjectifFace,
    });
  };

  const nombreObjDosChanged = () => {
    const config = broderieForm.getFieldsValue();
    broderieForm.setFieldsValue({
      nombreObjectifFace: 0,
      nombreObjectif: config.nombreObjectifDos,
    });
  };

  return (
    <>
      <Spin spinning={isLoading} size="large">
        <Card
          title={"Broderie"}
          extra={
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              disabled={!Can("9_2")}
              onClick={() => showBroderieModal()}
            >
              Ajouter un ordre de broderie
            </Button>
          }
        >
          <AppTable data={dataTable} columns={colums} />

          <Form
            layout="vertical"
            form={broderieForm}
            onFinish={addBroderie}
            initialValues={{ type: 3 }}
          >
            <Modal
              visible={broderieModal}
              onOk={broderieForm.submit}
              onCancel={handleCancel}
              getContainer={false}
              confirmLoading={isLoading}
              okText="Enregistrer"
              cancelText="Annuler"
              centered
              width={1200}
              title={
                editMode
                  ? "Modifier l'ordre de broderie"
                  : "Ajouter Un ordre de broderie"
              }
            >
              <Row>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Type d'ordre de broderie"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Champ obligatoire !",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      allowClear
                      showSearch
                      disabled={isValidation}
                      onChange={() => showCommandeField()}
                      dropdownStyle={{ position: "fixed" }}
                      placeholder="Type d'ordre de broderie"
                      filterOption={(inputValue, option) => {
                        const value =
                          option.props.value?.toString().toLowerCase() || ""; // Ensure value is a string
                        const children =
                          option.props.children?.toString().toLowerCase() || ""; // Ensure children is a string
                        return (
                          value.includes(inputValue.toLowerCase()) ||
                          children.includes(inputValue.toLowerCase())
                        );
                      }}
                    >
                      {typeBroderie?.map((typeBroderie) => (
                        <Select.Option
                          key={typeBroderie.id || `key-${Math.random()}`}
                          value={typeBroderie.id || null} // Assign value only if typeBroderie.id exists
                        >
                          {typeBroderie.type}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Produit"
                    name="showProduit"
                    rules={[{ required: true, message: "Champ obligatoire !" }]}
                  >
                    <Select
                      size="large"
                      allowClear
                      showSearch
                      dropdownStyle={{ position: "fixed" }}
                      onChange={() => produitChanged()}
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0 ||
                        option.props.value
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      }
                    >
                      {produits.map((option) => (
                        <Select.Option key={option.id}>
                          {option.libelle}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {selectedType === 1 && (
                  <Col span={8} className="px-2">
                    <Form.Item
                      label="Client"
                      name="clientId"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        allowClear
                        showSearch
                        disabled={isValidation}
                        onChange={() => showCommandeField()}
                        dropdownStyle={{ position: "fixed" }}
                        placeholder="Client"
                        filterOption={(inputValue, option) => {
                          const value =
                            option.props.value?.toString().toLowerCase() || ""; // Ensure value is a string
                          const children =
                            option.props.children?.toString().toLowerCase() ||
                            ""; // Ensure children is a string
                          return (
                            value.includes(inputValue.toLowerCase()) ||
                            children.includes(inputValue.toLowerCase())
                          );
                        }}
                      >
                        {clients?.map((client) => (
                          <Select.Option
                            key={client.id || `key-${Math.random()}`}
                            value={client.id || null} // Assign value only if client.id exists
                          >
                            {client.nom} {client.prenom}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {selectedType === 2 && (
                  <Col span={8} className="px-2">
                    <Form.Item
                      label="Fournisseur"
                      name="fournisseurId"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        allowClear
                        showSearch
                        disabled={isValidation}
                        onChange={() => showCommandeField()}
                        dropdownStyle={{ position: "fixed" }}
                        placeholder="Fournisseur"
                        filterOption={(inputValue, option) => {
                          const value =
                            option.props.value?.toString().toLowerCase() || ""; // Ensure value is a string
                          const children =
                            option.props.children?.toString().toLowerCase() ||
                            ""; // Ensure children is a string
                          return (
                            value.includes(inputValue.toLowerCase()) ||
                            children.includes(inputValue.toLowerCase())
                          );
                        }}
                      >
                        {fournisseurs?.map((fournisseur) => (
                          <Select.Option
                            key={fournisseur.id || `key-${Math.random()}`}
                            value={fournisseur.id || null} // Assign value only if fournisseur.id exists
                          >
                            {fournisseur.nom} {fournisseur.prenom}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Row>
                <Col span={6} className="px-2">
                  <Form.Item
                    label="Type de Tissus"
                    name="prodTypeTissuId"
                    rules={[{ required: true, message: "Champ obligatoire !" }]}
                  >
                    <Select size="large" disabled={true}>
                      {typeTissu &&
                        typeTissu.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {c.nom}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} className="px-2">
                  <Form.Item
                    label="Type de Coupe"
                    name="prodTypeCoupeId"
                    rules={[
                      {
                        required: true,
                        message: "Champ obligatoire !",
                      },
                    ]}
                  >
                    <Select size="large" disabled={true}>
                      {typeCoupe &&
                        typeCoupe.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {c.nom}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} className="px-2">
                  <Form.Item
                    label="Référence Broderie"
                    name="prodReferenceBroderieId"
                  >
                    <Select size="large" disabled={true}>
                      {referencesBroderies.map((m) => (
                        <Select.Option key={m.id} value={m.id}>
                          {m.nom}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3} className="px-2">
                  <Form.Item
                    label="Qte Stock Face"
                    name="qteStockFace"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                      },
                    ]}
                  >
                    <Input size="large" type="text" disabled />
                  </Form.Item>
                </Col>
                <Col span={3} className="px-2">
                  <Form.Item
                    label="Qte Stock Dos"
                    name="qteStockDos"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                      },
                    ]}
                  >
                    <Input size="large" type="text" disabled />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Nombre de Pièces Objectif"
                    name="nombreObjectif"
                    rules={[{ required: true, message: "Champ obligatoire !" }]}
                  >
                    <Input
                      size="large"
                      type="text"
                      disabled={isValidation}
                      onChange={() => nombreObjChanged()}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Quantité objectif face"
                    name="nombreObjectifFace"
                    rules={[{ required: true, message: "Champ obligatoire !" }]}
                  >
                    <Input
                      size="large"
                      type="text"
                      disabled={isValidation}
                      onChange={() => nombreObjFaceChanged()}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Quantité objectif dos"
                    name="nombreObjectifDos"
                    rules={[{ required: true, message: "Champ obligatoire !" }]}
                  >
                    <Input
                      size="large"
                      type="text"
                      disabled={isValidation}
                      onChange={() => nombreObjDosChanged()}
                    />
                  </Form.Item>
                </Col>
                {isValidation && (
                  <>
                    <Col span={8} className="px-2">
                      <Form.Item
                        label="Nombre Réel Réaliser"
                        name="nombreReel"
                        rules={[
                          { required: true, message: "Champ obligatoire !" },
                        ]}
                      >
                        <Input
                          size="large"
                          type="text"
                          maxLength={100}
                          onChange={() => nombreReelChanged()}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="px-2">
                      <Form.Item
                        label="Nombre face réaliser"
                        name="nombreFace"
                        rules={[
                          { required: true, message: "Champ obligatoire !" },
                        ]}
                      >
                        <Input
                          size="large"
                          type="text"
                          maxLength={100}
                          onChange={() => nombreFaceChanged()}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="px-2">
                      <Form.Item
                        label="Nombre dos réaliser"
                        name="nombreDos"
                        rules={[
                          { required: true, message: "Champ obligatoire !" },
                        ]}
                      >
                        <Input
                          size="large"
                          type="text"
                          maxLength={100}
                          onChange={() => nombreDosChanged()}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </Modal>
          </Form>
        </Card>
      </Spin>
    </>
  );
};
export default Broderie;
