import {
  DownloadOutlined, EditOutlined, FilePptOutlined,QuestionCircleOutlined,CopyOutlined, DollarOutlined, DeleteOutlined
} from "@ant-design/icons";
import { Button, Card, Form,  Tag, InputNumber ,Dropdown, Empty, Menu, message, Modal, Spin, Tooltip, Tabs, Table, Col, Row, Select, DatePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import Can from "../../../security/Can";
import BonLivraison from "../../../services/bonLivraison.service";
import ClientService from "../../../services/client.service";
import financeService from "../../../services/finance.service";
import Paiment from "../../../services/paiement.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";
import { Collapse } from 'antd';
const { Panel } = Collapse;
  const url = environment.serverUrl;
  
  
  const DashboardBonLivraisonSimple = () => {
    const [paiementForm] = Form.useForm();
    const [searchForm] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [dataTable, setDataTable] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [reference,setReference] = useState();
    const [compteInput, setCompteInput] = useState(false);

    const [paiementsModal, setPaiementsModal] = useState(false);
    const [paiementsList, setPaiementsList] = useState([]);
    const [comptesBancaires, setComptesBancaires] = useState([]);
    const [selectedBL, setSelectedBL] = useState({});
    const [sommeCoutTotale, setSommeCoutTotale] = useState(0); 
    const [sommeResteTotale, setSommeResteTotale] = useState(0); 
    const [clientsVentes, setClientsVentes] = useState([]);
    const [searchObj,setSearchObj] = useState({client:"",date_de:"",date_a:""});
    
    useEffect(() => {
        loadBonCommande();
        getClients()
        //loadClientVentes()
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
      setEditMode(false);
      setUpdateId(null);
    };

    
    const loadBonCommande = async () => {
      setLoading(true);
      await BonLivraison.getBonLivraisons(false , async (response)=> {
        if (response.status === 200 || 204) {
          let data = response.data;
          data = getBlsOfTheMonth(data)
          await ClientService.getClientsNames( (resp) => {
            if (response.status === 200 || 204) {
              initTableData(data , resp.data)
            }else message.error("Une erreur est survenu ! ");
          });
        }else message.error("Une erreur est survenu ! ");
      });
      setLoading(false);
    };
  
    const getBlsOfTheMonth = (blList) => {
      const startOfMonth = moment().startOf("month")
      const endOfMonth = moment().endOf("month")
      initSearch(startOfMonth,endOfMonth)
      const filtredBls = []
      for (let element of blList){
        if (moment(element.date).isBetween(startOfMonth,endOfMonth)) filtredBls.push(element)
      }
      return filtredBls
    }

    const initSearch = (startOfMonth,endOfMonth) => {
      const temp = {client:"tout",date_de:startOfMonth,date_a:endOfMonth} 
      searchForm.setFieldsValue(temp)
      setSearchObj(temp)
    }

    const initTableData = (data) => {
      const showMode = localStorage.getItem("SHOW_MODE");
      const list = [];
      let totalVenteSomme = 0
      let totalResteSomme = 0
      for (const element of data) {
        element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
        element.client = getClientName(element.client);
        if(showMode === "NO") {
          if(element.etat !== -1) list.push(element);
        } else list.push(element);
        element.paiment_status = getFactureStatus(element.paiment_status)

        element.reste_a_payer = currencyFormat(element.reste_a_payer)
        element.montant_total = currencyFormat(element.montant_total)
        
        if(element.etat !== -1){
          totalVenteSomme += parseFloat(element.montant_total.replace(/,/g, '')) || 0;
          totalResteSomme += parseFloat(element.reste_a_payer.replace(/,/g, '')) || 0;
        }
      }
      setSommeCoutTotale(totalVenteSomme)
      setSommeResteTotale(totalResteSomme)
      setDataTable(list);
    };

    const getClientName = (cientObj) => {
      return cientObj?.nom + " " + cientObj?.prenom
    }
  
    const formatteEtat = (etat) => {
      switch( etat ){
        case 0 : return "En cours";
        case 1 : return "Activé";
        case -1 : return "Annulé";
        default : return ""
      }
    }
    
    const selectChange = () => {
      const paiement = paiementForm.getFieldsValue();
      const moyen_paiement = paiement.moyen_paiement;
      const moyens = ["Carte Bancaire", "Chèque", "Virement"];
      if (moyens.includes(moyen_paiement)) setCompteInput(true);
      else setCompteInput(false);
    };
  
    const changeDevisStatus = async (id ,etat) => {
      const response = await BonLivraison.changeEtat(id,etat);
      if (response.status === 200 || 204) {
         message.info("Bon de commande desactivé ");
      } else message.error("Une erreur est survenu ! ");
      loadBonCommande();
    }

    const addPaiement = async (values) => {
      const data = values;
      //const fullyPaied = Number(values.montant) === Number(factureInfo.rest) ? 1 : 2;
      //data.fullyPaied = fullyPaied;
      data.etat = 1;
      data.clientId = selectedBL.clientId
      data.bonLivraisonId = selectedBL.id
      const response = await Paiment.addPaiement(data);
      if (response.status === 200) {
        setPaiementsModal(false);
        loadBonCommande()
        paiementForm.resetFields();
        message.info("Paiement ajouter avec success");
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };

    const loadClientVentes = async () => {
      setLoading(true);
      const response = await financeService.getFinanceClient()
      if (response.status === 200 || 204) initTableData(response.data)
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
  }
  
    const navigate = (location) => {
      history.push(location);
    };
    
  
    const navigateToUpdate = (record) => {
      const id = record.id;
      const editMode = true;
      history.push("/s/bonLivraison", {id, editMode});
    }
    const showPDFModal = (record) => {
      setShowModal(true);
      setReference(record.reference)
    } 
    const downloadFile = () => {
      window.open(url+"Files/Vente/BonLivraison/"+reference+".pdf", 'download');
    }

    const confirmDuplication = (record) => {
      Modal.confirm({
        icon: <QuestionCircleOutlined  />,
        content: "Êtes-vous sûr de dupliquer ce bon de livraison ?",
        okText: "Oui",
        onOk: () => duplicateData(record),
        cancelText: "Non",
      });
    };
    const duplicateData = async (record) => {
      const response = await BonLivraison.duplicate(record.id);
      if(response.data){
        const id  = response.data.id;
        message.success("Duplication avec success");
        history.push("/s/bonLivraison", {id});
      }else message.error("Une erreur est survenu ! ");
    }

    const openPaiementModal = async (record) => {
      setLoading(true);
      const response = await BonLivraison.getBonLivraison(record.id)
      setSelectedBL(response.data)
      getPaimentList(response.data)
      //setMaxMontant(Number(record.reste))
      setPaiementsModal(true);
      setLoading(false);
    };
 
    const getPaimentList = async (BL) => {
      console.log("passed BL",BL)
      const response  = await Paiment.getPaiements(BL.id)
      const data = response.data
      console.log("pariments",data)
      for (const element of data){
        element.date = moment(element.paiement_vente.date).format("YYYY MM DD") 
        element.date_encaissement = element.date_encaissement ? element.date_encaissement : "-" 
        //element.bonLivraison = BL.filter((it) => it.id == element.bonLivraisonVenteId)[0].reference
        element.bonLivraison = BL.reference
        element.montant = element.paiement_vente.montant
        element.moyen_paiement = element.paiement_vente.moyen_paiement
      }
      console.log("data",data)
      setPaiementsList(data)
      }
  
    const getFactureStatus = (status) => {
        switch (status) {
          case 1:
            return "Payé";
          case -1:
            return "Non Payé";
        }
    };

    const closePaiementModal = () => {
      setPaiementsModal(false);
      paiementForm.resetFields();
    };

    const locale = {
      emptyText: (
        <Empty description={"Le tableau est vide pour le moment "}></Empty>
      ),
    };

  const getClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients()
    if (response.status === 200 || 204){
      const newClient = {nom:"tout"}
      response.data.push(newClient)
      setClientsVentes(response.data)
    } 
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  }

  const search = async () => {
    const searchObj = searchForm.getFieldsValue()
    const { id } = clientsVentes.find((it)=>it.nom == searchObj.client)
    searchObj.clientId = id
    console.log(searchObj)
    const response = await BonLivraison.getBonLivraisonsBySearch(searchObj);
    initTableData(response.data)
    setSearchObj(searchObj)
  }

  const cancelSearch = () => {
    loadBonCommande()
    clearSearch()
  }

  const clearSearch = () => {
    const emptySearch = {client:"",date_de:"",date_a:""}
    setSearchObj(emptySearch)
    searchForm.setFieldsValue(emptySearch)
  }

    const BonCommandes = [
      {
        title: "Référence",
        dataIndex: "reference",
        sorter: (a, b) => a.reference - b.reference,
      },
      {
        title: "Date",
        dataIndex: "showDate",
        sorter: (a, b) => a.showDate - b.showDate,
      },
      {
        title: "Client",
        dataIndex: "client",
        sorter: (a, b) => a.client - b.client,
      },
      {
        title: "Montant TTC",
        dataIndex: "montant_total",
        render: (text, record) => <b>{Number(record.montant_total).toFixed(3)} TND</b>,
        sorter: (a, b) => a.montant_total - b.montant_total,
      },
      {
        title: "Reste a paye",
        dataIndex: "reste_a_payer",
        render: (text, record) => <b>{Number(record.reste_a_payer).toFixed(3)} TND</b>,
        sorter: (a, b) => a.reste_a_payer - b.reste_a_payer,
      },
      {
        title: "Devise",
        dataIndex: "devise",
        sorter: (a, b) =>a.devise.localeCompare(b.devise),
      },{
        title: "Etat",
        dataIndex: "etat",
        render: (text, record) => <Dropdown.Button overlay={
          <Menu disabled={!Can("18_4")} >
            <Menu.Item  disabled={record.etat === 1} className="px-5" key="1" onClick={()=> changeDevisStatus(record.id ,1)}>Validé</Menu.Item>
            <Menu.Item  disabled={record.etat === -1} className="px-5" key="2" onClick={()=> changeDevisStatus(record.id ,-1)}>Annulé</Menu.Item>
          </Menu>
        }>{formatteEtat(record.etat)}</Dropdown.Button>
        ,
        sorter: (a, b) => a.etat - b.etat,
      },
      {
      title: "Etat Paiement",
      dataIndex: "paiment_status",
      render: (text, record) => (
        <>
          {record.paiment_status === "Payé" && <Tag color="#2db7f5">Payé</Tag>}
          {record.paiment_status === "Non Payé" && <Tag color="#f50">Non Payé</Tag>}
        </>
      ),
      sorter: (a, b) => a.paiement_status - b.paiement_status,
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
          <Tooltip title="Dupliquer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("10_5")}
              onClick={() => confirmDuplication(record)}
              icon={<CopyOutlined />}
            />
          </Tooltip>
            <Tooltip title="Visualiser">
              <Button
                disabled={!Can("18_5")}
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => showPDFModal(record)}
                icon={<FilePptOutlined />}
              />
            </Tooltip>
            <Tooltip title="Mettre à jour">
              <Button
                disabled={record.etat === 1 || !Can("18_3")}
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => navigateToUpdate(record)}
                icon={<EditOutlined />}
              />
            </Tooltip>
            <Tooltip title="Liste des règlements">
                  <Button
                    className="mx-1"
                    type="dashed"
                    shape="circle"
                    disabled={record.etat == 0 || !Can("12_9")}
                    onClick={() => openPaiementModal(record)}
                    icon={<DollarOutlined />}
                  />
            </Tooltip>
          </div>
        ),
      },
    ];
  
    const paiementsCols = [
      {
        title: "Bon de Livraison",
        dataIndex: "bonLivraison",
        sorter: (a, b) => a.moyenPaiement.localeCompare(b.moyenPaiement),
      },
      {
        title: "Moyen de paiement",
        dataIndex: "moyen_paiement",
        sorter: (a, b) => a.moyenPaiement.localeCompare(b.moyenPaiement),
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => a.showDate.localeCompare(b.showDate),
      },
      {
        title: "Date d'encaissement",
        dataIndex: "date_encaissement",
        sorter: (a, b) =>
          a.showEncaissementDate.localeCompare(b.showEncaissementDate),
      },
      {
        title: "Montant TTC",
        dataIndex: "montant",
        render: (text, record) => <b>{Number(record.montant).toFixed(3)} </b>,
        sorter: (a, b) => a.montant - b.montant,
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
            <Tooltip title="Supprimer">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
              /*onClick={() => deletePaiement(record)} */
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </div>
        ),
      },
  ];

  function currencyFormat(num){
    return !isNaN(num) ? Number(num)?.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num
  }

    return (
      <>
      {Can("18_1") ? (

      <Spin spinning={isLoading} size="large">
        <Card
          title={"Bon de livraison simple"}
          extra={
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              disabled={!Can("18_2")}

              onClick={() => navigate("/s/bonLivraison")}
            >
              Ajouter Un Bon De Livraison Simple
            </Button>
          }
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-row align-center text-2xl font-bold">
              <h5> Montant Total des BL  : <input style={{fontWeight:"bold"}} className="text-success text-center w-25 border-0 bg-transparent" disabled type="text" value={currencyFormat(sommeCoutTotale)} /> </h5> 
            </div>
            <div className="d-flex flex-row align-center">
              <h5> Montant Total des  Reste à payer  : <input style={{fontWeight:"bold"}} className="text-danger text-center w-25 border-0 bg-transparent font-bold" disabled type="text" value={currencyFormat(sommeResteTotale)} /> </h5> 
            </div>
          </div>
          <br />
          <Collapse defaultActiveKey={['1']}>
              <Panel header="Recherche" key="1">
                <Form
                layout="vertical" 
                form={searchForm} 
                onFinish={search}
                >
                  <Row className='d-flex flex-row justify-content-evenly'>
                    <Col span={12}>
                      <Form.Item 
                      name={"client"}
                      label="client"
                      defaultValue="tout"
                        rules={[
                          {
                            required: true,
                            message: 'selectionner un client !',
                          },
                        ]}>
                            <Select size="large">
                              {clientsVentes.map((cl,index)=> <Select.Option key={index} value={cl.nom}>{cl.type == 0 ? cl.nom :  cl.raison_sociale}</Select.Option>  )}
                            </Select>
                        </Form.Item>
                    </Col> 
                    <Col span={4} style={{marginRight:"30px"}}>
                      <Form.Item 
                        name={"date_de"}
                        label="date debut"
                        valuePropName='date'
                        rules={[
                          {
                            required: true,
                            message: 'selectionner date de!',
                          },
                        ]}>
                              <DatePicker
                                defaultValue={moment().startOf('month')}
                                value={searchObj.date_de ? moment(searchObj.date_de) : ""}
                                placeholder=" "
                                size="large"
                                className="w-100"
                                onChange={(date,dateString)=> setSearchObj((prev)=>({...prev,date_de:dateString}))}
                              ></DatePicker>               
                        </Form.Item>
                    </Col> 
                    <Col span={4}>
                      <Form.Item 
                        name={"date_a"}
                        label="date fin"
                        valuePropName='date'
                        rules={[
                          {
                            required: true,
                            message: 'selectionner date a!',
                          },
                        ]}>
                              <DatePicker
                                defaultValue={moment().endOf('month')}
                                value={searchObj.date_a ? moment(searchObj.date_a) : ""}
                                placeholder=" "
                                size="large"
                                className="w-100"
                                onChange={(date,dateString)=> setSearchObj((prev)=>({...prev,date_a:dateString}))}
                              ></DatePicker>
                      </Form.Item>
                    </Col> 
                  </Row>
                  <Row className='d-flex justify-content-end'>
                    <Col className='px-2'>
                        <Form.Item className='m-0'>
                          <Button type="primary" htmlType="submit">
                            Valider
                          </Button>
                        </Form.Item>
                    </Col>
                    <Col className='px-2'>
                          <Button onClick={cancelSearch} type="danger">
                            Annuler
                          </Button>
                    </Col>
                  </Row>
                </Form>
              </Panel>
          </Collapse>
          <br />
          <TableWithFiltres data={dataTable} columns={BonCommandes} />
        </Card>
        <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  Bon de Livraison : {reference}
                </div>
                <div className="px-5 ">
                  <Button  onClick={()=> downloadFile()} icon={<DownloadOutlined />} >
                    Telecharger
                  </Button>
                </div>
              </div>
            </>
          }
          centered
          visible={showModal}
          footer={null}
          width={1000}
          onCancel={()=> setShowModal(false)}
          confirmLoading={isLoading}
        >
          { showModal && 
            <div className="heigt-500px">
              <PdfViewerModal 
                diractory="Files/Vente/BonLivraison/" 
                reference={reference}  
              ></PdfViewerModal>
            </div>
          }
        </Modal>  

        <Modal
            visible={paiementsModal}
            getContainer={false}
            centered
            onOk={paiementForm.submit}
            onCancel={() => closePaiementModal()}
            confirmLoading={isLoading}
            title={"Paiements"}
            width={900}
          >
            <hr />
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Liste des paiements" key="1">
                <Table
                  showSorterTooltip={false}
                  rowKey={(record) => record.id}
                  locale={locale}
                  columns={paiementsCols}
                  dataSource={paiementsList}
                  pagination={{ position: ["bottomCenter"], pageSize: 50 }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Nouveau paiement"
                key="2"
              >
                <Form
                  layout="vertical"
                  form={paiementForm}
                  onFinish={addPaiement}
                >
                  <Row>
                    <Col span={24} className="px-2">
                      <Form.Item label="Type de paiement" name="moyen_paiement">
                        <Select size="large" onChange={() => selectChange()}>
                          <Select.Option key="1" value="Espece">
                            Espece
                          </Select.Option>
                          <Select.Option key="2" value="Carte Bancaire">
                            Carte Bancaire
                          </Select.Option>
                          <Select.Option key="3" value="Chèque">
                            Chèque
                          </Select.Option>
                          <Select.Option key="4" value="Virement">
                            Virement
                          </Select.Option>
                          <Select.Option key="5" value="Effet">
                            Effet
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Montant"
                        name="montant"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          min={0}
                          max={selectedBL.reste_a_payer}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Date"
                        name="date"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder=" "
                          size="large"
                          className="w-100"
                        ></DatePicker>
                      </Form.Item>
                    </Col>
                    {compteInput && (
                      <>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Compte Bancaire"
                            name="compteBancaireId"
                          >
                            <Select
                              size="large"
                              onChange={() => selectChange()}
                            >
                              {comptesBancaires &&
                                comptesBancaires.map((compte) => (
                                  <Select.Option
                                    key={compte.id}
                                    value={compte.id}
                                  >
                                    {compte.designation}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Date d'encaissement"
                            name="date_encaissement"
                            rules={[
                              {
                                required: true,
                                message: "Champ obligatoire !",
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder=" "
                              size="large"
                              className="w-100"
                            ></DatePicker>
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    <Col span={24} className="px-2">
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            message:
                              "Champ obligatoire avec maximum de caractère 255 !",
                            max: 254,
                          },
                        ]}
                      >
                        <TextArea rows={3} type="text" maxLength={255} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Tabs.TabPane>
            </Tabs>
          </Modal>

      </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
    );
  };
  export default DashboardBonLivraisonSimple;
  