import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addTypeOrderCoupe = ( typeOrderCoupe ) => {
  return axios.post(API_URL + "/typeOrderCoupe/" , typeOrderCoupe ,{ headers: authHeader() });
};

const getTypeOrderCoupes = () => {
  return axios.get(API_URL + "/typeOrderCoupe/" , { headers: authHeader() });
};
const getActiveTypeOrderCoupes = () => {
  return axios.get(API_URL + "/typeOrderCoupe/active" , { headers: authHeader() });
};
const updateTypeOrderCoupe = (id , typeOrderCoupe ) => {
  return axios.put(API_URL + "/typeOrderCoupe/" + id , typeOrderCoupe, { headers: authHeader() });
};
const deleteTypeOrderCoupe = (id) => {
  return axios.delete(API_URL + "/typeOrderCoupe/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addTypeOrderCoupe,
  getTypeOrderCoupes,
  updateTypeOrderCoupe,
  deleteTypeOrderCoupe,
  getActiveTypeOrderCoupes
};
export default exportsMethodes;