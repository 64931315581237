import React from 'react'
import { useEffect, useState } from "react";
import { EditOutlined, HighlightOutlined, DownloadOutlined, ToolOutlined,FilePptOutlined, DollarOutlined, DeleteOutlined, SearchOutlined, CopyOutlined } from "@ant-design/icons";
import {
  Button, Card, Col, Divider, Empty, Form, Input, InputNumber, message,
  Modal, Row, Select, Spin, Table, Tabs, Tag, Tooltip, DatePicker, Dropdown, Menu
} from "antd";
import Can from "../../../security/Can";
import financeService from "../../../services/finance.service";
import CompteBancaireService from "../../../services/compteBancaire.service";
import BonLivraison from "../../../services/bonLivraison.service";
import ClientService from "../../../services/client.service";
import FactureAchatService from "../../../services/FactureAchat.service";
import FournisseurService from "../../../services/fournisseur.service";
import BonCommandeService from "../../../services/bonCommande.service";
import Paiment from "../../../services/paiement.service";
import PaimentAchat from "../../../services/paiementAchat.service";
import PdfViewerModal from "../../../PdfViewerModal";
import { Collapse } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import moment from 'moment';
import { environment } from "../../../env/config.env";
import HistoriqueFournisseur from './HistoriqueFournisseur';
const { Panel } = Collapse;
const { TabPane } = Tabs;

const url = environment.serverUrl;

const DashboardFianceFournisseur = () => {
  const [paiementForm] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [paiementsModal, setPaiementsModal] = useState(false);
  const [compteInput, setCompteInput] = useState(false);
  const [comptesBancaires, setComptesBancaires] = useState([]);
  const [factures, setFactures] = useState([]);
  const [fournisseur, setFournisseur] = useState({});
  const [fournisseurs, setFournisseurs] = useState([]);
  const [reference,setReference] = useState();
  const [openFactureModal,setOpenFactureModal] = useState(false);
  const [paiements,setPaiements] = useState(false);
  const [fournisseurTotalAchat,setFournisseurTotalAchat] = useState(0);
  const [fournisseurTotalReste,setFournisseurTotalReste] = useState(0);
  const [maxMontant,setMaxMontant] = useState(0);
  const [searchObj,setSearchObj] = useState({fournisseur:"",date_de:"",date_a:""});
  const [showModal, setShowModal] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [expandedRowKeys,setExpandedRowKeys] = useState([]);

  useEffect(() => {  
    loadComptesBancaires();
    // loadFournisseurVentes(); 
    getFournisseurs();
    initSearch()
  }, []);


  const loadComptesBancaires = async () => {
    setLoading(true);
    const response = await CompteBancaireService.getCompteBancaires();
    if (response.status === 200 || 204) setComptesBancaires(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const getFournisseurs = async () => {
    setLoading(true);
      const response = await FournisseurService.getFournisseurs()
      if (response.status === 200 || 204){
        const newFournisseur = {nom:"tout"}
        response.data.push(newFournisseur)
        setFournisseurs(response.data)
      } 
      else message.error("Une erreur est survenu ! ");
    setLoading(false);
  }

  const loadFournisseurVentes = async () => {
    setLoading(true);
    const response = await financeService.getFinanceFournisseur()
    if (response.status === 200 || 204) initTableData(response.data)
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  }

  // we single traited the col Total Achat with : clientsTotalAchat
  const initTableData = async (data) => {
    let factureList =  await financeService.getAllFactures()
    await clientsTotalAchat(data,factureList.data)
    for (const element of data){
        element.fournisseur = element.nom
        element.total_payé = await getFournisseurPaiment(element.id)
        element.reste = Number(element.total_vente - element.total_payé).toFixed(3)
        setFournisseurTotalAchat((prev) => parseFloat(prev) + parseFloat(element.total_vente))
        setFournisseurTotalReste((prev) => parseFloat(prev) + parseFloat(element.reste))
    }
    setDataTable(data)
  }

  const clientsTotalAchat = async (data,factureList) => {
    for (const element of data){
      let total_vente = 0
      let facturesClients = factureList.filter((it) => it.fournisseurId == element.id )
      facturesClients = facturesClients.map(it=> it.facture_achat)
      for (const facture of facturesClients){
        total_vente += Number(facture.montant_total)
      }
      element.total_vente = Number(total_vente.toFixed(3))
    }
  } 

  const getFournisseurPaiment = async (FournisseurId) => {
    let total_paye = 0
    const response = await financeService.getFournisseurPaiment(FournisseurId)
    setPaiements(response.data)
    const data = response.data
    for (const element of data ){ 
      total_paye += Number(element.montant)
    }
    return total_paye.toFixed(3)
  } 

  const openPaiementModal = (record) => {
    setLoading(true);
    setFournisseur(record)
    loadFactures(record.id);
    setMaxMontant(Number(record.reste)) 
    setPaiementsModal(true);
    setLoading(false);
  }
  
  const loadFactures = async (idF) => {
    setLoading(true);
    await BonCommandeService.getBonCommandes((response) => {
      if (response.status === 200 || 204) {
        FournisseurService.getFournisseurNames((resp) => {
          if (response.status === 200 || 204) {
            initFacturesTable(idF, response.data, resp.data);
          } else message.error("Une erreur est survenu ! ");
        });
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  };

  const initFacturesTable = async (idF, data, fournisseurs) => {
    console.log(data)
    setLoading(true);
    const showMode = localStorage.getItem("SHOW_MODE");
    let list = [];
    for (const element of data) {
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
      element.refBonCommande = element.reference
      const fournisseurId = element?.produit_achats[0]?.fournisseurId ?? 0;
      element.fournisseurId = fournisseurId;
      element.fournisseur = getFournisseurName(fournisseurId, fournisseurs);
      element.status = getFactureStatus(element.paiement_status);
      if(showMode === "NO") {
        if(element.etat !== -1) list.push(element);
      } else list.push(element);
    }
    list = list.filter(it=> it.fournisseurId === idF) 
    const response = await financeService.getFournisseurPaiment(idF)
    const paiments = response.data
    await formatPaiement(list, paiments)
    list = validateDataByDate(list)
    list = list.filter((it)=> it.etat != -1)
    setFactures(list);
    setLoading(false);
  };


  const getFournisseurName = (id, list) => {
    for (const element of list) {
      if (element.id === id) return element.nom;
    }
  };

  const getFactureStatus = (status) => {
    switch (status) {
      case 1:
        return "Payé";
      case 2:
        return "Payé Partiellement";
      default:
        return "Non Payé";
    }
  };

  const formatPaiement = async (bondCommandes, paiments) =>{
    for (const element of paiments ){
      element.bonCommandRef = await bondCommandes.filter(it=> it?.id == element?.bonCommandeId)[0]?.reference 
      element.date = moment(element.date).format('YYYY MM DD')
      element.date_encaissement = element.date_encaissement ? element.date_encaissement : "-"
    }
    paiments = validateDataByDate(paiments)
    setPaiements(paiments)
  }

  const closePaiementModal = () => {
      setPaiementsModal(false);
      paiementForm.resetFields();
  };

  const selectChange = () => {
      const paiement = paiementForm.getFieldsValue();
      const moyen_paiement = paiement.moyen_paiement;
      const moyens = ["Carte Bancaire", "Chèque", "Virement"];
      if (moyens.includes(moyen_paiement)) setCompteInput(true);
      else setCompteInput(false);
  };  

  const downloadFile = () => {
    window.open(url+"Files/Achat/BonCommande/"+reference+".pdf", 'download');
  }

  const downloadBilanFile = () => {
    window.open(url+"Files/Finance/"+reference+".pdf", 'download');
  }

  const showFactureModal = (record) => {
    setOpenFactureModal(true);
    setReference(record.reference)
  } 

  const initSearch = () => {
    const searchObj = searchForm.getFieldsValue()
    const starOfMonth = moment().startOf("month")
    const endOfMonth = moment().endOf("month")
    searchObj.date_de = starOfMonth 
    searchObj.date_a = endOfMonth
    searchObj.fournisseur = "tout"
    setSearchObj(searchObj)
    searchForm.setFieldsValue(searchObj)
    loadFournisseurVentesByDate(searchObj)
  }

  const search = async (values) => {
    setIsSearch(true)
    if (!validSearchDate(values))  return message.error("Une erreur: date debut doit être inférieur à date fin! ");
    const temp = {
      fournisseur:values.fournisseur,
      // date_de:formatDate(values.date_de),
      // date_a:formatDate(values.date_a)
      ...values
    } 
    setSearchObj(temp)
    await loadFournisseurVentesByDate(temp);
  }

  const validSearchDate = (values) => {
    if (moment(values.date_de).isAfter(moment(values.date_a))) return false
    else return true
  }

  const formatDate = (date) => {
    return date.format('YYYY-MM-DD')
  }
  
  const loadFournisseurVentesByDate = async (temp) => {
    setLoading(true);
    const response = await financeService.getFinanceFournisseurByDate(temp)
    if (response.status === 200 || 204) initTableDataByDate(response.data, temp)
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  }

  const initTableDataByDate = async (data, search) => {
    let totalVenteSomme = 0
    let totalResteSomme = 0
    let bonCommandList =  await financeService.getAllBonCommandes()
    await fournisseursTotalAchatByDate(data,bonCommandList.data,search)
    for (const element of data){
        element.fournisseur = element.nom
        element.total_payé = await getFournisseurPaimentByDate(element.id, search)
        element.reste = Number(element.total_vente - element.total_payé).toFixed(3)

        totalVenteSomme += parseFloat(element.total_vente)
        totalResteSomme += parseFloat(element.reste)
    }
    setFournisseurTotalAchat(totalVenteSomme)
    setFournisseurTotalReste(totalResteSomme)
    setDataTable(data)
  }

  const getFournisseurPaimentByDate = async (fournisseurId, search) => {
    let total_paye = 0
    const response = await financeService.getFournisseurPaimentByDate(fournisseurId, search)
    let data = response.data
    data = data.filter((it)=> moment(it.createdAt).isBetween(search.date_de, search.date_a, '[]')  )
    for (const element of data ){
      total_paye += Number(element.montant)
    }
    return total_paye
  } 

  const fournisseursTotalAchatByDate = async (data,bonCommandList,search) => {
    for (const element of data){
      let total_vente = 0
      let bonCommandClients = getBonCommandesByDate(bonCommandList, element, search);
      for (const bonCommande of bonCommandClients){
        total_vente += Number(bonCommande?.montant_total)
      }
      element.total_vente = Number(total_vente.toFixed(3))
    }
  }   
  
  function getBonCommandesByDate(bonCommandList, element, search) {
    let bonCommandClients = bonCommandList.filter((it) => it.fournisseurId == element.id);
    bonCommandClients = bonCommandClients.map((it) => it.bon_commande);
    bonCommandClients = bonCommandClients.filter((it) => moment(it.createdAt).isBetween(search.date_de, search.date_a, '[]'));
    return bonCommandClients;
  }

  const addPaiement = async (values) => {
    setLoading(true);
    const data = values;
    data.etat = 1;
    data.fournisseurId = fournisseur.id
    const response = await PaimentAchat.addPaiementGlobal(data,data.fournisseurId)
    if (response.status === 200) {
      setPaiementsModal(false);
      // loadFournisseurVentes()
      initSearch()
      paiementForm.resetFields();
      message.info("Paiement ajouter avec success");
    } else message.error("Une erreur est survenu ! ");
    setFournisseurTotalAchat(0)
    setFournisseurTotalReste(0)
    setLoading(false); 
  };

  const showPDFModal = (record) => {
    setShowModal(true);
    setReference("BLF-"+record.id)
  } 

  const validateDataByDate = (data) => {
    let validDatasByDate = []
    for (let element of data){
      if ( moment(element.date).isBetween(moment(searchObj.date_de),moment(searchObj.date_a))) validDatasByDate.push(element)
    }
    return validDatasByDate
  }

  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };

  const visDisable = (record) => {
    if(!Can("12_5") || record.total_payé == "0.000" ) return true
  }

  const colums = [
    {
      title: "Fournisseur",
      dataIndex: "fournisseur",
      sorter: (a, b) => a.fournisseur.localeCompare(b.fournisseur),
    },
    {
      title: "Total Achat",
      dataIndex: "total_vente",
      render:  (text,record) =>(
        <Tag color="#2db7f5"> {currencyFormat(text)} </Tag>
      ),
      sorter: (a, b) => a.total_vente - a.total_vente,
    },
    {
      title: "Total payé",
      dataIndex: "total_payé",
      render:  (text,record) =>(
        <Tag color="#008000"> {currencyFormat(text)} </Tag>
      ),
      sorter: (a, b) => a.total_payé.localeCompare(b.total_payé),
    },
    {
      title: "Reste à payer",
      dataIndex: "reste",
      render:  (text,record) =>(
        <Tag color="#f50"> {currencyFormat(text)} </Tag>
      ),
      sorter: (a, b) => a.reste.localeCompare(b.reste),
    },
    {
        title: "Action",
        key: "action",
        width: "13%",
  
        render: (text, record) => (
          <div>
            <Tooltip title="Visualiser">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                disabled={visDisable(record)}
                onClick={() => showPDFModal(record)}
                icon={<FilePptOutlined />}
              />
            </Tooltip>
            <Tooltip title="Liste des règlements">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                disabled={!Can("12_9")}
                onClick={() => openPaiementModal(record)}
                icon={<DollarOutlined />}
              />
            </Tooltip>
          </div>
        ),
      },
  ];

  const facturesCols = [
    {
      title: "Référence Bon de commande",
      dataIndex: "refBonCommande",
      sorter: (a, b) => a.refBonCommande.localeCompare(b.refBonCommande),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Fournisseur",
      dataIndex: "fournisseur",
      sorter: (a, b) => a.fournisseur.localeCompare(b.fournisseur),
    },{
      title: "Montant TTC",
      dataIndex : "montant_total",
      render: (text, record) => (
        <b>{currencyFormat(text)} TND</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },{
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) =>a.devise.localeCompare(b.devise),
    },
    {
      title: "Etat Paiement",
      dataIndex: "Etat_Paiement",
      render: (text, record) => (
        <>
          {record.status === "Payé" && <Tag color="#2db7f5">Payé</Tag>}
          {record.status === "Payé Partiellement" && (
            <Tag color="#87d068">Payé Partiellement</Tag>
          )}
          {record.status === "Non Payé" && <Tag color="#f50">Non Payé</Tag>}
        </>
      ),
      sorter: (a, b) => a.paiement_status - b.paiement_status,
    },
    {
      title: "Action",
      key: "action",
      width: "13%",

      render: (text, record) => (
        <div>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("12_5")}
              onClick={() => showFactureModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const paiementsCols = [
    {
      title: "Référence Bon Commande",
      dataIndex: "bonCommandRef",
      sorter: (a, b) => a.bonCommandRef.localeCompare(b.bonCommandRef),
    },
    {
      title: "Moyen de paiement",
      dataIndex: "moyen_paiement",
      sorter: (a, b) => a.moyen_paiement.localeCompare(b.moyen_paiement),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
      title: "Date d'encaissement",
      dataIndex: "date_encaissement",
      sorter: (a, b) =>
        a.date_encaissement - b.date_encaissement,
    },
    {
      title: "Montant TTC",
      dataIndex: "montant",
      render: (text, record) => <b>{currencyFormat(record.montant)} </b>,
      sorter: (a, b) => a.montant - b.montant,
    },
  ];

  const cancelSearch = async () => {
    setFournisseurTotalAchat(0)
    setFournisseurTotalReste(0)
    // await loadFournisseurVentes()
    initSearch()
    clearSearch()
  }

  const clearSearch = () => {
    const startOfMonth = moment().startOf("month")
    const endOfMonth = moment().endOf("month")
    const emptySearch = {fournisseur:"tout",date_de:startOfMonth,date_a:endOfMonth}
    setSearchObj(emptySearch)
    searchForm.setFieldsValue(emptySearch)
  }
  
  function currencyFormat(num) {
    return  Number(num)?.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const onTableRowExpand = async (expanded, record) => {
    setLoading(true)
    var keys = [];
    if(expanded){
        keys.push(record.id);
    }
    await loadFactures(record.id);
    setExpandedRowKeys(keys)
    setLoading(false)
  }

  const checkPaiement = () => {
    return maxMontant <= 0
  }

  return (
    <Spin spinning={isLoading} size="large">
     <Tabs defaultActiveKey="1">
      <TabPane tab="Finance" key="1">
      <Card
              title={"Gestion Finanicer Fournisseur"}
          >
            <div className="d-flex justify-content-between">
                  <div className="d-flex flex-row align-center">
                    <h5> Total des Achat  : <input style={{fontWeight:"bold"}} className="text-success text-center w-25 border-0 bg-transparent" disabled type="text" value={currencyFormat(fournisseurTotalAchat)} /> </h5> 
                  </div>
                  <div className="d-flex flex-row align-center">
                    <h5> Total des Reste à payer  : <input style={{fontWeight:"bold"}} className="text-danger text-center w-25 border-0 bg-transparent" disabled type="text" value={currencyFormat(fournisseurTotalReste)} /> </h5> 
                  </div>
            </div>
            <br />
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Recherche" key="1">
                <Form
                layout="vertical" 
                form={searchForm} 
                onFinish={search}
                >
                  <Row className='d-flex flex-row justify-content-evenly'>
                    <Col span={12}>
                      <Form.Item 
                      name={"fournisseur"}
                      label="fournisseur"
                        rules={[
                          {
                            required: true,
                            message: 'selectionner un fournisseur!',
                          },
                        ]}>
                            <Select size="large">
                              {fournisseurs.map((cl,index)=> <Select.Option key={index} value={cl.nom}>{cl.nom}</Select.Option>  )}
                            </Select>
                        </Form.Item>
                    </Col> 
                    <Col span={4} style={{marginRight:"30px"}}>
                      <Form.Item 
                        name={"date_de"}
                        label="date debut"
                        valuePropName='date'
                        rules={[
                          {
                            required: true,
                            message: 'selectionner date de!',
                          },
                        ]}>
                              <DatePicker
                                defaultValue={moment().startOf('month')}
                                value={searchObj.date_de ? moment(searchObj.date_de) : ""}
                                placeholder=" "
                                size="large"
                                className="w-100"
                                onChange={(date,dateString)=> setSearchObj((prev)=>({...prev,date_de:dateString}))}
                              ></DatePicker>               
                        </Form.Item>
                    </Col> 
                    <Col span={4}>
                      <Form.Item 
                        name={"date_a"}
                        label="date fin"
                        valuePropName='date'
                        rules={[
                          {
                            required: true,
                            message: 'selectionner date a!',
                          },
                        ]}>
                              <DatePicker
                                defaultValue={moment().endOf('month')}
                                value={searchObj.date_a ? moment(searchObj.date_a) : ""}
                                placeholder=" "
                                size="large"
                                className="w-100"
                                onChange={(date,dateString)=> setSearchObj((prev)=>({...prev,date_a:dateString}))}
                              ></DatePicker>
                      </Form.Item>
                    </Col> 
                  </Row>
                  <Row className='d-flex justify-content-end'>
                    <Col className='px-2'>
                        <Form.Item className='m-0'>
                          <Button type="primary" htmlType="submit">
                            Valider
                          </Button>
                        </Form.Item>
                    </Col>
                    <Col className='px-2'>
                          <Button onClick={cancelSearch} type="danger">
                            Annuler
                          </Button>
                    </Col>
                  </Row>
                </Form>
              </Panel>
          </Collapse>
          <br />
          <Table columns={colums}
              dataSource={dataTable}
              pagination={{ position: ["bottomCenter"], pageSize: 50 }}
              scroll={{ y: 500 }}
              rowKey={(record) => record.id}
              onExpand={onTableRowExpand}
              expandedRowKeys={expandedRowKeys}
              expandable={{
                rowExpandable: record => record.id !== null,
                expandedRowRender: record => <>
                <Spin spinning={isLoading} size="large">
                    <Table
                      rowKey={record.id}
                      locale={locale}
                      columns={facturesCols}
                      dataSource={factures}
                      pagination={{ position: ["none"], pageSize: 50 }}          
                    />
                </Spin>
                </>,
              }}
          />
        </Card>
        </TabPane>
        <TabPane tab="Historique" key="2">
              <HistoriqueFournisseur />
        </TabPane>
        </Tabs>

        <Modal
            visible={paiementsModal}
            getContainer={false}
            centered
            onOk={paiementForm.submit}
            onCancel={() => closePaiementModal()}
            confirmLoading={isLoading}
            title={"Paiements"}
            width={1100}
          >
            <hr />
            <Tabs defaultActiveKey="3">
              <Tabs.TabPane tab="Liste des Bons de Commandes" key="3">
                <Table
                  showSorterTooltip={false}
                  rowKey={(record) => record.id}
                  locale={locale}
                  dataSource={factures}
                  columns={facturesCols}
                  pagination={{ position: ["bottomCenter"], pageSize: 50 }} 
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Liste des paiements" key="1">
                <Table
                  showSorterTooltip={false}
                  rowKey={(record) => record.id}
                  locale={locale}
                  columns={paiementsCols}
                  dataSource={paiements}
                  pagination={{ position: ["bottomCenter"], pageSize: 50 }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                disabled={checkPaiement()}
                tab="Nouveau paiement"
                key="2"
              >
                <Form
                  layout="vertical"
                  form={paiementForm}
                  onFinish={addPaiement}
                >
                  <Row>
                    <Col span={24} className="px-2">
                      <Form.Item label="Type de paiement" name="moyen_paiement">
                        <Select size="large" onChange={() => selectChange()}>
                          <Select.Option key="1" value="Espece">
                            Espece
                          </Select.Option>
                          <Select.Option key="2" value="Carte Bancaire">
                            Carte Bancaire
                          </Select.Option>
                          <Select.Option key="3" value="Chèque">
                            Chèque
                          </Select.Option>
                          <Select.Option key="4" value="Virement">
                            Virement
                          </Select.Option>
                          <Select.Option key="5" value="Effet">
                            Effet
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Montant"
                        name="montant"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          min={0}
                          max={maxMontant}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Date"
                        name="date"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder=" "
                          size="large"
                          className="w-100"
                        ></DatePicker>
                      </Form.Item>
                    </Col>
                    {compteInput && (
                      <>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Compte Bancaire"
                            name="compteBancaireId"
                          >
                            <Select
                              size="large"
                              onChange={() => selectChange()}
                            >
                              {comptesBancaires &&
                                comptesBancaires.map((compte,index) => (
                                  <Select.Option
                                    key={index}
                                    value={compte.id}
                                  >
                                    {compte.designation}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Date d'encaissement"
                            name="date_encaissement"
                            rules={[
                              {
                                required: true,
                                message: "Champ obligatoire !",
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder=" "
                              size="large"
                              className="w-100"
                            ></DatePicker>
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    <Col span={24} className="px-2">
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            message:
                              "Champ obligatoire avec maximum de caractère 255 !",
                            max: 254,
                          },
                        ]}
                      >
                        <TextArea rows={3} type="text" maxLength={255} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Tabs.TabPane>
            </Tabs>
          </Modal>

          <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  Bilan Financier : {reference}
                </div>
                <div className="px-5 ">
                  <Button  onClick={()=> downloadBilanFile()} icon={<DownloadOutlined />} >
                    Telecharger
                  </Button>
                </div>
              </div>
            </>
          }
          centered
          visible={showModal}
          footer={null}
          width={1000}
          onCancel={()=> setShowModal(false)}
          confirmLoading={isLoading}
        >
          { showModal && 
            <div className="heigt-500px">
              <PdfViewerModal 
                diractory="Files/Finance/" 
                reference={reference}  
              ></PdfViewerModal>
            </div>
          }
        </Modal> 

          <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  Bon de Livraison : {reference}
                </div>
                <div className="px-5 ">
                  <Button  onClick={()=> downloadFile()} icon={<DownloadOutlined />} >
                    Telecharger
                  </Button>
                </div>
              </div>
            </>
          }
          centered
          visible={openFactureModal}
          footer={null}
          width={1000}
          onCancel={()=> setOpenFactureModal(false)}
          confirmLoading={isLoading}
        >
          { openFactureModal && 
            <div className="heigt-500px">
              <PdfViewerModal 
                diractory="Files/Achat/BonCommande/" 
                reference={reference}  
              ></PdfViewerModal>
            </div>
          }
        </Modal>  

    </Spin>
  )
}

export default DashboardFianceFournisseur
