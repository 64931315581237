import { Col, Row } from "antd";
import React, { useEffect } from "react";


function FicheFooter({ entreprise }) {
  
  useEffect(() => {}, []);

  return (
    <Row>
      <Col span={16} offset={4} className="fiche-divider-top mt-5 pt-3">
        <div direction="vertical" className="px-2 text-center">  
          <span><strong> Adresse :</strong></span><span className="description-text"> {entreprise[0]?.adresse} </span>
          <span><strong> - Téléphone  :</strong> </span><span className="description-text"> {entreprise[0]?.telephone}</span> 
          <span><strong> MF : </strong></span><span className="description-text"> {entreprise[0]?.matricule_fiscal}</span>
          <span><strong> - RC :  </strong></span><span className="description-text"> {entreprise[0]?.registre_commerce} </span>
        </div>
      </Col>
    </Row>
    
  );
}
export default FicheFooter;
