import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addBonCommande = async ( bon_commande ) => {
  return await axios.post(API_URL + "/bon_commande/" , bon_commande ,{ headers: authHeader() });
};

const getBonCommandes = async ( callback ) => {
  callback( await axios.get(API_URL + "/bon_commande/" , { headers: authHeader() }))
};
const getBonCommandesAsync = async (  ) => {
  return await axios.get(API_URL + "/bon_commande/" , { headers: authHeader() })
};
const duplicate = async ( id) => {
  return axios.get(API_URL + "/bon_commande/duplicate/" + id , {headers: authHeader()})
}
const getBonCommande = async ( id, callback ) => {
  callback( await axios.get(API_URL + "/bon_commande/" + id , { headers: authHeader() }))
};
const getActiveBonCommandeForFacturation = async (callback) => {
  callback( await axios.get(API_URL + "/bon_commande/f/names" , { headers: authHeader() }))
}
const getActiveBonCommandeForReception = async (callback) => {
  callback( await axios.get(API_URL + "/bon_commande/r/names" , { headers: authHeader() }))
}
const getReference = ( id ) => {
  return axios.get(API_URL + "/bon_commande/ref/" + id , { headers: authHeader() });
};
const updateBonCommande = (id , bon_commande ) => {
  return axios.put(API_URL + "/bon_commande/" + id , bon_commande, { headers: authHeader() });
};

const deleteBonCommande = (id) => {
  return axios.delete(API_URL + "/bon_commande/" + id , { headers: authHeader() });
};
const changeStateBonCommande = (id,etat) => {
  return axios.post(API_URL + "/bon_commande/change/" + id  ,{etat}, { headers: authHeader() });
};

const getLast = async (callback) => {
  callback( await axios.get(API_URL + "/bon_commande/last", { headers: authHeader() }))
}
const exportsMethodes = {
  addBonCommande,
  getBonCommandes,
  getBonCommande,
  updateBonCommande,
  deleteBonCommande,
  changeStateBonCommande,
  getActiveBonCommandeForFacturation,
  getActiveBonCommandeForReception,
  getLast,
  duplicate,
  getReference,
  getBonCommandesAsync
};
export default exportsMethodes;