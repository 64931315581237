import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addAssemblage = ( assemblage ) => {
  return axios.post(API_URL + "/assemblage/" , assemblage ,{ headers: authHeader() });
};

const getAssemblages = () => {
  return axios.get(API_URL + "/assemblage/" , { headers: authHeader() });
};
const getActiveAssemblages = () => {
  return axios.get(API_URL + "/assemblage/active" , { headers: authHeader() });
};
const updateAssemblage = (id , assemblage ) => {
  return axios.put(API_URL + "/assemblage/" + id , assemblage, { headers: authHeader() });
};
const deleteAssemblage = (id) => {
  return axios.delete(API_URL + "/assemblage/" + id , { headers: authHeader() });
};

const loadStockQte = ( data )=> {
  return axios.post(API_URL + "/assemblage/qteStock/", data, { headers: authHeader() } )
}

const loadStock = () => {
  return axios.get(API_URL + "/assemblage/stock" , { headers: authHeader() });
}

const exportsMethodes = {
  addAssemblage,
  getAssemblages,
  updateAssemblage,
  deleteAssemblage,
  getActiveAssemblages,
  loadStockQte,
  loadStock
};
export default exportsMethodes;