import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addTimbre = ( tva ) => {
  return axios.post(API_URL + "/timbre/" , tva ,{ headers: authHeader() });
};

const getTimbres = () => {
  return axios.get(API_URL + "/timbre/" , { headers: authHeader() });
};
const getActiveTimbres = () => {
  return axios.get(API_URL + "/timbre/active" , { headers: authHeader() });
};

const updateTimbre = (id , tva ) => {
  return axios.put(API_URL + "/timbre/" + id , tva, { headers: authHeader() });
};

const deleteTimbre = (id) => {
  return axios.delete(API_URL + "/timbre/" + id , { headers: authHeader() });
};

const exportsMethodes = {
    addTimbre,
  getTimbres,
  getActiveTimbres,
  updateTimbre,
  deleteTimbre
};
export default exportsMethodes;