import {
  Card, message, Spin
} from "antd";
import React, { useEffect, useState } from "react";
import OrdreCoupeService from "../../../../services/ordreCoupe.service";


import TableFilters from "../../../uiHelpers/TableFilters";
  
  const StockCoupe = () => {
    
    const [isLoading, setLoading] = useState(false);
    const [dataTable, setDataTable] = useState([]);

   
  
    useEffect(() => {
      loadStock();
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
    };
    const loadStock = async () => {
      setLoading(true);
      const response = await OrdreCoupeService.loadStock();
      console.log(response);
      if (response.status === 200 || 204) initTableData(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };


    const initTableData = (data) => {
        console.log(data);
        for (const element of data) {
            element.typeCoupe = element?.prod_type_coupe?.nom;
            element.typeTissu = element?.prod_type_tissu?.nom;
        }
        console.log(data);
        sortByUpdatedAtd(data)
        setDataTable(data)
    };
  
    const sortByUpdatedAtd = (data) => {
      return data.sort((a,b)=>{
           return new Date(a.updatedAt).getTime() - 
           new Date(b.updatedAt).getTime()
       }).reverse();
    }  

    const colums = [
      {
        title: "Type Coupe",
        dataIndex: "typeCoupe",
        sorter: (a, b) => a.typeCoupe.localeCompare(b.typeCoupe),
      },
      {
        title: "Type Tissus",
        dataIndex: "typeTissu",
        sorter: (a, b) => a.typeTissu.localeCompare(b.typeTissu),
      },
      {
        title: "Faces",
        dataIndex: "face",
        sorter: (a, b) => a.face - b.face,
      },
      {
        title: "Dos",
        dataIndex: "dos",
        sorter: (a, b) => a.dos - b.dos,
      },
      {
        title: "Manches",
        dataIndex: "manches",
        sorter: (a, b) => a.manches - b.manches,
      },
    ];
  
 
    return (
        <>
            <Spin spinning={isLoading} size="large">
                <Card title={"Stock Ordre de Coupe"} >
                    <TableFilters data={dataTable} columns={colums} />
                </Card>
            </Spin>
        </>
    );
  };
  export default StockCoupe;
  