import {
  DollarOutlined,
  DownloadOutlined, EditOutlined, FilePptOutlined , QuestionCircleOutlined ,CopyOutlined, DeleteOutlined
} from "@ant-design/icons";
import { Button, Card, Dropdown, Menu, message, Modal, Spin, Tooltip, Col, Row, Tabs, Table, Form ,
  Select, DatePicker, Empty, Input, InputNumber, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import Can from "../../../security/Can";
import BonCommandeService from "../../../services/bonCommande.service";
import FournisseurService from "../../../services/fournisseur.service";
import PaiementAchatService from "../../../services/paiementAchat.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";

const url = environment.serverUrl;

const DashboardBonCommande = () => {
  const [paiementForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [reference,setReference] = useState();
  const [paiementsModal, setPaiementsModal] = useState(false);
  const [comptesBancaires, setComptesBancaires] = useState([]);
  const [paiementsList, setPaiementsList] = useState([]);
  const [compteInput, setCompteInput] = useState(false);
  const [bonCommandInfo, setBonCommandInfo] = useState({});
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
      loadBonCommande();
      loadFournisseurs();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadBonCommande = async () => {
    setLoading(true);
    await BonCommandeService.getBonCommandes((response)=> {
      if (response.status === 200 || 204) {
        FournisseurService.getFournisseurNames( (resp) => {
          if (response.status === 200 || 204) {
            initTableData(response.data , resp.data)
          }else message.error("Une erreur est survenu ! ");
        });
      }else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  };

  const loadFournisseurs = async (data) => {
    setLoading(true);

  };

  const initTableData = async (data ,fournisseurs) => {
    const showMode = localStorage.getItem("SHOW_MODE");
    const list = [];
    for (const element of data) {
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
      element.dateLivraison = moment(new Date(element?.date_livraison)).format("YYYY-MM-DD");
      const fournisseurId = element.produit_achats[0]?.fournisseurId ?? 0;
      element.fournisseurId = element.produit_achats[0]?.fournisseurId ?? 0;
      element.fournisseur = getFournisseurName(fournisseurId,fournisseurs);
      element.qteDefecite = await isQteDefecite(element)
      element.montant_total = currencyFormat(element.montant_total)
      if(showMode === "NO") {
        if(element.etat !== -1) list.push(element);
      } else list.push(element)
    }
    setDataTable(list);
  };

  const getFournisseurName = (id ,list) => {
    for (const element of list) {
      if(element.id === id ) return element.raison_sociale
    }
  }

  const formatteEtat = (etat , Fstatus , Lstatus, qteDefecite) => {
    if (etat == 0) return "En cours"
    else if (qteDefecite) return "Annulé *"
    switch( etat ){
      case 0 : return "En cours";
      case 1 : {
        if( Fstatus ) return "Facturé";
        if( Lstatus ) return "Livré"
        else return "Activé";
      }
      case -1 : return "Annulé";
      default : return ""
    }
  }

  const isQteDefecite = (element) => {
    for (let prod of element.produit_achats){
      const qteDeficite = prod.produit?.stocks[0].qteDeficite
      if ( qteDeficite < 0 ) return true
    }
    return false
  } 

  const changeStateBonCommande = async (id ,etat) => {
    const response = await BonCommandeService.changeStateBonCommande(id,etat);
    if (response.status === 200 || 204) {
      const msg = etat === 1 ? "Activé" : "Désactivé"  
       message.info("Bon de commande "+ msg);
    } else message.error("Une erreur est survenu ! ");
    loadBonCommande();
  }

  const navigate = (location) => {
    history.push(location);
  };

  const navigateToUpdate = (record) => {
    const id = record.id;
    history.push("/bonCommande", {id});
  }

  const showPDFModal = (record) => {
    setShowModal(true);
    setReference(record.reference)
  } 
  const downloadFile = () => {
    window.open(url+"Files/Achat/BonCommande/"+reference+".pdf", 'download');
  }

  const confirmDuplication = (record) => {
    Modal.confirm({
      icon: <QuestionCircleOutlined  />,
      content: "Êtes-vous sûr de dupliquer ce bon de commande ?",
      okText: "Oui",
      onOk: () => duplicateData(record),
      cancelText: "Non",
    });
  };
  const duplicateData = async (record) => {
    const response = await BonCommandeService.duplicate(record.id);
    if(response.data){
      const id  = response.data.id;
      message.success("Duplication avec success");
      history.push("/bonCommande", {id});
    }else message.error("Une erreur est survenu ! ");
  }

  const ConfirmeDeleteBonCommand = (record) => {
    Modal.confirm({
      icon: <QuestionCircleOutlined  />,
      content: "Êtes-vous sûr de Suppprimer ce bon de commande ?",
      okText: "Oui",
      onOk: () => deleteBonCommand(record),
      cancelText: "Non",
    });
  }

  const deleteBonCommand = async (record) => {
    const response = await BonCommandeService.deleteBonCommande(record.id);
    if(response.data){
      message.success("Suppression avec success");
      loadBonCommande();
    }  
    else message.error("Une erreur est survenu ! ");
  }

  const openPaiementModal = async (record) => {
    setLoading(true);
    await loadBonCommandPaiements(record.id)
    const somme = Number(record.montant_total) - Number(record.reste_a_paye)
    console.log(somme)
    setBonCommandInfo({
      id: record.id,
      reference: record.reference,
      montant: record.montant_total,
      rest: record.reste_a_paye,
      somme: somme,
      etat: record.etat,
      devise : record.devise
    });
    setPaiementsModal(true);
    setLoading(false);
  };

  const loadBonCommandPaiements = async (id) => {
    const response = await PaiementAchatService.getActivePaiements(id);
    if (response.status === 200 || 204) {
      const data = response.data;
      var somme_paiement = 0;
      for (const element of data) {
        const nbr = element.id + "";
        element.moyenPaiement = element.moyen_paiement
          ? element.moyen_paiement
          : "Non mentionné";
        const prefix = element.moyenPaiement === "Non mentionné" ? "AC-" : "P-";
        element.reference = prefix + nbr.padStart(4, "0");
        element.showDate = moment(element.date).format("YYYY/MM/DD");
        if (element.date_encaissement) {
          element.showEncaissementDate = moment(
            element.date_encaissement
          ).format("YYYY/MM/DD");
        } else {
          element.showEncaissementDate = element.showDate;
        }
        somme_paiement += element.montant;
      }
      setPaiementsList(data);
      return somme_paiement;
    } else message.error("Une erreur est survenu ! ");
  };

  const closePaiementModal = () => {
    setPaiementsModal(false);
    paiementForm.resetFields();
  };

  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };

  const checkPaiement = () => {
    return bonCommandInfo.etat !== 1 || Number(bonCommandInfo.rest) === 0;
  };

  const selectChange = () => {
    const paiement = paiementForm.getFieldsValue();
    const moyen_paiement = paiement.moyen_paiement;
    const moyens = ["Carte Bancaire", "Chèque", "Virement"];
    if (moyens.includes(moyen_paiement)) setCompteInput(true);
    else setCompteInput(false);
  };

  const addPaiement = async (values) => {
    setLoading(true);
    const fullyPaied = Number(values.montant) === Number(bonCommandInfo.rest) ? 1 : 2;
    const data = values;
    data.bonCommandeId	 = bonCommandInfo.id;
    data.etat = 1;
    data.fullyPaied = fullyPaied;
    const response = await PaiementAchatService.addPaiement(data);

    if (response.status === 200) {
      setPaiementsModal(false);
      loadBonCommande();
      paiementForm.resetFields();
      message.info("Paiement ajouter avec success");
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const BonCommandes = [
    {
      title: "Référence",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Date Livraison",
      dataIndex: "dateLivraison",
      sorter: (a, b) => a?.dateLivraison?.localeCompare(b?.dateLivraison),
    },
    {
      title: "Fournisseur",
      dataIndex: "fournisseur",
      sorter: (a, b) => a?.fournisseur?.localeCompare(b?.fournisseur),
    },
    
    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => <b>{currencyFormat(record.montant_total)} {record.devise}</b>,
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) =>a.devise.localeCompare(b.devise),
    },
    {
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => <Dropdown.Button overlay={
        <Menu disabled={!Can("10_4") || record.p_status || record.r_status} >
          <Menu.Item disabled={record.etat === 1} className="px-5" key="1" onClick={()=> changeStateBonCommande(record.id ,1)}>Validé</Menu.Item>
          <Menu.Item disabled={record.etat === -1}  className="px-5" key="2" onClick={()=> changeStateBonCommande(record.id ,-1)}>Annulé</Menu.Item>
        </Menu>
      }>{formatteEtat(record.etat , record.p_status , record.r_status, record.qteDefecite)}</Dropdown.Button>
      ,
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Etat Paiement",
      dataIndex: "Etat_Paiement",
      render: (text, record) => (
        <>
          {record.paiement_status === 1 && <Tag color="#2db7f5">Payé</Tag>}
          {record.paiement_status === 2 && (
            <Tag color="#87d068">Payé Partiellement</Tag>
          )}
          {record.paiement_status === 0 && <Tag color="#f50">Non Payé</Tag>}
        </>
      ),
      sorter: (a, b) => a.paiement_status - b.paiement_status,
    },
    {
      title: "Action",
      key: "action",
      width:"15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Dupliquer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("10_5")}
              onClick={() => confirmDuplication(record)}
              icon={<CopyOutlined />}
            />
          </Tooltip>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("10_5")}
              onClick={() => showPDFModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={record.etat === 1 || !Can("10_3")}
              onClick={() => navigateToUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Ajouter un règlement">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("12_9")   }
              onClick={() => openPaiementModal(record)}
              icon={<DollarOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("12_9") || ![0,-1].includes(record.etat)  }
              onClick={() => ConfirmeDeleteBonCommand(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const paiementsCols = [
    {
      title: "Référence",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Moyen de paiement",
      dataIndex: "moyenPaiement",
      sorter: (a, b) => a.moyenPaiement.localeCompare(b.moyenPaiement),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Date d'encaissement",
      dataIndex: "showEncaissementDate",
      sorter: (a, b) =>
        a.showEncaissementDate.localeCompare(b.showEncaissementDate),
    },
    {
      title: "Montant TTC",
      dataIndex: "montant",
      render: (text, record) => <b>{currencyFormat(record.montant)} </b>,
      sorter: (a, b) => a.montant - b.montant,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <div>
    //       <Tooltip title="Supprimer">
    //         <Button
    //           className="mx-1"
    //           type="dashed"
    //           shape="circle"
    //           onClick={() => deletePaiement(record)}
    //           icon={<DeleteOutlined />}
    //         />
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ];


  function currencyFormat(num) {
    return  Number(num)?.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return (
    <>
    {Can("10_1") ? (
      <Spin spinning={isLoading} size="large">
        <Card
          title={"Bon de commande"}
          extra={
            <Button
              className="mx-2"
              shape="round"
              disabled={!Can("10_2")}
              type="primary"
              onClick={() => navigate("/bonCommande")}
            >
              Ajouter Un Bon De Commande
            </Button>
          }
        >
          <TableWithFiltres data={dataTable} columns={BonCommandes} />
        </Card>
        <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  Bon de commande : {reference}
                </div>
                <div className="px-5 ">
                  <Button  onClick={()=> downloadFile()} icon={<DownloadOutlined />} >
                    Telecharger
                  </Button>
                </div>
              </div>
            </>
          }
          centered
          visible={showModal}
          footer={null}
          width={1000}
          onCancel={()=> setShowModal(false)}
          confirmLoading={isLoading}
      >
        { showModal && 
          <div className="heigt-500px">
            <PdfViewerModal 
              diractory="Files/Achat/BonCommande/" 
              reference={reference}  
            ></PdfViewerModal>
          </div>
        }
        

      </Modal>
      <Modal
            visible={paiementsModal}
            getContainer={false}
            centered
            onOk={paiementForm.submit}
            onCancel={() => closePaiementModal()}
            confirmLoading={isLoading}
            title={"Paiements"}
            width={900}
          >
            <Row>
              <Col span={6}>
                <b>Référence : {bonCommandInfo.reference} </b>
              </Col>
              <Col span={6}>
                <b>Total TTC : {bonCommandInfo.montant}</b>
              </Col>
              <Col span={6}>
                <b>Total Payé : {bonCommandInfo.somme}</b>
              </Col>
              <Col span={6}>
                <b>Reste à payé :<span className="text-success"> {bonCommandInfo.rest} </span></b>
              </Col>
            </Row>
            <hr />
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Liste des paiements" key="1">
                <Table
                  expandable={{
                    expandedRowRender: (record) => (
                      <p className="px-1">
                        <b> Description : </b>
                        {record.description}
                      </p>
                    ),
                    rowExpandable: (record) => record.description !== null,
                  }}
                  showSorterTooltip={false}
                  rowKey={(record) => record.id}
                  locale={locale}
                  columns={paiementsCols}
                  dataSource={paiementsList}
                  pagination={{ position: ["bottomCenter"], pageSize: 50 }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                disabled={checkPaiement()}
                tab="Nouveau paiement"
                key="2"
              >
                <Form
                  layout="vertical"
                  form={paiementForm}
                  onFinish={addPaiement}
                  disabled={isLoading}
                >
                  <Row>
                    <Col span={24} className="px-2">
                      <Form.Item label="Type de paiement" name="moyen_paiement">
                        <Select size="large" onChange={() => selectChange()}>
                          <Select.Option key="1" value="Espece">
                            Espece
                          </Select.Option>
                          <Select.Option key="2" value="Carte Bancaire">
                            Carte Bancaire
                          </Select.Option>
                          <Select.Option key="3" value="Chèque">
                            Chèque
                          </Select.Option>
                          <Select.Option key="4" value="Virement">
                            Virement
                          </Select.Option>
                          <Select.Option key="5" value="Effet">
                            Effet
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Montant"
                        name="montant"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          min={0}
                          max={bonCommandInfo.rest}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Date"
                        name="date"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder=" "
                          size="large"
                          className="w-100"
                        ></DatePicker>
                      </Form.Item>
                    </Col>
                    {compteInput && (
                      <>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Compte Bancaire"
                            name="compteBancaireId"
                          >
                            <Select
                              size="large"
                              onChange={() => selectChange()}
                            >
                              {comptesBancaires &&
                                comptesBancaires.map((compte) => (
                                  <Select.Option
                                    key={compte.id}
                                    value={compte.id}
                                  >
                                    {compte.designation}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Date d'encaissement"
                            name="date_encaissement"
                            rules={[
                              {
                                required: true,
                                message: "Champ obligatoire !",
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder=" "
                              size="large"
                              className="w-100"
                            ></DatePicker>
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    <Col span={24} className="px-2">
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            message:
                              "Champ obligatoire avec maximum de caractère 255 !",
                            max: 254,
                          },
                        ]}
                      >
                        <TextArea rows={3} type="text" maxLength={255} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Tabs.TabPane>
            </Tabs>
        </Modal>
      </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default DashboardBonCommande;
