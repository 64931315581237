import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card,Col, Form, Input,message, Modal,Row, Spin, Switch, Tag, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import Can from "../../../../security/Can";
import referenceBroderieService from "../../../../services/referenceBroderie.service";
import TableWithFiltres from "../../../uiHelpers/TableFilters";
  
  const ReferenceBroderie = () => {
    const [referenceBroderieForm] = Form.useForm();
    const [referenceBroderieModal, setReferenceBroderieModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [switchValue, setSwitchValue] = useState("unchecked");
  
  
    useEffect(() => {
      loadReferenceBroderies();
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
      setEditMode(false);
      setUpdateId(null);
    };
    const loadReferenceBroderies = async () => {
      setLoading(true);
      const response = await referenceBroderieService.getReferenceBroderies();
      if (response.status === 200 || 204) initTableData(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
    const initTableData = (data) => {
      setDataTable(data);
    };
  
    const showReferenceBroderieModal = () => {
      setReferenceBroderieModal(true);
    };
  
    const handleOk = () => {
      setReferenceBroderieModal(false);
    };
  
    const updateMode = (record) => {
      setReferenceBroderieModal(true);
      const checkedSwitch = record.etat ? "checked" : "unchecked";
      setSwitchValue(checkedSwitch);
      referenceBroderieForm.setFieldsValue(record);
      setUpdateId(record.id);
      setEditMode(true);
    };
  
    const handleCancel = () => {
      setReferenceBroderieModal(false);
      clearData();
    };
  
    const addReferenceBroderie = async (values) => {
      setLoading(true);
      var data = values
      data.etat = data.etat ? data.etat : false; 
      const response = editMode
        ? await referenceBroderieService.updateReferenceBroderie(updateId, data)
        : await referenceBroderieService.addReferenceBroderie(data);
      if (response.status === 200 || 204) {
        if (editMode) message.info("Mise à jour avec success");
        else message.info("Référence de broderie ajouter avec success");
      } else message.error("Une erreur est survenu ! ");
  
      closingModal();
    };
  
    const closingModal = () => {
      loadReferenceBroderies();
      setReferenceBroderieModal(false);
      setLoading(false);
      clearData();
    };
  
    const clearData = () => {
      referenceBroderieForm.resetFields();
      setEditMode(false);
      setUpdateId(null);
    };
  
    const ReferenceBroderieColumns = [
      {
        title: "Nom",
        dataIndex: "nom",
        sorter: (a, b) => a.nom.localeCompare(b.nom),
        width: "17%",
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "55%",
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        title: "Etat",
        dataIndex: "etat",
        sorter: (a, b) => a.valeur - b.valeur,
        render: (text, record) => (
          <Tag color={record.etat ? "cyan" : "red" }>{record.etat ? "Activé" : "Désactivé"}</Tag>
        ),
        width: "10%",
      },
      {
        title: "Action",
        width: "10%",
        key: "action",
        render: (text, record) => (
          <div>
            <Tooltip title="Mettre à jour">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                disabled={!Can("3_3")}
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
  
    const confirmDelete = (record) => {
      Modal.confirm({
        title: "Attention",
        icon: <ExclamationCircleOutlined />,
        content: "Êtes-vous sûr de vouloir supprimer cette référence de broderie ?",
        okText: "Oui",
        onOk: () => deleteItem(record),
        cancelText: "Non",
      });
    };
    const deleteItem = async (item) => {
      const response = await referenceBroderieService.deleteReferenceBroderie(item.id);
      if (response.status === 204 || 200) {
        message.info("Suppression avec success");
        loadReferenceBroderies();
      } else message.error("Une erreur est survenu ! ");
    };
  
    return (
      <Spin spinning={isLoading} size="large">
        <Card
          title={"Références Broderies"}
          extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("3_2")}
                onClick={() => showReferenceBroderieModal()}
              >
                Ajouter une référence de broderie
              </Button>
          }
        >
          <TableWithFiltres data={dataTable} columns={ReferenceBroderieColumns}></TableWithFiltres>
          <Form layout="vertical" form={referenceBroderieForm} onFinish={addReferenceBroderie}>
            <Modal
              visible={referenceBroderieModal}
              onOk={referenceBroderieForm.submit}
              onCancel={handleCancel}
              getContainer={false}
              confirmLoading={isLoading}
              okText="Enregistrer"
              cancelText="Annuler"
              centered
              width={600}
              title={
                <>
                  <div className="d-flex justify-content-between ">
                    <div className="my-0">
                      {editMode
                        ? "Modifier la référence de broderie"
                        : "Ajouter une référence de broderie"}
                    </div>
                    <div>
                      <Form.Item
                        className="my-0 mx-5"
                        name="etat"
                        valuePropName={switchValue}
                      >
                        <Switch
                          checkedChildren="Activer"
                          unCheckedChildren="Désactiver"
                          className="px-4"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </>
              }
            >
              <Row>
                <Col span={24} className="px-2">
                  <Form.Item
                    label="Nom"
                    name="nom"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
  
                <Col span={24} className="px-2">
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        message:
                          "Champ obligatoire avec maximum de caractère 255 !",
                        max: 254,
                      },
                    ]}
                  >
                    <TextArea rows={4} type="text" maxLength={255} />
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </Form>
        </Card>
      </Spin>
    );
  };
  export default ReferenceBroderie;
  