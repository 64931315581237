import {
  EditOutlined,
  EyeOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import unite from "../../../../helpers/unite";
import Can from "../../../../security/Can";
import OrdreCoupeService from "../../../../services/ordreCoupe.service";
import typeCoupeService from "../../../../services/typeCoupe.service";
import typeOrdreCoupeService from "../../../../services/typeOrdreCoupe.service";
import typeTissusService from "../../../../services/typeTissus.service";

import bonCommandeService from "../../../../services/bonCommande.service";
import produitService from "../../../../services/produit.service";

import AppTable from "../../../uiHelpers/AppTabe";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

const OrdreCoupe = () => {
  const [ordreCoupeForm] = Form.useForm();
  const [ordreCoupeModal, setOrdreCoupeModal] = useState(false);
  const [validateCoupeModal, setValidateCoupeModal] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();

  const [isDisabled, setIsDisabled] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const [typeOrdreCoupe, setTypeOrdreCoupe] = useState([]);
  const [typeCoupe, setTypeCoupe] = useState([]);
  const [typeTissu, setTypeTissu] = useState([]);
  const [produits, setProduits] = useState([]);
  const [bonCommandes, setBonCommandes] = useState([]);

  const [etat, setEtat] = useState(0);

  const [isStateChange, setStateChange] = useState(0);

  useEffect(() => {
    loadOrdreCoupes();
    loadTypeOrdreCoupes();
    loadTypeCoupes();
    loadTypeTissus();
    loadProduits();
    loadBonCommandes();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadOrdreCoupes = async () => {
    setLoading(true);
    const response = await OrdreCoupeService.getOrdreCoupes();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadTypeOrdreCoupes = async () => {
    setLoading(true);
    const response = await typeOrdreCoupeService.getActiveTypeOrderCoupes();
    if (response.status === 200 || 204) setTypeOrdreCoupe(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const loadTypeCoupes = async () => {
    setLoading(true);
    const response = await typeCoupeService.getActiveTypeCoupes();
    if (response.status === 200 || 204) setTypeCoupe(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const loadTypeTissus = async () => {
    setLoading(true);
    const response = await typeTissusService.getActiveTypeTissus();
    if (response.status === 200 || 204) setTypeTissu(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadProduits = async () => {
    setLoading(true);
    const response = await produitService.getMatierePremiere();
    if (response.status === 200 || 204) setProduits(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const loadBonCommandes = async () => {
    setLoading(true);
    const response = await bonCommandeService.getBonCommandesAsync();
    if (response.status === 200 || 204) setBonCommandes(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const initTableData = (data) => {
    for (const element of data) {
      element.typeCoupe = element?.prod_type_coupe?.nom;
      element.typeTissu = element?.prod_type_tissu?.nom;
      element.typeOrdreCoupe = element?.prod_type_ordre_coupe?.nom;
      element.createdAt = moment(element.createdAt).format("YYYY-MM-DD");
    }
    var temp = sortByCreatedAt(data);
    setDataTable(temp);
  };

  const sortByCreatedAt = (data) => {
    return data
      .sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      })
      .reverse();
  };

  const showOrdreCoupeModal = () => {
    setOrdreCoupeModal(true);
  };

  const handleOk = () => {
    setOrdreCoupeModal(false);
  };

  const updateMode = (record) => {
    setOrdreCoupeModal(true);
    console.log(record);
    ordreCoupeForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const handleCancel = () => {
    setOrdreCoupeModal(false);
    clearData();
    setIsDisabled(false);
  };
  const handleValidationCancel = () => {
    setValidateCoupeModal(false);
    setIsDisabled(false);
    clearData();
  };

  const addOrdreCoupe = async (values) => {
    setLoading(true);
    values.etat = values.etat ? values.etat : 0;
    const response = editMode
      ? await OrdreCoupeService.updateOrdreCoupe(updateId, values)
      : await OrdreCoupeService.addOrdreCoupe(values);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Ordre de Coupe ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    if (isStateChange)
      await OrdreCoupeService.updateOdreCoupeStatus(updateId, { etat: etat });

    closingModal();
    setLoading(false);
  };

  const closingModal = async () => {
    await loadOrdreCoupes();
    setOrdreCoupeModal(false);
    setValidateCoupeModal(false);
    clearData();
    setEtat(0);
    setStateChange(false);
  };

  const clearData = () => {
    ordreCoupeForm.resetFields();
    ordreCoupeForm.setFieldsValue({ type: false });
    setEditMode(false);
    setUpdateId(null);
  };

  const changeOrdreCoupeStatus = async (record, etat) => {
    try {
      setLoading(true);

      if (etat === 1) {
        setValidateCoupeModal(true);
        ordreCoupeForm.setFieldsValue(record);
        setUpdateId(record.id);
        setEtat(1);
        setEditMode(true);
        setStateChange(true);
      } else {
        await OrdreCoupeService.updateOdreCoupeStatus(record.id, {
          etat: etat,
        });
        record.etat = etat;
      }

      await loadOrdreCoupes();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const qteChanged = () => {
    const nbr = ordreCoupeForm.getFieldValue("nombreReel");
    ordreCoupeForm.setFieldsValue({
      nombreFace: nbr,
      nombreDos: nbr,
      nombreBras: nbr * 2,
    });
  };

  const showMode = (record) => {
    setIsDisabled(true);
    setUpdateId(record.id);
    setEditMode(true);
    ordreCoupeForm.setFieldsValue(record);
    setValidateCoupeModal(true);
  };

  const formatteEtat = (etat) => {
    switch (etat) {
      case 0:
        return "En cours";
      case 1:
        return "Validé";
      case -1:
        return "Annulé";
      default:
        return "";
    }
  };

  const colums = [
    {
      title: "Numéro",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Type Ordre de coupe",
      dataIndex: "typeOrdreCoupe",
      sorter: (a, b) => a.typeOrdreCoupe.localeCompare(b.typeOrdreCoupe),
    },
    {
      title: "Type Coupe",
      dataIndex: "typeCoupe",
      sorter: (a, b) => a.typeCoupe.localeCompare(b.typeCoupe),
    },
    {
      title: "Type Tissus",
      dataIndex: "typeTissu",
      sorter: (a, b) => a.typeTissu.localeCompare(b.typeTissu),
    },
    {
      title: "Quantité objectif",
      dataIndex: "nombreObjectif",
      sorter: (a, b) => a.nombreObjectif.localeCompare(b.nombreObjectif),
    },
    {
      title: "Date de Creation",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => (
        <Dropdown.Button
          overlay={
            <Menu>
              <Menu.Item
                disabled={record.etat === 1}
                className="px-5"
                key="1"
                onClick={() => changeOrdreCoupeStatus(record, 1)}
              >
                Validé
              </Menu.Item>
              <Menu.Item
                disabled={record.etat === -1}
                className="px-5"
                key="4"
                onClick={() => changeOrdreCoupeStatus(record, -1)}
              >
                Annulé
              </Menu.Item>
            </Menu>
          }
        >
          {formatteEtat(record.etat, record.bn_status)}
        </Dropdown.Button>
      ),
      sorter: (a, b) => a.etat - b.etat,
    },
    {
      title: "Actions",
      key: "action",
      width: "15%",
      render: (text, record) => (
        <div>
          <Tooltip title="afficher">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={record.etat !== 1}
              onClick={() => showMode(record)}
              icon={<EyeOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={record.etat === 1}
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={isLoading} size="large">
        <Card
          title={"Ordre de Coupe"}
          extra={
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              disabled={!Can("9_2")}
              onClick={() => showOrdreCoupeModal()}
            >
              Ajouter un ordre de Coupe
            </Button>
          }
        >
          <AppTable data={dataTable} columns={colums} />

          <Form
            layout="vertical"
            form={ordreCoupeForm}
            onFinish={addOrdreCoupe}
            initialValues={{
              prod_order_coupes_compositions: [undefined],
              prodTypeOrdreCoupeId: 1,
            }}
          >
            <Modal
              visible={ordreCoupeModal}
              onOk={ordreCoupeForm.submit}
              onCancel={handleCancel}
              getContainer={false}
              confirmLoading={isLoading}
              okText="Enregistrer"
              cancelText="Annuler"
              centered
              width={1200}
              title={
                editMode ? "Modifier le ordreCoupe" : "Ajouter Un Ordre Coupe"
              }
            >
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Informations Générales" key="1">
                  <Row>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Type OC"
                        name="prodTypeOrdreCoupeId"
                        rules={[
                          {
                            required: true,
                            message:
                              "Champ obligatoire avec maximum de caractère 100 !",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          allowClear
                          showSearch
                          dropdownStyle={{ position: "fixed" }}
                          placeholder="Type OC"
                          filterOption={(inputValue, option) => {
                            const value =
                              option.props.value?.toString().toLowerCase() ||
                              ""; // Ensure value is a string
                            const children =
                              option.props.children?.toString().toLowerCase() ||
                              ""; // Ensure children is a string
                            return (
                              value.includes(inputValue.toLowerCase()) ||
                              children.includes(inputValue.toLowerCase())
                            );
                          }}
                        >
                          {typeOrdreCoupe?.map((typeOrdreCoupe) => (
                            <Select.Option
                              key={typeOrdreCoupe.id || `key-${Math.random()}`}
                              value={typeOrdreCoupe.id || null} // Assign value only if typeOrdreCoupe.id exists
                            >
                              {typeOrdreCoupe.nom}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Type de Coupe"
                        name="prodTypeCoupeId"
                        rules={[
                          {
                            required: true,
                            message:
                              "Champ obligatoire avec maximum de caractère 100 !",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          allowClear
                          showSearch
                          placeholder="Type de Coupe"
                          filterOption={(inputValue, option) => {
                            const value =
                              option.props.value?.toString().toLowerCase() ||
                              ""; // Ensure value is a string
                            const children =
                              option.props.children?.toString().toLowerCase() ||
                              ""; // Ensure children is a string
                            return (
                              value.includes(inputValue.toLowerCase()) ||
                              children.includes(inputValue.toLowerCase())
                            );
                          }}
                        >
                          {typeCoupe?.map((typeCoupe) => (
                            <Select.Option
                              key={typeCoupe.id || `key-${Math.random()}`}
                              value={typeCoupe.id || null} // Assign value only if typeCoupe.id exists
                            >
                              {typeCoupe.nom}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Type de Tissus"
                        name="prodTypeTissuId"
                        rules={[
                          {
                            required: true,
                            message:
                              "Champ obligatoire avec maximum de caractère 100 !",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          allowClear
                          showSearch
                          placeholder="Type de Tissus"
                          filterOption={(inputValue, option) => {
                            const value =
                              option.props.value?.toString().toLowerCase() ||
                              ""; // Ensure value is a string
                            const children =
                              option.props.children?.toString().toLowerCase() ||
                              ""; // Ensure children is a string
                            return (
                              value.includes(inputValue.toLowerCase()) ||
                              children.includes(inputValue.toLowerCase())
                            );
                          }}
                        >
                          {typeTissu?.map((typeTissu) => (
                            <Select.Option
                              key={typeTissu.id || `key-${Math.random()}`}
                              value={typeTissu.id || null} // Assign value only if typeTissu.id exists
                            >
                              {typeTissu.nom}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Nombre de Pièces Objectif"
                        name="nombreObjectif"
                        rules={[
                          { required: true, message: "Champ obligatoire !" },
                        ]}
                      >
                        <Input size="large" type="text" maxLength={100} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab="Matière Première "
                  forceRender={true}
                  key="2"
                >
                  <Row>
                    <Col span={24}>
                      <b className="p-2">Matière Première ordre de Coupe :</b>
                    </Col>
                  </Row>
                  <Form.List
                    name="prod_order_coupes_compositions"
                    label="produit ordre de production"
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <>
                            <Row>
                              <Col span={8} className="px-2 mt-2">
                                <Form.Item
                                  {...restField}
                                  label="code"
                                  name={[name, "produitId"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    allowClear
                                    showSearch
                                    dropdownStyle={{ position: "fixed" }}
                                    placeholder="code"
                                    filterOption={(inputValue, option) => {
                                      const value =
                                        option.props.value
                                          ?.toString()
                                          .toLowerCase() || ""; // Ensure value is a string
                                      const children =
                                        option.props.children
                                          ?.toString()
                                          .toLowerCase() || ""; // Ensure children is a string
                                      return (
                                        value.includes(
                                          inputValue.toLowerCase()
                                        ) ||
                                        children.includes(
                                          inputValue.toLowerCase()
                                        )
                                      );
                                    }}
                                  >
                                    {produits?.map((produits) => (
                                      <Select.Option
                                        key={
                                          produits.id || `key-${Math.random()}`
                                        }
                                        value={produits.id || null} // Assign value only if produits.id exists
                                      >
                                        {produits.libelle}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col span={8} className="px-2 mt-2">
                                <Form.Item
                                  {...restField}
                                  label="unite"
                                  name={[name, "unite"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Champ obligatoire !",
                                    },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    className="w-100"
                                    allowClear
                                    showSearch
                                    dropdownStyle={{ position: "fixed" }}
                                    placeholder="unite"
                                    filterOption={(inputValue, option) => {
                                      const value =
                                        option.props.value
                                          ?.toString()
                                          .toLowerCase() || ""; // Ensure value is a string
                                      const children =
                                        option.props.children
                                          ?.toString()
                                          .toLowerCase() || ""; // Ensure children is a string
                                      return (
                                        value.includes(
                                          inputValue.toLowerCase()
                                        ) ||
                                        children.includes(
                                          inputValue.toLowerCase()
                                        )
                                      );
                                    }}
                                  >
                                    {unite?.map((unite) => (
                                      <Select.Option
                                        key={unite.id || `key-${Math.random()}`}
                                        value={unite.id || null} // Assign value only if unite.id exists
                                      >
                                        {unite.code_fr}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col span={7} className="px-2 mt-2">
                                <Form.Item
                                  {...restField}
                                  name={[name, "qteSortie"]}
                                  label="Quantité Sortie du stock"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <Input size="large" />
                                </Form.Item>
                              </Col>

                              <Col span={1} className="px-2 mt-2">
                                <Form.Item label=" ">
                                  <MinusCircleOutlined
                                    size="large"
                                    onClick={() => {
                                      if (name !== 0) remove(name);
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        ))}
                        <Row>
                          <Col span={12} className="px-2 mt-3">
                            <Button type="dashed" onClick={() => add()}>
                              Ajouter
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form.List>
                </Tabs.TabPane>
              </Tabs>
            </Modal>

            <Modal
              visible={validateCoupeModal}
              onOk={ordreCoupeForm.submit}
              onCancel={handleValidationCancel}
              getContainer={false}
              confirmLoading={isLoading}
              okText="Enregistrer"
              cancelText="Annuler"
              closable={!isDisabled}
              centered
              width={1200}
              title={"Validé l'ordre de coupe"}
            >
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Informations Générales" key="1">
                  <Row>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Nombre de Pièces Objectif"
                        name="nombreObjectif"
                        rules={[
                          { required: true, message: "Champ obligatoire !" },
                        ]}
                      >
                        <Input size="large" type="text" disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={6} className="px-2">
                      <Form.Item
                        label="Nombre de Pièces Produit Réellement"
                        name="nombreReel"
                        rules={[
                          { required: true, message: "Champ obligatoire !" },
                        ]}
                      >
                        <Input
                          size="large"
                          type="text"
                          disabled={isDisabled}
                          onChange={() => qteChanged()}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6} className="px-2">
                      <Form.Item
                        label="Nombre de face"
                        name="nombreFace"
                        rules={[
                          {
                            required: true,
                            message:
                              "Champ obligatoire avec maximum de caractère 100 !",
                          },
                        ]}
                      >
                        <Input size="large" type="text" disabled={isDisabled} />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="px-2">
                      <Form.Item
                        label="Nombre de dos"
                        name="nombreDos"
                        rules={[
                          {
                            required: true,
                            message:
                              "Champ obligatoire avec maximum de caractère 100 !",
                          },
                        ]}
                      >
                        <Input size="large" type="text" disabled={isDisabled} />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="px-2">
                      <Form.Item label="Nombre de Manches" name="nombreBras">
                        <Input size="large" type="text" disabled={isDisabled} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab="Matière Première "
                  forceRender={true}
                  key="2"
                >
                  <Row>
                    <Col span={24}>
                      <b className="p-2">Matière Première ordre de Coupe :</b>
                    </Col>
                  </Row>
                  <Form.List
                    name="prod_order_coupes_compositions"
                    label="produit ordre de production"
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <>
                            <Row>
                              <Col span={5} className="px-2 mt-2">
                                <Form.Item
                                  {...restField}
                                  name={[name, "produitId"]}
                                  label="code"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <Select size="large" disabled>
                                    {produits &&
                                      produits.map((c) => (
                                        <Select.Option key={c.id} value={c.id}>
                                          {c.libelle}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={4} className="px-2 mt-2">
                                <Form.Item
                                  {...restField}
                                  name={[name, "unite"]}
                                  label="unite"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Champ obligatoire !",
                                    },
                                  ]}
                                >
                                  <Select
                                    className="w-100"
                                    size="large"
                                    disabled
                                  >
                                    {unite &&
                                      unite.map((u) => (
                                        <Select.Option
                                          key={u.id}
                                          value={u.id}
                                          className="w-100"
                                        >
                                          {u.code_fr}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={5} className="px-2 mt-2">
                                <Form.Item
                                  {...restField}
                                  name={[name, "qteSortie"]}
                                  label="Quantité Sortie du stock"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <Input size="large" disabled={isDisabled} />
                                </Form.Item>
                              </Col>
                              <Col span={5} className="px-2 mt-2">
                                <Form.Item
                                  {...restField}
                                  name={[name, "qteDechetRecycle"]}
                                  label="Quantité Déchet Recyclé"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <Input size="large" disabled={isDisabled} />
                                </Form.Item>
                              </Col>
                              <Col span={5} className="px-2 mt-2">
                                <Form.Item
                                  {...restField}
                                  name={[name, "qteDechet"]}
                                  label="Qté Déchet à la fin"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Champ obligatoire avec maximum de caractère 100 !",
                                    },
                                  ]}
                                >
                                  <Input size="large" disabled={isDisabled} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Tabs.TabPane>
              </Tabs>
            </Modal>
          </Form>
        </Card>
      </Spin>
    </>
  );
};
export default OrdreCoupe;
