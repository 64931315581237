import {
    Col,
    DatePicker,
    Form,
    Input, Row, Select, Typography
} from "antd";
import React from "react";

const { Text, Title } = Typography;

function FicheStockDetails({ projetList }) {


  return (
    <Row>
      <Col span={24} className="pr-02">
        <Form.Item
          label="Date :"
          name="date"
          rules={[
            {
              required: true,
              message: "Champ obligatoire !",
            },
          ]}
        >
          <DatePicker placeholder=" " size="large" className="w-100" />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label="Référence :"
          name="reference"
        >
          <Input type={"text"} size="large" className="w-100" disabled />
        </Form.Item>
      </Col>
      {projetList &&
        <Col span={24}>
          <Form.Item
            label="Projet :"
            name="projetId"
          >
          <Select size="large" >
            {
            projetList.map(projet => (
              <Select.Option key={projet.id} value={projet.id}>
                {projet.titre} 
              </Select.Option>   
            ))
          }
          </Select>
          </Form.Item>
        </Col>
      }
      <Col span={24}>
        <Form.Item
          label="Note :"
          name="note"
        >
          <Input.TextArea rows={4} type="text" maxLength={255} />
        </Form.Item>
      </Col>
    </Row>
  );
}
export default FicheStockDetails;
