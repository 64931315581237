import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Switch,
  Tag,
  Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import Can from "../../../../security/Can";
import MarqueService from "../../../../services/marque.service";
import TableWithFiltres from "../../../uiHelpers/TableFilters";

const Marque = () => {
  const [marqueForm] = Form.useForm();
  const [categorieModal, setMarqueModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [switchValue, setSwitchValue] = useState("unchecked");


  useEffect(() => {
    loadMarques();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadMarques = async () => {
    setLoading(true);
    const response = await MarqueService.getMarques();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const initTableData = (data) => {
    setDataTable(data);
  };

  const showMarqueModal = () => {
    setMarqueModal(true);
  };

  const handleOk = () => {
    setMarqueModal(false);
  };

  const updateMode = (record) => {
    setMarqueModal(true);
    const checkedSwitch = record.etat ? "checked" : "unchecked";
    setSwitchValue(checkedSwitch);
    marqueForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const handleCancel = () => {
    setMarqueModal(false);
    clearData();
  };

  const addMarque = async (values) => {
    setLoading(true);
    var data = values
    data.etat = data.etat ? data.etat : false; 
    const response = editMode
      ? await MarqueService.updateMarque(updateId, data)
      : await MarqueService.addMarque(data);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Marque ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    closingModal();
  };

  const closingModal = () => {
    loadMarques();
    setMarqueModal(false);
    setLoading(false);
    clearData();
  };

  const clearData = () => {
    marqueForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const MarqueColumns = [
    {
      title: "Nom",
      dataIndex: "nom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
      width: "17%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "55%",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Etat",
      dataIndex: "etat",
      sorter: (a, b) => a.valeur - b.valeur,
      render: (text, record) => (
        <Tag color={record.etat ? "cyan" : "red" }>{record.etat ? "Activé" : "Désactivé"}</Tag>
      ),
      width: "10%",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("3_3")}
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer cette Marque ?",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };
  const deleteItem = async (item) => {
    const response = await MarqueService.deleteMarque(item.id);
    if (response.status === 204 || 200) {
      message.info("Suppression avec success");
      loadMarques();
    } else message.error("Une erreur est survenu ! ");
  };

  return (
    <Spin spinning={isLoading} size="large">
      <Card
        
        title={"Marque"}
        extra={
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              disabled={!Can("3_2")}
              onClick={() => showMarqueModal()}
            >
              Ajouter une Marque
            </Button>
        }
      >
        <TableWithFiltres data={dataTable} columns={MarqueColumns}></TableWithFiltres>
        <Form disabled={isLoading} name="formMarque" layout="vertical" form={marqueForm} onFinish={addMarque}>
          <Modal
            visible={categorieModal}
            onOk={marqueForm.submit}
            onCancel={handleCancel}
            getContainer={false}
            confirmLoading={isLoading}
            okText="Enregistrer"
            cancelText="Annuler"
            centered
            width={600}
            title={
              <>
                <div className="d-flex justify-content-between ">
                  <div className="my-0">
                    {editMode
                      ? "Modifier la marque"
                      : "Ajouter une marque"}
                  </div>
                  <div>
                    <Form.Item
                      className="my-0 mx-5"
                      name="etat"
                      valuePropName={switchValue}
                    >
                      <Switch
                        checkedChildren="Activer"
                        unCheckedChildren="Désactiver"
                        className="px-4"
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
            }
          >
            <Row>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Nom de la marque "
                  name="nom"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input size="large" type="text" maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={24} className="px-2">
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      message:
                        "Champ obligatoire avec maximum de caractère 255 !",
                      max: 254,
                    },
                  ]}
                >
                  <TextArea rows={4} type="text" maxLength={255} />
                </Form.Item>
              </Col>
            </Row>
          </Modal>
        </Form>
      </Card>
    </Spin>
  );
};
export default Marque;
