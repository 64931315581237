import { Card, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import BroderieService from "../../../../services/broderie.service";
import TableFilters from "../../../uiHelpers/TableFilters";
    
const StockBroderie = () => {
  
  const [isLoading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);


  useEffect(() => {
    loadStock();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
  };
  const loadStock = async () => {
    setLoading(true);
    const response = await BroderieService.loadStock();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };


  const initTableData = (data) => {
    for (const element of data)
      element.showProduit = element?.produit?.libelle;
      console.log(data);
    sortByUpdatedAtd(data)  
    setDataTable(data)
  };

  const sortByUpdatedAtd = (data) => {
    return data.sort((a,b)=>{
         return new Date(a.updatedAt).getTime() - 
         new Date(b.updatedAt).getTime()
     }).reverse();
  }

  const colums = [
    {
      title: "Reference Interne",
      dataIndex: ["produit","reference_interne"],
      sorter: (a, b) => a.showProduit.localeCompare(b.showProduit),
    },
    {
      title: "Produit",
      dataIndex: "showProduit",
      sorter: (a, b) => a.showProduit.localeCompare(b.showProduit),
    },
    {
      title: "Faces",
      dataIndex: "face",
      sorter: (a, b) => a > b,
    },
    {
      title: "Dos",
      dataIndex: "dos",
      sorter: (a, b) => a > b,
    },
  ];


  return (
      <>
          <Spin spinning={isLoading} size="large">
              <Card title={"Stock Broderie"} >
                  <TableFilters data={dataTable} columns={colums} />
              </Card>
          </Spin>
      </>
  );
};
export default StockBroderie;
    