import { Col } from "antd";
import React, { useEffect } from "react";

function FicheTableTotauxFactureVente({ fodec,entreprise,isDarkMode ,prixTotal,selectedCurrency }) {
  useEffect(() => {
  }, [prixTotal,selectedCurrency]);
  return (
    <Col span="8" className="px-4">
      <table className={`table ${isDarkMode ? "table-dark" : "" } `}>
        <thead>
          <tr>
          <th scope="row">Totaux</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Total HT</th>
            <td>{Number(prixTotal?.total_ht).toFixed(3)} {selectedCurrency}</td>
          </tr>
          { prixTotal?.acompte_montant &&  Number(prixTotal?.acompte_montant) > 1 ? (
              <>
                <tr>
                  <th scope="row">Acompte (à deduire)</th>
                  <td>{Number(prixTotal?.acompte_montant).toFixed(3)} {selectedCurrency}</td>
                </tr>
                <tr>
                  <th scope="row">Montant HT avec Acompte </th>
                  <td>{Number(prixTotal?.total_ht - prixTotal?.acompte_montant).toFixed(3)} {selectedCurrency}</td>
                </tr>
              </>
          ) :<></>}
          { fodec ? (
            <tr>
              <th scope="row">Fodec</th>
              <td>{Number(prixTotal?.fodec).toFixed(3)} {selectedCurrency}</td>
            </tr>
          ) : ( <></>)}
          <tr>
            <th scope="row">TVA</th>
            <td>{Number(prixTotal?.tva).toFixed(3)} {selectedCurrency}</td>
          </tr>
          <tr>
            <th scope="row">Totat TTC </th>
            <td>{Number(prixTotal?.total_with_acompte).toFixed(3)} {selectedCurrency}</td>
          </tr>
          <tr>
            <th scope="row">Timbre fiscale</th>
            <td>{Number(prixTotal?.totalTimbreFiscal).toFixed(3)} {selectedCurrency}</td>
          </tr>
          <tr>
            <th scope="row">Total</th>
            <th scope="row">{Number(prixTotal?.montant_total).toFixed(3)} {selectedCurrency}</th>
          </tr>
        </tbody>
      </table>
    </Col>
  );
}
export default FicheTableTotauxFactureVente;
