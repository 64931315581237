import {
  Card, message, Spin
} from "antd";
import React, { useEffect, useState } from "react";
import AssemblageService from "../../../../services/assemblage.service";


import TableFilters from "../../../uiHelpers/TableFilters";
  
  const StockAssemblage = () => {
    
    const [isLoading, setLoading] = useState(false);
    const [dataTable, setDataTable] = useState([]);

  
    useEffect(() => {
      loadStock();
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
    };
    const loadStock = async () => {
      setLoading(true);
      const response = await AssemblageService.loadStock();
      if (response.status === 200 || 204) initTableData(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };


    const initTableData = (data) => {
      for (const element of data) 
        element.showProduit = element?.produit?.libelle;
      sortByUpdatedAtd(data)
      setDataTable(data)
    };
  
    const sortByUpdatedAtd = (data) => {
      return data.sort((a,b)=>{
           return new Date(a.updatedAt).getTime() - 
           new Date(b.updatedAt).getTime()
       }).reverse();
    }

    const colums = [
      {
        title: "Produit",
        dataIndex: "showProduit",
        sorter: (a, b) => a.showProduit - b.showProduit,
      },
      {
        title: "Quantité",
        dataIndex: "quantite",
        sorter: (a, b) => a.quantite - b.quantite,
      },
    ];
  
 
    return (
        <>
            <Spin spinning={isLoading} size="large">
                <Card title={"Stock Assemblage"} >
                    <TableFilters data={dataTable} columns={colums} />
                </Card>
            </Spin>
        </>
    );
  };
  export default StockAssemblage;
  