import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addBroderie = ( broderie ) => {
  return axios.post(API_URL + "/broderie/" , broderie ,{ headers: authHeader() });
};

const getBroderies = () => {
  return axios.get(API_URL + "/broderie/" , { headers: authHeader() });
};
const getActiveBroderies = () => {
  return axios.get(API_URL + "/broderie/active" , { headers: authHeader() });
};
const updateBroderie = (id , broderie ) => {
  return axios.put(API_URL + "/broderie/" + id , broderie, { headers: authHeader() });
};
const deleteBroderie = (id) => {
  return axios.delete(API_URL + "/broderie/" + id , { headers: authHeader() });
};

const updateOdreCoupeStatus = ( id , etat ) => {
  return axios.put(API_URL + "/broderie/status/" + id , etat, { headers: authHeader() });
}

const loadStockQte = ( data )=> {
  return axios.post(API_URL + "/broderie/qteStock/", data, { headers: authHeader() } )
}

const loadStock = () => {
  return axios.get(API_URL + "/broderie/stock" , { headers: authHeader() });
}

const exportsMethodes = {
  addBroderie,
  getBroderies,
  updateBroderie,
  deleteBroderie,
  getActiveBroderies,
  updateOdreCoupeStatus,
  loadStockQte,
  loadStock
};
export default exportsMethodes;