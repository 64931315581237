import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
  } from "@ant-design/icons";
  import {
    Button,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Spin,
    Switch,
    Tag,
    Tooltip,
  } from "antd";
  import TextArea from "antd/lib/input/TextArea";
  import React, { useEffect, useState } from "react";
  import AppTable from "../../../uiHelpers/AppTabe";
  import TimbreFiscalService from "../../../../services/timbreFiscal.service";
  import Can from "../../../../security/Can";

  const TimbreDiscal = () => {
    const [timbreForm] = Form.useForm();
    const [timbreModal, setTimbreModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [switchValue, setSwitchValue] = useState("unchecked");

    useEffect(() => {
        loadTimbres();
        return () => {
          clearStates();
        };
    }, []);

    const clearStates = () => {
        setDataTable([]);
        setEditMode(false);
        setUpdateId(null);
    };

    const loadTimbres = async () => {
        setLoading(true);
        const response = await TimbreFiscalService.getTimbres()
        if (response.status === 200 || 204) initTableData(response.data);
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
    };

    const initTableData = (data) => {
        setDataTable(data);
    };

    const updateMode = (record) => {
        setTimbreModal(true);
        const checkedSwitch = record.etat ? "checked" : "unchecked";
        setSwitchValue(checkedSwitch);
        timbreForm.setFieldsValue(record);
        setUpdateId(record.id);
        setEditMode(true);
    };

    const addTimbre = async (values) => {
        setLoading(true);
        var data = values
        data.etat = data.etat ? data.etat : false; 
        data.type = 2;
        const response = editMode
          ? await TimbreFiscalService.updateTimbre(updateId, data)
          : await TimbreFiscalService.addTimbre(data);
        if (response.status === 200 || 204) {
          if (editMode) message.info("Mise à jour avec success");
          else message.info("Taxe ajouter avec success");
        } else message.error("Une erreur est survenu ! ");
    
        closingModal();
    };

    const closingModal = () => {
        loadTimbres();
        setTimbreModal(false);
        setLoading(false);
        clearData();
    };

    const clearData = () => {
        timbreForm.resetFields();
        setEditMode(false);
        setUpdateId(null);
    };

    const showTimbreModal = () => {
        setTimbreModal(true);
    };

    const handleCancel = () => {
        setTimbreModal(false);
        clearData();
    };

    const TaxeColumns = [
        {
          title: "Nom",
          dataIndex: "titre",
          sorter: (a, b) => a.nom.localeCompare(b.nom),
          width: "17%",
        },
        {
          title: "Description",
          dataIndex: "description",
          width: "40%",
          sorter: (a, b) => a.description.localeCompare(b.description),
        },{
          title: "Valeur",
          dataIndex: "valeur",      
          width: "20%",
          sorter: (a, b) => a.valeur.localeCompare(b.valeur),
        },
        {
          title: "Etat",
          dataIndex: "etat",
          sorter: (a, b) => a.valeur - b.valeur,
          render: (text, record) => (
            <Tag color={record.etat ? "cyan" : "red" }>{record.etat ? "Activé" : "Désactivé"}</Tag>
          ),
          width: "10%",
        },
        {
          title: "Action",
          key: "action",
          width: "10%",
    
          render: (text, record) => (
            <div>
              <Tooltip title="Mettre à jour">
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  disabled={!Can("2_3")}
    
                  onClick={() => updateMode(record)}
                  icon={<EditOutlined />}
                />
              </Tooltip>
    
            </div>
          ),
        },
    ];

    const confirmDelete = (record) => {
        Modal.confirm({
          title: "Attention",
          icon: <ExclamationCircleOutlined />,
          content: "Êtes-vous sûr de vouloir supprimer cette Timbre ?",
          okText: "Oui",
          onOk: () => deleteItem(record),
          cancelText: "Non",
        });
    };
      const deleteItem = async (item) => {
        const response = await TimbreFiscalService.deleteTaxe(item.id);
        if (response.status === 204 || 200) {
          message.info("Suppression avec success");
          loadTimbres()
        } else message.error("Une erreur est survenu ! ");
    };


    return (
        <Spin spinning={isLoading} size="large">
          <Card
            
            title={"Tmibre Fiscal"}
            extra={
              Can("2_2") && (  
                <Button
                  className="mx-2"
                  shape="round"
                  type="primary"
                  onClick={() => showTimbreModal()}
                >
                  Ajouter une Timbre
                </Button>
              )
            }
          >
            <AppTable data={dataTable} columns={TaxeColumns} />
            <Form layout="vertical" form={timbreForm} onFinish={addTimbre}>
              <Modal
                visible={timbreModal}
                onOk={timbreForm.submit}
                onCancel={handleCancel}
                getContainer={false}
                confirmLoading={isLoading}
                okText="Enregistrer"
                cancelText="Annuler"
                centered
                width={600}
                title={
                  <>
                    <div className="d-flex justify-content-between ">
                      <div className="my-0">
                        {editMode
                          ? "Modifier la timbre"
                          : "Ajouter une timbre"}
                      </div>
                      <div>
                        <Form.Item
                          className="my-0 mx-5"
                          name="etat"
                          valuePropName={switchValue}
                        >
                          <Switch
                            checkedChildren="Activer"
                            unCheckedChildren="Désactiver"
                            className="px-4"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                }
              >
                <Row>
                  <Col span={12} className="px-2">
                    <Form.Item
                      label="Nom de la timbre "
                      name="titre"
                      rules={[
                        {
                          required: true,
                          message:
                            "Champ obligatoire avec maximum de caractère 100 !",
                          max: 99,
                        },
                      ]}
                    >
                      <Input size="large" type="text" maxLength={100} />
                    </Form.Item>
                  </Col>
                  <Col span={12} className="px-2">
                    <Form.Item
                      label="Valeur"
                      name="valeur"
                      rules={[
                        {
                          required: true,
                          message:"Champ obligatoire avec maximum de caractère 100 !",
                        },
                      ]}
                    >
                      <InputNumber
                        size="large"
                        style={{ width: "100%" }}
                        min={0}
                        max={99}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          message:
                            "Champ obligatoire avec maximum de caractère 255 !",
                          max: 254,
                        },
                      ]}
                    >
                      <TextArea rows={4} type="text" maxLength={255} />
                    </Form.Item>
                  </Col>
                </Row>
              </Modal>
            </Form>
          </Card>
        </Spin>
    );

  }
  export default TimbreDiscal;