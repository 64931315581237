import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Tabs,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import unite from "../../../helpers/unite";
import CategorieService from "../../../services/categorie.service";
import FournisseurService from "../../../services/fournisseur.service";
import MarqueService from "../../../services/marque.service";
import ProduitService from "../../../services/produit.service";
import TvaService from "../../../services/tva.service";
import AppTable from "../../uiHelpers/AppTabe";
import AddCategorieModalForm from "../parametres/tabs/AddCategorieModalForm";
import AddMarqueModalForm from "../parametres/tabs/AddMarqueModalForm";
import AddTvaModalForm from "../parametres/tabs/AddTvaModalForm";
import AddFournisseurModalForm from "./AddFournisseurModalForm";

const AddProductModalForm = ({showModal,productAdded}) => {
  const [produitForm] = Form.useForm();
  const [produitModal, setProduitModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [switchValue, setSwitchValue] = useState("unchecked");
  const [isProduct, setProduitType] = useState(false);
  const [current, setCurrent] = useState(0);
  const [marque, setMarques] = useState([]);
  const [categorie, setCategories] = useState([]);
  const [fournisseur, setFournisseur] = useState([]);
  const [tva, setTvas] = useState([]);
  const [prixAchat, setPrixAchat] = useState({ ttc: 0, ht: 0 });
  const [prixVente, setPrixVente] = useState({ ttc: 0, ht: 0 });
  const [marges, setMarges] = useState({ margeBrute: 0, margeReel: 0 });
  const [proprietereVal, setProprietreVal] = useState(true);

  const [categorieModal, setCategorieModal] = useState(false);
  const [marqueModal, setMarqueModal] = useState(false);
  const [tvaModal, setTvaModal] = useState(false);
  const [fournisseurModal, setFournisseurModal] = useState(false);

  useEffect( () => {
    async function fetchData() {
      await loadTvas();
      await loadMarques();
      await loadCategories();
      await loadFournisseur();
    }
    fetchData();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setPrixAchat({ ttc: 0, ht: 0 });
    setPrixVente({ ttc: 0, ht: 0 });
    setMarges({ margeBrute: 0, margeReel: 0 });

  };

  const loadFournisseur = async () => {
    setLoading(true);
    const response = await FournisseurService.getFournisseurs();
    if (response.status === 200 || 204) setFournisseur(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const loadMarques = async () => {
    setLoading(true);
    const response = await MarqueService.getActiveMarques();
    if (response.status === 200 || 204) setMarques(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadCategories = async () => {
    setLoading(true);
    const response = await CategorieService.getActiveCategories();
    if (response.status === 200 || 204) setCategories(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const loadTvas = async () => {
    setLoading(true);
    const response = await TvaService.getActiveTva();
    if (response.status === 200 || 204) setTvas(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const prixVenteChanged = () => {
    const produit = produitForm.getFieldsValue();
    const prixVenteInput = produit.prixVente;
    const taxeId = produit.tvaVente;
    const taxe = tva.find((e) => e.id === taxeId)?.valeur ?? 1
    const ht = Number(prixVenteInput).toFixed(3);
    const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
    setPrixVente((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
    const marge_ht = ((prixVenteInput- prixAchat.ht) / prixAchat.ht) * 100;
    produitForm.setFieldsValue({ marge_ht: marge_ht });
    setMargesValues()
  }

  const handleCancel = () => {
    clearData();
    productAdded(false);

  };


  const closingModal = () => {
    setProduitModal(false);
    setLoading(false);
    setSwitchValue("unchecked");
    clearData();
  };

  const clearData = () => {
    produitForm.resetFields();
    produitForm.setFieldsValue({ type: false });
    setProduitType(false);
    setPrixAchat({ ttc: 0, ht: 0 });
    setPrixVente({ ttc: 0, ht: 0 });
    setMarges({ margeBrute: 0, margeReel: 0 });
    setEditMode(false);
    setUpdateId(null);
  };


  const switchChange = () => {
    setProduitType(produitForm.getFieldValue("type"));
  };

  const calculateAchat = () => {
    if (
      !produitForm.getFieldValue("typePrix") ||
      !produitForm.getFieldValue("prixFournisseur")
    )
      return;
    const prixType = produitForm.getFieldValue("typePrix");
    const prix = produitForm.getFieldValue("prixFournisseur");
    const taxe =
      tva.find((e) => e.id === produitForm.getFieldValue("tvaAchat"))?.valeur ??
      0;
    const { ttc, ht } = calculateTotaux(prixType, prix, taxe);
    setPrixAchat((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
  };
  
  const calculateTotaux = (type, prix, taxe) => {
    if (type === "HT") {
      const ht = Number(prix).toFixed(3);
      const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
      return { ttc, ht };
    } else {
      const ttc = Number(prix).toFixed(3);
      const ht = (ttc / (1 + taxe / 100)).toFixed(3);
      return { ttc, ht };
    }
  };
  const calculateMarges = (venteHt, achatHt) => {
    const margeBrute = (((venteHt - achatHt) / venteHt) * 100).toFixed(2);
    const margeReel = (((venteHt - achatHt) / achatHt) * 100).toFixed(2);
    return { margeBrute, margeReel };
  };
  const setProprietereValue = () => {
    setProprietreVal(produitForm.getFieldValue("proprietere"));
  };
  const openAddCategorieModal = () => {
    setCategorieModal(true);
  };
  const onCategorieModalClose = async (action) => {
    if (action) await loadCategories();
    setCategorieModal(false);
  };
  const openAddMarqueModal = () => {
    setMarqueModal(true);
  };
  const onMarqueModalClose = async (action) => {
    if (action) await loadMarques();
    setMarqueModal(false);
  };
  const openAddTvaModal = () => {
    setTvaModal(true);
  };
  const onTvaModalClose = async (action) => {
    if (action) await loadTvas();
    setTvaModal(false);
  };
  const openAddFournisseurModal = () => {
    setFournisseurModal(true);
  };
  const onFournisseurModalClose = async (action) => {
    if (action) await loadFournisseur()
    setFournisseurModal(false);
  };

  const addProduit = async (values) => {
    setLoading(true);
    var data = values;
    data.type = data.type ? data.type : false;

    const taxeVente = tva.find(
      (e) => e.id === produitForm.getFieldValue("tvaVente")
    )?.valeur;
    const taxeAchat = tva.find(
      (e) => e.id === produitForm.getFieldValue("tvaAchat")
    )?.valeur;
    const fournisseurId = produitForm.getFieldValue("fournisseurId");
    const produit_ventes = {
      tva: taxeVente,
      unite: data.uniteVente,
      prixTTC: prixVente.ttc,
      prixHT: prixVente.ht,
    };
    data.produit_ventes = produit_ventes;

    const produit_achats = {
      tva: taxeAchat,
      unite: data.uniteAchat,
      prixTTC: prixAchat.ttc,
      prixHT: prixAchat.ht,
      fournisseurId: fournisseurId,
    };
    data.produit_achats = produit_achats;

    const response = editMode
      ? await ProduitService.updateProduit(updateId, values)
      : await ProduitService.addProduit(values);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Produit ajouter avec success");
    } else message.error("Une erreur est survenu ! ");
    closingModal();
    productAdded(true);
    setLoading(false);

  };


  const margeChanged = () => {
    const product = produitForm.getFieldsValue();
    const prixVenteHt = Number(prixAchat.ht) + ( Number(prixAchat.ht) * ( product.marge_ht / 100 ) );
    produitForm.setFieldsValue({
      tvaVente : product.tvaAchat,
      uniteVente : product.uniteAchat,
      prixVente : prixVenteHt,
      VentetypePrix : "HT"
    })
    const ht = Number(prixVenteHt).toFixed(3);
    const taxe = tva.find((e) => e.id === produitForm.getFieldValue("tvaVente"))?.valeur ?? 0;
    const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
    setPrixVente((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
    setMargesValues();
  }
  const setMargesValues = () => {
    const { margeBrute, margeReel } = calculateMarges(
      prixVente.ht,
      prixAchat.ht
    );
    setMarges({ margeBrute, margeReel });
  }


  return (
    <Spin spinning={isLoading} size="large">
   
      <Form layout="vertical" form={produitForm} onFinish={addProduit}>
        <Modal
          visible={showModal}
          onOk={produitForm.submit}
          onCancel={handleCancel}
          getContainer={false}
          confirmLoading={isLoading}
          okText="Enregistrer"
          cancelText="Annuler"
          centered
          width={900}
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  {editMode ? "Modifier le produit" : "Ajouter Un produit"}
                </div>
                <div>
                  <Form.Item
                    className="my-0 mx-5"
                    name="type"
                    valuePropName={switchValue}
                  >
                    <Switch
                      checkedChildren="Service"
                      unCheckedChildren="Produit"
                      className="px-4"
                      onChange={() => switchChange()}
                    />
                  </Form.Item>
                </div>
              </div>
            </>
          }
        >
          <Tabs>
            <Tabs.TabPane
              tab={
                <span className="d-flex align-items-center px-3 pr-4 without-hover-effect">
                  {/* <CloseCircleOutlined /> */}
                  Informations
                </span>
              }
              key="1"
            >
              <Row>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="libelle"
                    name="libelle"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Row>
                    <Col span={20} className="pl-2">
                      <Form.Item
                        label="Catégorie"
                        name="categorieId"
                        rules={[
                          {
                            required: true,
                            message:
                              "Champ obligatoire avec maximum de caractère 100 !",
                          },
                        ]}
                      >
                        <Select size="large">
                          {categorie.map((c) => (
                            <Select.Option key={c.id} value={c.id}>
                              {c.nom}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={4} className="pr-2">
                      <Form.Item label=" ">
                        <Tooltip title="Ajouter une categorie">
                          <Button
                            className="margin-05"
                            type=""
                            onClick={() => openAddCategorieModal()}
                            icon={<PlusOutlined />}
                            size={"large"}
                          />
                        </Tooltip>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Référence Interne"
                    name="reference_interne"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Code barre"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Row>
                    <Col span={20} className="pl-2">
                      <Form.Item
                        label="Marque"
                        name="marqueId"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <Select size="large">
                          {marque.map((m) => (
                            <Select.Option key={m.id} value={m.id}>
                              {m.nom}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={4} className="pr-2">
                      <Form.Item label=" ">
                        <Tooltip title="Ajouter une marque">
                          <Button
                            className="margin-05"
                            onClick={() => openAddMarqueModal()}
                            type=""
                            icon={<PlusOutlined />}
                            size={"large"}
                          />
                        </Tooltip>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={8} className="px-2">
                  <Form.Item
                    label="référence constructeur"
                    name="reference_constructeur"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
              </Row>

                  <Row>
                    <Col span={24} className="p-2">
                      {!isProduct ? <b>   Prix Achat :</b> : <b>   Coût du service :</b> } 
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} className="px-2">
                      <Row>
                        <Col span={21} className="pl-2">
                          <Form.Item
                            label="Tva"
                            name="tvaAchat"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Champ obligatoire avec maximum de caractère 100 !",
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              onChange={() => calculateAchat()}
                            >
                              {tva.map((t) => (
                                <Select.Option key={t.id} value={t.id}>
                                  {t.titre} - {t.valeur} %
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={3} className="pr-2">
                          <Form.Item label=" ">
                            <Tooltip title="Ajouter une TVA ">
                              <Button
                                className="margin-05"
                                onClick={() => openAddTvaModal()}
                                type=""
                                icon={<PlusOutlined />}
                                size={"large"}
                              />
                            </Tooltip>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label="Unité"
                        name="uniteAchat"
                        rules={[
                          {
                            required: true,
                            message:
                              "Champ obligatoire avec maximum de caractère 100 !",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          onChange={() => calculateAchat()}
                        >
                          {unite.map((u) => (
                            <Select.Option key={u.id} value={u.id}>
                              {u.code_fr}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} className="px-2">
                      <Form.Item
                        label={!isProduct ? <> Prix Fournisseur </> : <> Coût</>}
                        name="prixFournisseur"
                        rules={[
                          {
                            required: true,
                            message:"Champ obligatoire avec maximum de caractère 100 !",
                          },
                        ]}
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          min={0}
                          max={99999999999}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="px-2">
                      <Form.Item
                        label="Type"
                        name="typePrix"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire ",
                          },
                        ]}
                      >
                        <Radio.Group onChange={() => calculateAchat()}>
                          <Radio value={"TTC"}>TTC</Radio>
                          <Radio value={"HT"}>HT</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={6} className="px-2">
                      <Form.Item label="Résultat:">
                        Prix TTC : {prixAchat.ttc} <br />
                        Prix HT : {prixAchat.ht}
                        <br />
                        <Input type={"hidden"}></Input>
                      </Form.Item>
                    </Col>
                  </Row>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span className="d-flex align-items-center px-3 pr-4 without-hover-effect">
                  Prix vente
                </span>
              }
              key="2"
            >
              <Row>
                <Col span={12} className="px-2 my-2">
                  <b className="px-2">Prix Vente :</b>
                </Col>
              </Row>
              <Row>
                  <Col span={16} className="px-2">
                    <Row>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Marge HT"
                          name="marge_ht"
                          rules={[
                            {
                              required: true,
                              message: "Champ obligatoire !",
                            },
                          ]}
                        >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          min={0}
                          max={99999999999}
                          onChange={() => margeChanged()}
                        />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Prix Vente HT"
                          name="prixVente"
                          rules={[
                            {
                              required: true,
                              message:"Champ obligatoire !",
                            },
                          ]}
                        >
                          <InputNumber
                            size="large"
                            style={{ width: "100%" }}
                            min={0}
                            max={99999999999}
                            onChange={() => prixVenteChanged()}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8} className="px-4">
                  <Form.Item label="Résultat:">
                    Prix HT : {prixVente.ht} <br />
                    Prix TTC : {prixVente.ttc} <br />
                    {/* { marges?.margeBrute !== 0 && (
                      <>
                        Marge Brute : {marges.margeBrute} %<br />
                        Marge Réel : {marges.margeReel} % <br />
                      </>
                    )} */}
                    <Input type={"hidden"}></Input>
                  </Form.Item>
                </Col>

                </Row>
              <Row>

                <Col span={6} className="px-2">
                  <Form.Item
                    label="Type"
                    name="VentetypePrix"
                    style={{ visibility: "hidden" }}
                  >
                    <Radio.Group>
                      <Radio value={"TTC"}>TTC</Radio>
                      <Radio value={"HT"}>HT</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={6} className="px-2">
                    <Form.Item
                      label="Tva"
                      name="tvaVente"
                      style={{ visibility: "hidden" }}
                    >
                      <Select  size="large" disabled>
                        {tva.map((t) => (
                          <Select.Option key={t.id} value={t.id}>
                            {t.titre} - {t.valeur} %
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                </Col>
                <Col span={6} className="px-2">
                  <Form.Item
                    label="Unité"
                    name="uniteVente"
                    style={{ visibility: "hidden" }}
                  >
                    <Select
                      className="w-100"
                      size="large"
                      disabled
                      onChange={() => calculateAchat()}
                    >
                      {unite.map((u) => (
                        <Select.Option
                          key={u.id}
                          value={u.id}
                          className="w-100"
                        >
                          {u.code_fr}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span className="d-flex align-items-center without-hover-effect">
                  {true && <CloseCircleOutlined className="red-flag" />}
                  <span className="red-flag"> Description </span>
                </span>
              }
              key="3"
            >
              <Row>
                <Col span={12} className="px-2">
                  <Form.Item
                    name="proprietere"
                    label="Votre entreprise est Propriétaire?"
                    rules={[
                      { required: true, message: "Champ obligatoire !" },
                    ]}
                  >
                    <Radio.Group onChange={() => setProprietereValue()}>
                      <Radio value={false}>Non</Radio>
                      <Radio value={true}>Oui</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  {!proprietereVal && (
                    <Col span={24} className="px-2">
                      <Row>
                        <Col span={21} className="pl-2">
                          <Form.Item
                            label="Fournisseur"
                            name="fournisseurId"
                            rules={[
                              {
                                required: true,
                                message: "Champ obligatoire !",
                              },
                            ]}
                          >
                            <Select size="large" className="w-100">
                              {fournisseur.map((f) => (
                                <Select.Option key={f.id} value={f.id}>
                                  {f.nom} {f.prenom}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={3} className="pr-2">
                          <Form.Item label=" ">
                            <Tooltip title="Ajouter un fournisseur">
                              <Button
                                className="margin-05"
                                onClick={() => openAddFournisseurModal()}
                                type=""
                                icon={<PlusOutlined />}
                                size={"large"}
                              />
                            </Tooltip>
                            <AddFournisseurModalForm
                              showModal={fournisseurModal}
                              fournisseurAdded={(action) =>
                                onFournisseurModalClose(action)
                              }
                            ></AddFournisseurModalForm>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24} className="px-2">
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        message:
                          "Champ obligatoire avec maximum de caractère 255 !",
                        max: 254,
                      },
                    ]}
                  >
                    <TextArea type="text" />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Modal>
      </Form>
      <AddTvaModalForm
        showModal={tvaModal}
        tvaAdded={(action) => onTvaModalClose(action)}
      ></AddTvaModalForm>
      <AddMarqueModalForm
        showModal={marqueModal}
        marqueAdded={(action) => onMarqueModalClose(action)}
      ></AddMarqueModalForm>
      <AddCategorieModalForm
        showModal={categorieModal}
        categorieAdded={(action) => onCategorieModalClose(action)}
      ></AddCategorieModalForm>
    </Spin>
  );
};
export default AddProductModalForm;
