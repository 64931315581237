import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const getFinanceClient = () => {
    return axios.get(API_URL + "/finance_client/" , { headers: authHeader() });
}

const getBonLivrisons = (id) => {
    return axios.get(API_URL + "/finance_client/bonLivrisons/" + id , { headers: authHeader() });
}

const getClientPaiment = (id) => {
    return axios.get(API_URL + "/finance_client/paiments/" + id , { headers: authHeader() });
}

const getFinanceClientByDate = (searchObj) => {
    return axios.post(API_URL + "/finance_client/by_date/" , searchObj , { headers: authHeader() });
}

const getClientPaimentByDate = (id, searchObj) => {
    return axios.post(API_URL + "/finance_client/paiments/by_date/"+id , searchObj , { headers: authHeader() });
}

const getFinanceFournisseur = () => {
    return axios.get(API_URL + "/finance_fournisseur/" , { headers: authHeader() });
}

const getFournisseurPaiment = (id) => {
    return axios.get(API_URL + "/finance_fournisseur/paiments/" + id , { headers: authHeader() });
}

const getAllBonCommandes = () => {
    return axios.get(API_URL + "/finance_fournisseur/bonCommandes/" , { headers: authHeader() });
}

const getFinanceFournisseurByDate = (searchObj) => {
    return axios.post(API_URL + "/finance_fournisseur/by_date/" , searchObj , { headers: authHeader() });
}

const getFournisseurPaimentByDate = (id, searchObj) => {
    return axios.post(API_URL + "/finance_fournisseur/paiments/by_date/"+id , searchObj , { headers: authHeader() });
}

const getClientHistory = (searchObj) => {
    return axios.post(API_URL + "/finance_client/history/", searchObj , { headers: authHeader() });
}

const gerenateHistoriqueCLient = (searchObj,dataTable) => {
    return axios.post(API_URL + "/finance_client/generateHistory/", {searchObj: searchObj,dataTable :dataTable} , { headers: authHeader() });
}

const getFournisseurHistory = (searchObj) => {
    return axios.post(API_URL + "/finance_fournisseur/history/", searchObj , { headers: authHeader() });
}

const gerenateHistoriqueFournisseur = (searchObj,dataTable) => {
    return axios.post(API_URL + "/finance_fournisseur/generateHistory/", {searchObj: searchObj,dataTable :dataTable} , { headers: authHeader() });
}

const exportsMethodes = {
    getFinanceClient,
    getBonLivrisons,
    getClientPaiment,
    getFinanceClientByDate,
    getClientPaimentByDate,
    getFinanceFournisseur,
    getFournisseurPaiment,
    getAllBonCommandes,
    getFinanceFournisseurByDate,
    getFournisseurPaimentByDate,
    getClientHistory,
    getFournisseurHistory,
    gerenateHistoriqueCLient,
    gerenateHistoriqueFournisseur
  };
  export default exportsMethodes;