import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addReferenceBroderie = ( referenceBroderie ) => {
  return axios.post(API_URL + "/referenceBroderie/" , referenceBroderie ,{ headers: authHeader() });
};

const getReferenceBroderies = () => {
  return axios.get(API_URL + "/referenceBroderie/" , { headers: authHeader() });
};
const getActiveReferenceBroderies = () => {
  return axios.get(API_URL + "/referenceBroderie/active" , { headers: authHeader() });
};
const updateReferenceBroderie = (id , referenceBroderie ) => {
  return axios.put(API_URL + "/referenceBroderie/" + id , referenceBroderie, { headers: authHeader() });
};
const deleteReferenceBroderie = (id) => {
  return axios.delete(API_URL + "/referenceBroderie/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addReferenceBroderie,
  getReferenceBroderies,
  updateReferenceBroderie,
  deleteReferenceBroderie,
  getActiveReferenceBroderies
};
export default exportsMethodes;