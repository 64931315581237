import React from 'react'
import { DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import {
  Button, Card, Col, Divider, Empty, Form, Input, InputNumber, message,
  Modal, Row, Select, Spin, Space, Table, Tabs, Tag, Tooltip, DatePicker, Dropdown, Menu
} from "antd";
import ClientService from "../../../services/client.service";
import financeService from "../../../services/finance.service";
import PdfViewerModal from "../../../PdfViewerModal";
import moment from 'moment';
import { Collapse } from 'antd'
import { environment } from "../../../env/config.env";
import { useForm } from 'antd/lib/form/Form';
const { Panel } = Collapse;

const url = environment.serverUrl;

function HistoriqueClient() {
  const [searchForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [clients, setClients] = useState([]);
  const [searchObj,setSearchObj] = useState({client:"",date_de:"",date_a:""});
  const [showModal, setShowModal] = useState(false);
  const [onSearch, setOnSearch] = useState(false);

  useEffect(()=>{
    getClients()
    initSearchFrom()
  },[])

  const initSearchFrom = () => {
    let searchObj = searchForm.getFieldsValue()
    const starOfMonth = moment().startOf("month")
    const endOfMonth = moment().endOf("month")
    searchObj = {date_de:starOfMonth,date_a:endOfMonth,client:""}
    setSearchObj(searchObj)
    searchForm.setFieldsValue(searchObj)
  }

  const getClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients()
    if (response.status === 200 || 204) setClients(response.data)
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  }

  const search = async (values) => {
    if (!validSearchDate(values))  return message.error("Une erreur: date debut doit être inférieur à date fin! ");
    const temp = { client:values.client, date_de:values.date_de, date_a:values.date_a } 
    setSearchObj(temp)
    setOnSearch(true)
    await loadHistoriqueClient(temp);
  }

  const loadHistoriqueClient = async (searchObj) => {
    setLoading(true);
    const response = await financeService.getClientHistory(searchObj)
    if (response.status === 200 || 204) initTableData(response.data)
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  }

  const initTableData = (data) => {
    for (let element of data){
      element.date = moment(element.date).format("YYYY-MM-DD")
    }
    setDataTable(data)
  }


  const cancelSearch = () => {
    initSearchFrom()
    setDataTable([])
    setOnSearch(false)
  }

  const validSearchDate = (values) => {
    if (moment(values.date_de).isAfter(moment(values.date_a))) return false
    else return true
  }

  function currencyFormat(num) {
    return num !== "-" ? num?.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "-"
  }

  const colums = []
  
  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };

  const historiqueColums = [
    {
      title: "Date",
      dataIndex: "date",
    },{
      title: "Libelle",
      dataIndex: "libelle",
    },{
      title: "Numéro",
      dataIndex: "No",
    },{
      title: "Debit",
      dataIndex: "debit",
      render:(text, record) => (
        <>
          <Tag color="#2db7f5"> {currencyFormat(record.debit)} </Tag>
        </>
        )
    },{
      title: "Creadit",
      dataIndex: "creadit",
      render:(text, record) => (
        <>
          <Tag color="#008000"> {currencyFormat(record.creadit)} </Tag>
        </>
        )
    },{
      title: "Solde",
      dataIndex: "solde",
      render: (text, record) => (
        <>
          {record.solde >= 0 && <span className='text-success'> <Tag color="#008000"> {currencyFormat(record.solde)} </Tag> </span> }
          {record.solde < 0 && <span className='text-danger'> <Tag color="#f50">  {currencyFormat(record.solde)} </Tag> </span> }
        </>
      )
    }
];

const gerenateHistoriqueCLient = async () => {
  setLoading(true);
  const response = await financeService.gerenateHistoriqueCLient(searchObj,dataTable)
  if (response.status === 200 || 204){
    message.success("historique est généré sous forme de PDF")
    window.open(url+"Files/stock/historique/client/"+searchObj.client+".pdf", 'download');
  } 
  else message.error("Une erreur est survenu ! ");
  setLoading(false);
}

const showPDFModal = () => {
  // setShowModal(true);
  window.open(url+"Files/stock/historique/client/"+searchObj.client+".pdf", 'download');
} 

const downloadFile = () => {
  window.open(url+"Files/stock/historique/client/"+searchObj.client+".pdf", 'download');
}

  return (
    <>
      <Spin spinning={isLoading} size="large">
        <Card title={"Historique Client"} >
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Recherche" key="1">
              <Form
              layout="vertical" 
              form={searchForm} 
              onFinish={search}
              >
              <Row className='d-flex flex-row justify-content-evenly'>
                <Col span={12}>
                  <Form.Item 
                  name={"client"}
                  label="client"
                    rules={[
                      {
                        required: true,
                        message: 'selectionner un client !',
                      },
                    ]}>
                        <Select size="large">
                          {clients.map((cl,index)=> <Select.Option key={index} value={cl.id}>{cl.type == 0 ? cl.nom : cl.raison_sociale}</Select.Option>  )}
                        </Select>
                    </Form.Item>
                </Col> 
                <Col span={4} style={{marginRight:"30px"}}>
                  <Form.Item 
                    name={"date_de"}
                    label="date debut"
                    valuePropName='date'
                    rules={[
                      {
                        required: true,
                        message: 'selectionner date de!',
                      },
                    ]}>
                          <DatePicker
                            defaultValue={moment().startOf('month')}
                            value={searchObj.date_de ? moment(searchObj.date_de) : ""}
                            placeholder=" "
                            size="large"
                            className="w-100"
                            onChange={(date,dateString)=> setSearchObj((prev)=>({...prev,date_de:dateString}))}
                          ></DatePicker>               
                    </Form.Item>
                </Col> 
                <Col span={4}>
                  <Form.Item 
                    name={"date_a"}
                    label="date fin"
                    valuePropName='date'
                    rules={[
                      {
                        required: true,
                        message: 'selectionner date a!',
                      },
                    ]}>
                          <DatePicker
                            defaultValue={moment().endOf('month')}
                            value={searchObj.date_a ? moment(searchObj.date_a) : ""}
                            placeholder=" "
                            size="large"
                            className="w-100"
                            onChange={(date,dateString)=> setSearchObj((prev)=>({...prev,date_a:dateString}))}
                          ></DatePicker>
                  </Form.Item>
                </Col> 
              </Row>
              <Row className='d-flex justify-content-end'>
                <Col className='px-2'>
                    <Form.Item className='m-0'>
                      <Button type="primary" htmlType="submit">
                        Valider
                      </Button>
                    </Form.Item>
                </Col>
                <Col className='px-2'>
                      <Button onClick={cancelSearch} type="danger">
                        Annuler
                      </Button>
                </Col>
              </Row>
            </Form>
          </Panel>
        </Collapse>
        <br />     
        <Row className='d-flex justify-content-end'>
          {/* <Button disabled={!onSearch} onClick={() => showPDFModal()} >Visualizer</Button>  */}
          <Button disabled={!onSearch} onClick={gerenateHistoriqueCLient}>Imprimer</Button> 
        </Row>
        <Table
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          locale={locale}
          columns={historiqueColums}
          dataSource={dataTable}
          pagination={{ position: ["bottomCenter"], pageSize: 50 }}
        />
        </Card>

        <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  Historique Financier :
                </div>
                <div className="px-5 ">
                  <Button  onClick={()=> downloadFile()} icon={<DownloadOutlined />} >
                    Telecharger
                  </Button>
                </div>
              </div>
            </>
          }
          centered
          visible={showModal}
          footer={null}
          width={1000}
          onCancel={()=> setShowModal(false)}
          confirmLoading={isLoading}
      >
        { showModal && 
          <div className="heigt-500px">
            <PdfViewerModal 
              diractory="Files/stock/historique/client/" 
              reference={searchObj.client}  
            ></PdfViewerModal>
          </div>
        }
        

      </Modal>

      </Spin>
    </>
  )
}

export default HistoriqueClient