import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addBonRetour = async ( bon_retour ) => {
  return await axios.post(API_URL + "/bon_retour" , bon_retour ,{ headers: authHeader() });
};

const getBonRetours = async ( type ,callback ) => {
  callback( await axios.get(API_URL + "/bon_retour/type/"+type , { headers: authHeader() }))
};


const getBonRetour = async ( id, callback ) => {
  callback( await axios.get(API_URL + "/bon_retour/" + id , { headers: authHeader() }))
};
const updateBonRetour = (id , bon_retour ) => {
  return axios.put(API_URL + "/bon_retour/" + id , bon_retour, { headers: authHeader() });
};

const deleteBonRetour = (id) => {
  return axios.delete(API_URL + "/bon_retour/" + id , { headers: authHeader() });
};
const changeEtat = (id ,etat ,record) => {
  return axios.post(API_URL + "/bon_retour/state/" + id , {etat : etat, record: record} , { headers: authHeader() });
};

const getActiveBonRetours = async (id) => {
  return await axios.get(API_URL + "/bon_retour/active/" , { headers: authHeader() });
}

const getLast = async (callback) => {
  callback( await axios.get(API_URL + "/bon_retour/last", { headers: authHeader() }))
}
const exportsMethodes = {
  addBonRetour,
  getBonRetours,
  getBonRetour,
  updateBonRetour,
  deleteBonRetour,
  changeEtat,
  getActiveBonRetours,
  getLast
  
};
export default exportsMethodes;