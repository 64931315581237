import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Spin,
  Switch,
  Tag,
  Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import Can from "../../../../security/Can.js";
import TvaService from "../../../../services/tva.service";
import AppTable from "../../../uiHelpers/AppTabe";


const Tva = () => {
  const [tvaForm] = Form.useForm();
  const [tvaModal, setTvaModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [switchValue, setSwitchValue] = useState("unchecked");


  useEffect(() => {
    loadTvas();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadTvas = async () => {
    setLoading(true);
    const response = await TvaService.getTvas();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  
  const initTableData = (data) => {
    setDataTable(data);
  };

  const showTvaModal = () => {
    setTvaModal(true);
  };

  const handleOk = () => {
    setTvaModal(false);
  };

  const updateMode = (record) => {
    setTvaModal(true);
    const checkedSwitch = record.etat ? "checked" : "unchecked";
    setSwitchValue(checkedSwitch);
    tvaForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const handleCancel = () => {
    setTvaModal(false);
    clearData();
  };

  const addTva = async (values) => {
    setLoading(true);
    var data = values
    data.etat = data.etat ? data.etat : false; 
    data.type = 1;
    const response = editMode
      ? await TvaService.updateTva(updateId, data)
      : await TvaService.addTva(data);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Tva ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    closingModal();
  };

  const closingModal = () => {
    loadTvas();
    setTvaModal(false);
    setLoading(false);
    clearData();
  };

  const clearData = () => {
    tvaForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const TvaColumns = [
    {
      title: "Nom",
      dataIndex: "titre",
      sorter: (a, b) => a.titre.localeCompare(b.titre),
      width: "17%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "40%",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Valeur",
      dataIndex: "valeur",
      width: "20%",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Etat",
      dataIndex: "etat",
      width: "10%",
      sorter: (a, b) => a.etat - b.etat,
      render: (text, record) => (
        <Tag color={record.etat ? "cyan" : "red" }>{record.etat ? "Activé" : "Désactivé"}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("2_3")}
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>

        </div>
      ),
    },
  ];


  return (
    <Spin spinning={isLoading} size="large">
      <Card
        title={"TVA"}
        extra={
          Can("2_2") && (  
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              onClick={() => showTvaModal()}
            >
              Ajouter une TVA
            </Button>
          )
        }
      >

        <AppTable data={dataTable} columns={TvaColumns} />
        <Form layout="vertical" form={tvaForm} onFinish={addTva}>
          <Modal
            visible={tvaModal}
            onOk={tvaForm.submit}
            onCancel={handleCancel}
            getContainer={false}
            confirmLoading={isLoading}
            okText="Enregistrer"
            cancelText="Annuler"
            centered
            width={600}
            title={
              <>
                <div className="d-flex justify-content-between ">
                  <div className="my-0">
                    {editMode
                      ? "Modifier la TVA"
                      : "Ajouter une TVA"}
                  </div>
                  <div>
                    <Form.Item
                      className="my-0 mx-5"
                      name="etat"
                      valuePropName={switchValue}
                    >
                      <Switch
                        checkedChildren="Activer"
                        unCheckedChildren="Désactiver"
                        className="px-4"
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
            }
          >
            <Row>
            <Col span={12} className="px-2">
                <Form.Item
                  label="Nom de la TVA "
                  name="titre"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input size="large" type="text" maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Valeur"
                  name="valeur"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire  !",
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    style={{ width: "100%" }}
                    min={0}
                    max={99}
                  />
                </Form.Item>
              </Col>

              <Col span={24} className="px-2">
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      message:
                        "Champ obligatoire avec maximum de caractère 255 !",
                      max: 254,
                    },
                  ]}
                >
                  <TextArea rows={4} type="text" maxLength={255} />
                </Form.Item>
              </Col>
            </Row>
          </Modal>
        </Form>
      </Card>
    </Spin>
  );
};
export default Tva;
