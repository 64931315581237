import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addConfection = ( confection ) => {
  return axios.post(API_URL + "/confection/" , confection ,{ headers: authHeader() });
};

const getConfections = () => {
  return axios.get(API_URL + "/confection/" , { headers: authHeader() });
};
const getActiveConfections = () => {
  return axios.get(API_URL + "/confection/active" , { headers: authHeader() });
};
const updateConfection = (id , confection ) => {
  return axios.put(API_URL + "/confection/" + id , confection, { headers: authHeader() });
};
const deleteConfection = (id) => {
  return axios.delete(API_URL + "/confection/" + id , { headers: authHeader() });
};

const updateOdreConfectionStatus = ( id , etat ) => {
  return axios.put(API_URL + "/confection/status/" + id , etat, { headers: authHeader() });
}

const loadStockQte = ( data )=> {
  return axios.post(API_URL + "/confection/qteStock/", data, { headers: authHeader() } )
}

const loadStock = () => {
  return axios.get(API_URL + "/confection/stock" , { headers: authHeader() });
}

const update = (id , data) => {
  return axios.put(API_URL + "/confection/stock/"+id , data, { headers: authHeader() });
}

const exportsMethodes = {
  addConfection,
  getConfections,
  updateConfection,
  deleteConfection,
  getActiveConfections,
  updateOdreConfectionStatus,
  loadStockQte,
  loadStock,
  update
};
export default exportsMethodes;