import {
  CheckOutlined,
  DeleteOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Can from "../../../security/Can";
import BonTransfertService from "../../../services/bonTransfert.service";
import EntrepriseService from "../../../services/entreprise.service";
import ProduitService from "../../../services/produit.service";
import StockService from "../../../services/Stock.service";
import AppTable from "../../uiHelpers/AppTabe";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import FicheStockDetails from "../../uiHelpers/Fiches/FicheStockDetails";
import FicheTransfertHeader from "../../uiHelpers/Fiches/FicheTransfertHeader";
import NotAuthorized from "../../uiHelpers/NotAuthorized";

function BonTransfert() {
  const [productsForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [entreprise, setEntreprise] = useState({});
  const [productsModal, setProductsModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isDarkMode, setDarkMode] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [productModal, setProductModal] = useState(false);
  const [stores, setStores] = useState([]);

  const history = useHistory();

  const location = useLocation();

  const isVente = true;

  useEffect(() => {
    loadEntreprise();
    getStoreNames();
    getThemeMode();

    setUpdate();
    return () => {
      clearStates();
    };
  }, []);

  const getStoreNames = async () => {
    setLoading(true);
    const response = await StockService.getStores();
    if (response.status === 200 || 204) setStores(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const setUpdate = async () => {
    if (location.state?.id) {
      const id = location.state?.id;
      setLoading(true);
      await BonTransfertService.getBonTransfert(id, (response) => {
        if (response.status === 200 || 204) {
          const productList = { ...response.data };
          productList.date = moment(productList.date);
          productsForm.setFieldsValue(productList);
          loadProduits(productList.from_store_id);
          const ids = [];
          for (const element of productList.produit_bon_transferts)
            ids.push(element.produitId);
          setSelectedIds(ids);
          setUpdateId(id);
          setEditMode(true);
        } else message.error("Une erreur est survenu ! ");
        setLoading(false);
      });
    } else {
      generateReference();
    }
  };
  const generateReference = async () => {
    await BonTransfertService.getLast((response) => {
      if (response.status === 200 || 204) {
        if (response.data.length === 0) {
          var nbr = "1";
          var reference = "BT-" + nbr.padStart(4, "0");
        } else {
          const lastRef = response.data.reference;
          const refNbr = Number(lastRef.substring(3, 7)) + 1 + "";
          var reference = "BT-" + refNbr.padStart(4, "0");
        }
        productsForm.setFieldsValue({
          reference: reference,
          date: moment(new Date()),
        });
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  };

  const loadProduits = async (storeId) => {
    setLoading(true);
    const response = await ProduitService.getProduitsByStore(storeId);
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const initTableData = (produits) => {
    produits = formatteProductsToDataTable(produits);
    setProductList(produits);
  };

  const formatteProductsToDataTable = (produits) => {
    const list = [];
    for (const produit of produits) {
      if( !produit.type ){
        produit.showType = produit.type ? "Service" : "Produit";
        produit.ttcAchat = produit.produit_achats[0]?.prixTTC;
        produit.htAchat = produit.produit_achats[0]?.prixHT;
        produit.pqte = produit.stocks[0]?.quantite;
        produit.fournisseurId = produit.produit_ventes[0]?.fournisseurId;
        produit.showFournisseur =
          produit.produit_ventes[0]?.fournisseur?.raison_sociale ??
          "Produit proprietere";
        produit.ttcVente = produit.produit_ventes[0].prixTTC;
        produit.htVente = produit.produit_ventes[0].prixHT;
        list.push(produit)
      }
    }
    return list;
  };


  const selectProduct = (produit) => {
    setSelectedIds((prev) => [...prev, produit.id]);
    formatteProduct(produit);
  };

  const removeElement = (produitId) => {
    const list = selectedIds;
    setSelectedIds(list.filter((idP) => idP !== produitId));
  };

  const isSelected = (id) => {
    return selectedIds.includes(id);
  };

  const formatteProduct = (produit) => {
    const qte = produit?.quantite ?? 1;
    const product = {
      produitId: produit?.id,
      libelle: produit?.libelle,
      quantite: Number(qte),
    };
    const productList = productsForm.getFieldValue("produit_bon_transferts")
      ? productsForm.getFieldValue("produit_bon_transferts")
      : [];
    productList.push(product);
    productsForm.setFieldsValue({ produit_bon_transferts: productList });
  };

  const getThemeMode = () => {
    const theme = localStorage.getItem("COLOR_THEME");
    const darkMode = theme === "DARK" ? true : false;
    setDarkMode(darkMode);
  };

  const loadEntreprise = async () => {
    setLoading(true);
    const response = await EntrepriseService.getEntreprise();
    if (response.status === 200 || 204) {
      setEntreprise(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const clearStates = () => {
    setEditMode(false);
    setUpdateId(null);
  };

  const handleCancel = () => {
    setProductsModal(false);
  };

  const getProductId = (index, name) => {
    return productsForm.getFieldValue("produit_bon_transferts")[index]
      ?.produitId;
  };

  const addBonTransfert = async () => {
    setLoading(true);
    const product = productsForm.getFieldsValue();
    const bonTransfert = { ...product };
    bonTransfert.etat = 0;
    const response = editMode
      ? await BonTransfertService.updateBonTransfert(updateId, bonTransfert)
      : await BonTransfertService.addBonTransfert(bonTransfert);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Bon de transfert Ajouter Avec Success");
      history.push("/bon_transfert");
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const ProduitColumns = [
    {
      title: "Type",
      dataIndex: "showType",
      key: "1",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Libelle",
      dataIndex: "libelle",
      sorter: (a, b) => a.showNom.localeCompare(b.showNom),
    },
    {
      title: "Référence Int",
      dataIndex: "reference_interne",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      sorter: (a, b) => a.valeur - b.valeur,
    },{
      title: "Quantité",
      dataIndex: "pqte",
      sorter: (a, b) => a.pqte - b.pqte,
    },
    
    {
      title: "Achat TTC",
      dataIndex: "ttcAchat",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Vente TTC",
      dataIndex: "ttcVente",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              disabled={isSelected(record.id)}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => selectProduct(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const toSelect = () => {
    return (
      <Select size="large">
        {stores &&
          stores.map((store) => (
            <Select.Option disabled={compareSelects(store.id)} value={store.id}>
              {store.nom}
            </Select.Option>
          ))}
      </Select>
    );
  };

  const fromSelectChanged = () => {
    const storeId = productsForm.getFieldValue("from_store_id");
    loadProduits(storeId);
    toSelect();
  };

  const compareSelects = (id) => {
    const storeFromId = productsForm.getFieldValue("from_store_id");
    const result = storeFromId === id;
    return result;
  };
  const disableForm = () => {
    return selectedIds.length === 0;
  }

  return (
    <>
      {Can("21_2") ? (
        <Spin className="px-5" spinning={isLoading} size="large">
          <Card className="w-100 card-shadow">
            <Form
              layout="vertical"
              form={productsForm}
              onFinish={addBonTransfert}
            >
              <Row>
                <FicheTransfertHeader
                  moduleName={"Bon De Transfert"}
                  entreprise={entreprise}
                ></FicheTransfertHeader>
              </Row>
              <Row>
                <Col span={8} className="px-2 pt-3">
                  <Col span={24}>
                    <Form.Item
                      label="Du Store :"
                      name="from_store_id"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                      ]}
                    >
                      <Select size="large" onChange={() => fromSelectChanged()}>
                        {stores &&
                          stores.map((store) => (
                            <Select.Option value={store.id}>
                              {store.nom}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Vers :"
                      name="to_store_id"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                      ]}
                    >
                      {toSelect()}
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={8} offset={8} className="px-2 pt-3">
                  <FicheStockDetails></FicheStockDetails>
                </Col>
              </Row>
              <Row>
                <Col span="24" className="px-4">
                  <table
                    className={` mb-5 table ${isDarkMode ? "table-dark" : ""} `}
                  >
                    <thead>
                      <tr>
                        <th scope="col" style={{width : "5%"}} >#</th>
                        <th scope="col" style={{width : "75%"}} >Libelle</th>
                        <th scope="col" style={{width : "10%"}} >Quantité</th>
                        <th scope="col" style={{width : "10%"}} className="text-center color">
                          <Tooltip title="Ajouter un produit">
                            <span
                              className="medium pointer"
                              type="primary"
                              onClick={() => setProductsModal(true)}
                            >
                              <PlusCircleOutlined />
                            </span>
                          </Tooltip>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <Form.List
                        name="produit_bon_transferts"
                        label="List des addresse de livraisons"
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(
                              ({ key, name, ...restField }, index) => (
                                <>
                                  <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "libelle"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Champ obligatoire avec maximum de caractère 100 !",
                                          },
                                        ]}
                                      >
                                        <Input
                                          style={{ width: "100%" }}
                                          type={"text"}
                                          bordered={false}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "quantite"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:"Champ obligatoire !",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          style={{ width: 60 }}
                                          className="w-125px"
                                          min={0}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td className="text-center red-flag pt-1 w-90px">
                                      <Tooltip title="Supprimer le produit">
                                        <span
                                          className="medium pointer"
                                          type="primary"
                                          onClick={() => {
                                            removeElement(getProductId(index));
                                            remove(name);
                                          }}
                                        >
                                          <DeleteOutlined />
                                        </span>
                                      </Tooltip>
                                    </td>
                                  </tr>
                                </>
                              )
                            )}
                          </>
                        )}
                      </Form.List>
                    </tbody>
                  </table>
                </Col>
              </Row>

              <FicheFooter entreprise={entreprise}></FicheFooter>

              <Row className="d-flex flex-row-reverse pt-5 pb-2">
                <Button
                  className="mx-2"
                  type="primary"
                  onClick={productsForm.submit}
                  disabled={disableForm()}

                >
                  Enregistrer
                </Button>
                
              </Row>
            </Form>
          </Card>

          <Modal
            visible={productsModal}
            getContainer={false}
            centered
            footer={null}
            onCancel={handleCancel}
            confirmLoading={isLoading}
            title="Liste des Produits"
            width={1000}
          >
            <Spin spinning={isLoading} size="large">
              <AppTable data={productList} columns={ProduitColumns} />
            </Spin>
          </Modal>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
}

export default BonTransfert;
