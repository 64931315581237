import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Modal,
    Row,
    Spin
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import CategorieService from "../../../../services/categorie.service";

const AddCategorieModalForm = ({ showModal , categorieAdded }) => {
    const [categorieForm] = Form.useForm();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {}, [showModal]);

    const handleCancel = () => {
        closingModal();
        categorieAdded(false);

    };
    const closingModal = () => {
        setLoading(false);
        categorieForm.resetFields();
    };

    const addCategorie = async (values) => {
        setLoading(true);
        var data = values;
        data.etat = true;
        const response = await CategorieService.addCategorie(data);
        if (response.status === 200 || 204) {
        message.info("Catégorie ajouter avec success");
        } else message.error("Une erreur est survenu ! ");
        closingModal();
        categorieAdded(true);

    };



  return (

        <Modal
          visible={showModal}
          onOk={categorieForm.submit}
          onCancel={handleCancel}
          getContainer={false}
          confirmLoading={isLoading}
          okText="Enregistrer"
          cancelText="Annuler"
          centered
          width={600}
          title={"Ajouter une categorie"}
        >
          <Form layout="vertical" form={categorieForm} onFinish={addCategorie}>
            <Row>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Nom de la categorie "
                  name="nom"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input size="large" type="text" maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={24} className="px-2">
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      message:
                        "Champ obligatoire avec maximum de caractère 255 !",
                      max: 254,
                    },
                  ]}
                >
                  <TextArea rows={4} type="text" maxLength={255} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
  );
};
export default AddCategorieModalForm;
