import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  EditOutlined,
  HighlightOutlined,
  DownloadOutlined,
  ToolOutlined,
  FilePptOutlined,
  DollarOutlined,
  DeleteOutlined,
  SearchOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  DatePicker,
  Dropdown,
  Menu,
  Switch
} from "antd";
import moment from "moment";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip as RTooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Collapse } from "antd";
import ProduitService from "../../../services/produit.service";
import ConfectionService from "../../../services/confection.service";
import BonLivraisonService from "../../../services/bonLivraison.service";
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const Production = () => {
  const [searchForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [produitsFini, setProduitsFini] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [isCummulative, setIsCummulative] = useState(false);
  const [searchObj, setSearchObj] = useState({
    produits: [],
    range: [],
  });

  useEffect(() => {
    loadProduits();
    initData();
  }, []);

  const loadProduits = async () => {
    setLoading(true);
    const response = await ProduitService.getProduitFini();
    if (response.status === 200 || 204) {
      const data = [...response.data];
      let obj = {};
      obj.id = "tout";
      data.push(obj);
      setProduitsFini(data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const initData = async () => {};

  const search = async (values) => {
    const temp = {
      produits: values.produits,
      range: searchObj.range,
    };
    setSearchObj(temp);
    setIsCummulative(false);
    filterData(temp);
  };

  const filterData = async (search) => {
    setLoading(true);
    const listOfMonthes = getListOfMonth(search.range);

    let confections = await ConfectionService.getConfections();
    confections = confections.data;
    confections = filterByProduitIds(confections, search.produits);
    confections = filterByDate(confections, search.range, "updatedAt");

    let bls = await BonLivraisonService.getActiveBonLivraison();
    bls = bls.data;
    let produitsBls = [];
    for (let element of bls) {
      const produitBonLivraisons = element.produit_bon_livraisons?.map((ele) => { return { ...ele, dateBl: element.date }}); //add dateBl to every produit_bon_livraisons
      const blList = filterByProduitIds(produitBonLivraisons, search.produits);
      if (blList.length > 0) produitsBls.push(...blList);
    }
    const selectedProduitBl = filterByDate(produitsBls, search.range, "dateBl");

    const dataLineChart = [];
    const dataBarChart = [];
    for (let month of listOfMonthes) {
      var qtyProduit = 0;
      var qtyVendu = 0;
      var montantAchat = 0;
      var montantRevenu = 0;

      for (let element of confections) {
        if (moment(element.updatedAt).format("YYYY-MM") == month)
          qtyProduit += element.qteTotal;
      }

      for (let element of selectedProduitBl) {
        if (moment(element.dateBl).format("YYYY-MM") == month){
          qtyVendu += element.quantite;

          const prixAchat = element.produit.produit_achats[0].prixHT
          montantAchat += prixAchat * element.quantite

          montantRevenu += parseFloat(parseFloat(element.total_prix_HT).toFixed(3))
        }
      }

      dataLineChart.push({
        qtyProduit: qtyProduit,
        qtyVendu: qtyVendu,
        month: month,
      });

      dataBarChart.push({
        sommeAchat: montantAchat,
        sommeVente: parseFloat(parseFloat(montantRevenu).toFixed(3)),
        month: month,
      })
    }

    setLineChartData(dataLineChart);
    setBarChartData(dataBarChart);
    setLoading(false);
  };

  const filterCummulativeData = async (search) => {
    setLoading(true);
    const listOfMonthes = getListOfMonth(search.range);

    let confections = await ConfectionService.getConfections();
    confections = confections.data;
    confections = filterByProduitIds(confections, search.produits);
    confections = filterByDate(confections, search.range, "updatedAt");

    let bls = await BonLivraisonService.getActiveBonLivraison();
    bls = bls.data;
    let produitsBls = [];
    for (let element of bls) {
      const produitBonLivraisons = element.produit_bon_livraisons?.map((ele) => { return { ...ele, dateBl: element.date }}); //add dateBl to every produit_bon_livraisons
      const blList = filterByProduitIds(produitBonLivraisons, search.produits);
      if (blList.length > 0) produitsBls.push(...blList);
    }
    const selectedProduitBl = filterByDate(produitsBls, search.range, "dateBl");

    const dataLineChart = [];
    const dataBarChart = [];
    var montantAchat = 0;
    var montantRevenu = 0;

    for (let month of listOfMonthes) {
      var qtyProduit = 0;
      var qtyVendu = 0;

      for (let element of confections) {
        if (moment(element.updatedAt).format("YYYY-MM") == month)
          qtyProduit += element.qteTotal;
      }

      for (let element of selectedProduitBl) {
        if (moment(element.dateBl).format("YYYY-MM") == month){
          qtyVendu += element.quantite;

          const prixAchat = element.produit.produit_achats[0].prixHT
          montantAchat += prixAchat * element.quantite

          montantRevenu += parseFloat(parseFloat(element.total_prix_HT).toFixed(3))
        }
      }

      dataLineChart.push({
        qtyProduit: qtyProduit,
        qtyVendu: qtyVendu,
        month: month,
      });

      dataBarChart.push({
        sommeAchat: montantAchat,
        sommeVente: parseFloat(parseFloat(montantRevenu).toFixed(3)),
        month: month,
      })
    }

    setLineChartData(dataLineChart);
    setBarChartData(dataBarChart);
    setLoading(false);
  }

  const cancelSearch = async () => {
    initData();
    clearSearch();
  };

  const clearSearch = () => {
    const emptySearch = {
      produits: null,
      range: [],
    };
    setSearchObj(emptySearch);
    searchForm.setFieldsValue(emptySearch);
  };

  const switchCummulative = (value) => {
    setIsCummulative(value)
    if(value) filterCummulativeData(searchObj)
    else filterData(searchObj)
  }

  // helper functions
  const filterByProduitIds = (data, produitIds) => {
    if(produitIds.includes("tout")) return data
    return data.filter((element) => produitIds.includes(element.produitId));
  };

  const filterByDate = (data, range, field) => {
    let [start, end] = [...range];
    const startDate = moment(start, "YYYY-MM");
    const endDate = moment(end, "YYYY-MM").endOf("month");

    const filteredData = data.filter((item) => {
      const updatedAtMoment = moment(item[field]);
      return updatedAtMoment.isBetween(startDate, endDate, "month", "[]");
    });

    return filteredData;
  };

  const getListOfMonth = (range) => {
    let [start, end] = [...range];
    const startDate = moment(start, "YYYY-MM");
    const endDate = moment(end, "YYYY-MM");
    const dates = [];

    for (
      let currentDate = startDate.clone();
      currentDate.isSameOrBefore(endDate);
      currentDate.add(1, "month")
    ) {
      dates.push(currentDate.format("YYYY-MM"));
    }

    return dates;
  };

  return (
    <Spin spinning={isLoading} size="large">
      <Card title={"Production"}>
        <br />
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Recherche" key="1">
            <Form layout="vertical" form={searchForm} onFinish={search}>
              <Row className="d-flex flex-row justify-content-evenly">
                <Col span={12}>
                  <Form.Item
                    name={"produits"}
                    label="produit fini"
                    rules={[
                      {
                        required: true,
                        message: "selectionner un produit fini !",
                      },
                    ]}
                  >
                    <Select size="large" mode="multiple">
                      {produitsFini.map((cl, index) => (
                        <Select.Option key={index} value={cl.id}>
                          {cl.libelle}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ marginRight: "30px" }}>
                  <Form.Item
                    name={"range"}
                    label="Range"
                    rules={[
                      {
                        required: true,
                        message: "selectionner Range!",
                      },
                    ]}
                  >
                    <RangePicker
                      picker="month"
                      onChange={(date, dateString) =>
                        setSearchObj((prev) => ({
                          ...prev,
                          range: dateString,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-flex justify-content-end">
                <Col className="px-2">
                  <Form.Item className="m-0">
                    <Button type="primary" htmlType="submit">
                      Valider
                    </Button>
                  </Form.Item>
                </Col>
                <Col className="px-2">
                  <Button onClick={cancelSearch} type="danger">
                    Annuler
                  </Button>
                </Col>
              </Row>
            </Form>
          </Panel>
        </Collapse>
        <br />

        <Row>
          <Col span={24}>
            <h5 className="p-3">Chart 1 : </h5>
            <LineChart
              width={1200}
              height={400}
              data={lineChartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="month" label={{ value: 'Mois', position: 'insideBottom' }}/>
              <YAxis label={{ value: 'Quantité', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Line
                type="monotone"
                dataKey="qtyProduit"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="qtyVendu" stroke="#82ca9d" activeDot={{ r: 8 }} />
            </LineChart>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <h5 className="p-3">Chart 2 : </h5>
            <BarChart 
              width={1200}
              height={400}
              data={barChartData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" label={{ value: 'Mois', position: 'insideBottom' }} />
              <YAxis label={{ value: 'Montant', angle: -90, position: 'insideLeft' }} />
              <Tooltip position="right" >
                <Switch style={{position: 'absolute', top: '10px', right: '10px'}} 
                  checkedChildren="Normal" 
                  unCheckedChildren="Cummulative"
                  checked={isCummulative}
                  onChange={(value)=> switchCummulative(value)}
                >
                  {isCummulative ? "Cummulative" : "Normal"}
                </Switch>
              </Tooltip>
              <Legend verticalAlign="top" height={36} />
              <Bar dataKey="sommeAchat" fill="#8884d8" />
              <Bar dataKey="sommeVente" fill="#82ca9d" />
            </BarChart>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default Production;
