import React, { useEffect } from "react";


function FicheFournisseur({ fournisseur }) {

  useEffect(() => {
  }, []);

  return (
    <>
      {fournisseur && (
        <>
          <span className="ligne-height"> <strong> Entreprise :</strong> </span> <span className="description-text"> {fournisseur.raison_sociale} </span>
          <br />
          <span className="ligne-height"> <strong> Matricule Social : </strong> </span> <span className="description-text"> {fournisseur.matricule_fiscal} </span>
          <br />
          <span className="ligne-height"> <strong> Responsable :</strong>  </span> <span className="description-text"> {fournisseur.nom} {fournisseur.prenom} </span>
          <br />
          <span className="ligne-height"> <strong> Téléphone  : </strong> </span> <span className="description-text">  {fournisseur.telephone_pro} </span>
          <br />
          <span className="ligne-height"> <strong> Adresse :  </strong> </span> <span className="description-text">   {fournisseur.adresse_fournisseurs[0]?.adresse} </span>
          <br />
          {/* {fournisseur.site && (
            <>
              <span className="ligne-height"> <strong> Site web : </strong> </span> <span className="description-text"> {fournisseur.site} </span>
            </>
          )} */}
        </>
      )}
    </>
  );
}
export default FicheFournisseur;
