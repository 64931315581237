import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addTypeCoupe = ( typeCoupe ) => {
  return axios.post(API_URL + "/typeCoupe/" , typeCoupe ,{ headers: authHeader() });
};

const getTypeCoupes = () => {
  return axios.get(API_URL + "/typeCoupe/" , { headers: authHeader() });
};
const getActiveTypeCoupes = () => {
  return axios.get(API_URL + "/typeCoupe/active" , { headers: authHeader() });
};
const updateTypeCoupe = (id , typeCoupe ) => {
  return axios.put(API_URL + "/typeCoupe/" + id , typeCoupe, { headers: authHeader() });
};
const deleteTypeCoupe = (id) => {
  return axios.delete(API_URL + "/typeCoupe/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addTypeCoupe,
  getTypeCoupes,
  updateTypeCoupe,
  deleteTypeCoupe,
  getActiveTypeCoupes
};
export default exportsMethodes;